<mat-card-title mat-dialog-title class="d-flex align-items-center mb-0"
  ><mat-icon>filter_alt</mat-icon>
  {{
    'configurable-fields.formula-builder.define-file-data-filters.title'
      | translate
  }}
</mat-card-title>
<mat-card-subtitle>{{
  'configurable-fields.formula-builder.define-file-data-filters.sub-title'
    | translate
}}</mat-card-subtitle>
<mat-divider></mat-divider>
<mat-dialog-content class="main-container">
  <div class="row header-row">
    <h6>
      {{
        'configurable-fields.formula-builder.define-file-data-filters.filter'
          | translate
      }}
    </h6>

    <button
      (click)="addForm()"
      mat-stroked-button
      style="font-size: small"
      [matTooltip]="
        'configurable-fields.formula-builder.define-file-data-filters.new-filter-button'
          | translate
      "
    >
      {{
        'configurable-fields.formula-builder.define-file-data-filters.new-filter-button'
          | translate
      }}
    </button>
  </div>
  <form class="container">
    <div *ngFor="let field of fields; let i = index" class="row filter-row">
      <mat-form-field appearance="fill" class="filter-element">
        <mat-label>
          {{
            'configurable-fields.formula-builder.define-file-data-filters.field-name'
              | translate
          }}
        </mat-label>
        <mat-select
          placeholder="Field Name"
          required
          [formControl]="field.field"
        >
          <mat-option
            *ngFor="let field of foreignReferenceFields"
            [value]="field._id"
          >
            {{ field.name }}
          </mat-option>
        </mat-select>
        <mat-error>{{
          'configurable-fields.formula-builder.define-file-data-filters.name-required'
            | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="filter-element">
        <mat-label>
          {{
            'configurable-fields.formula-builder.define-file-data-filters.comparison-type'
              | translate
          }}</mat-label
        >
        <mat-select
          placeholder="Comparison Type"
          required
          [formControl]="field.comparisonType"
          [disabled]="!field.field.value"
        >
          <mat-option *ngFor="let type of COMPARISON_TYPES" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="filter-element">
        <mat-label>
          {{
            'configurable-fields.formula-builder.define-file-data-filters.compare-with'
              | translate
          }}</mat-label
        >
        <mat-select
          placeholder="Compare With"
          required
          [formControl]="field.compareWith"
          [disabled]="!field.field.value"
          (valueChange)="setFieldItem(i, $event)"
        >
          <mat-option value="local">
            {{
              'configurable-fields.formula-builder.define-file-data-filters.local-field'
                | translate
            }}
          </mat-option>
          <mat-option value="direct">
            {{
              'configurable-fields.formula-builder.define-file-data-filters.direct'
                | translate
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        *ngIf="field.compareWith.value === 'local'"
        appearance="fill"
        class="filter-element"
      >
        <mat-label>
          {{
            'configurable-fields.formula-builder.define-file-data-filters.compare-value'
              | translate
          }}</mat-label
        >
        <mat-select
          placeholder="Field Name"
          required
          [formControl]="field.compareValue"
          [disabled]="!field.field.value"
        >
          <mat-option
            *ngFor="let field of fileConfig?.fields || []"
            [value]="field._id"
          >
            {{ field.name }}
          </mat-option>
        </mat-select>
        <mat-error>{{
          'configurable-fields.formula-builder.define-file-data-filters.value-required'
            | translate
        }}</mat-error>
      </mat-form-field>
      <div
        *ngIf="field.compareWith.value === 'direct' && field.fieldItem"
        class="filter-element"
      >
        <app-display-single-configurable-field
          [fieldItem]="field.fieldItem"
          [disabled]="!field.field.value"
        ></app-display-single-configurable-field>
      </div>

      <button
        mat-icon-button
        (click)="removeForm(i)"
        type="button"
        matTooltip="Delete"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    [mat-dialog-close]="false"
    [matTooltip]="
      'configurable-fields.formula-builder.define-file-data-filters.close'
        | translate
    "
  >
    {{
      'configurable-fields.formula-builder.define-file-data-filters.close'
        | translate
    }}
  </button>
  <button
    mat-raised-button
    cdkFocusInitial
    color="primary"
    [matTooltip]="
      'configurable-fields.formula-builder.define-file-data-filters.save'
        | translate
    "
    [disabled]="isAllValid()"
    (click)="save()"
  >
    {{
      'configurable-fields.formula-builder.define-file-data-filters.save'
        | translate
    }}
  </button>
</mat-dialog-actions>
