export enum RECEIPTS_PAYMENT_METHODS {
  CASH = 'CASH',
  CHEQUE = 'CHEQUE',
  CARD = 'CARD',
  BANK_TRANSFER = 'BANK TRANSFER',
  ADJUSTMENT = 'ADJUSTMENT',
}

export enum RECEIPTS_PAYMENT_OPTIONS {
  PREMIUM = 'PREMIUM',
  DEPOSIT = 'DEPOSIT',
  MISCELLANEOUS = 'MISCELLANEOUS',
}

export enum CARD_PAYMENT_TYPE {
  ONLINE = 'ONLINE',
  CARD_MACHINE = 'CARD MACHINE',
}

export enum RECEIPTS_REFERENCE_CATEGORIES {
  BANK_TRANSFER_TYPE = 'BANK TRANSFER TYPES',
}

export enum MISCELLANEOUS_REFERENCE_CATEGORIES {
  MISCELLANEOUS_TYPES = 'MISCELLANEOUS TYPES',
}

export enum MISCELLANEOUS_FIELDS {
  CODE = 'CODE',
  MISCELLANEOUS_TYPE = 'MISCELLANEOUS TYPE',
}

export enum APPROVE_STATUS {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
}

export enum RECEIPT_REQUEST_CANCELLATION_REASONS {
  USER_MISTAKE = 'User Mistake',
  RETURNED_CHEQUE = 'Returned Cheque',
}

//  from accounts
export enum ACCOUNT_REFERENCE_CATEGORIES {
  BANKS = 'BANKS',
  BANK_BRANCHES = 'BRANCHES',
}

export enum ACCOUNT_TYPES {
  SAVINGS = 'Savings',
  CURRENT = 'Current',
  DEPOSIT = 'Deposit',
}

export enum CONTROL_TYPES {
  BANK = 'Bank',
  RECEIPT = 'Receipt',
}

export enum TRANSACTION_TYPES {
  COLLECTION = 'Collection',
  PAYMENT = 'Payment',
}
