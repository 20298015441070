export enum PAYOUTS_STATUSES {
  DRAFT = 'DRAFT',
  IN_QUEUE = 'IN_QUEUE',
  APPROVED = 'APPROVED',
  PAID = 'PAID',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING_APPROVAL_REPRINT = 'PENDING_APPROVAL_REPRINT',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  CANCELLATION_REQUEST = 'CANCELLATION_REQUESTED',
  APPROVED_REPROCESSING = 'APPROVED_(RE-PROCESSING)',
  PAYMENT_REPROCESSING = 'PAYMENT_REPROCESSING',
}

export enum SELECTED_BUTTON {
  REQUEST_APPROVAL = 'REQUEST_APPROVAL',
  ALLOW_PAYMENT = 'ALLOW_PAYMENT',
  PROCESS_PAYMENT = 'PROCESS_PAYMENT',
}

export enum PAYMENTS_METHOD_TYPES {
  BANK_TRANSFER = 'BANK_TRANSFER',
  CHEQUE = 'CHEQUE',
  MIXED = 'MIXED',
}

export enum DEDUCTION_TYPES {
  GENERAL = 'GENERAL',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum DEDUCTION_VALUE_TYPES {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
}

export enum DEDUCTION_SUBJECTIVE_VALUES {
  GROSS_EARNINGS = 'GROSS_EARNINGS',
}

export enum DEDUCTION_CRITERIA_APPLICABILITY_VALUES {
  ALL = 'ALL',
  ANY = 'ANY',
}

export enum DEDUCTION_CRITERIA_SUBJECTIVE_VALUES {
  GROSS_EARNINGS = 'GROSS_EARNINGS',
}

export enum CRITERIA_RELATIONSHIP_VALUES {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
}

export enum PAYOUTS_BULK_DEDUCTION_ADD_RESPONSES {
  INVALID_AGENT_CODE = 'INVALID_AGENT_CODE',
  INVALID_BATCH = 'INVALID_BATCH',
  INVALID_SUBJECTIVE_VALUE = 'INVALID_SUBJECTIVE_VALUE',
  INVALID_DEDUCTION_VALUE = 'INVALID_DEDUCTION_VALUE',
}

export enum PAYOUTS_DEFAULT_FIELDS {
  EMPLOYMENT_TYPE = 'Employment Type',
}

export enum ENTRIES_TYPES {
  CREDIT = 'Credit',
  DEBIT = 'Debit',
}

export enum PAYOUTS_CONFIG_DATA_MAPPINGS {
  COMMISSION_BREAKDOWN = 'COMMISSION_BREAKDOWN',
}

export enum PAYOUTS_BATCH_DATE_RANGE_TYPES {
  BATCH = 'Batch',
  CURRENT_MONTH = 'Current Month',
  LAST_THREE_MONTHS = 'Last Three Months',
  LAST_SIX_MONTHS = 'Last Six Months',
}

export enum PAYOUTS_ENTRIES_CONFIG_TYPE {
  EARNING = 'EARNING',
  DEDUCTION = 'DEDUCTION',
}
