import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ELAutocompleteElement } from '@el-autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { Observable, takeUntil } from 'rxjs';

import {
  CONFIGURABLE_FIELD_CHECKBOX_TYPES,
  CONFIGURABLE_FIELD_DATA_TYPES,
  FIELD_MODE_TYPES,
  MODULES,
} from '@shared/constants';
import {
  ICheckBoxRadioButtonOptionsConfig,
  IConfigurableFieldConfig,
  IConfigurableFieldConfigCheckBox,
  IConfigurableFieldConfigResponse,
  IConfigurableFieldDataTypes,
  ICriteriaConfig,
  IReferenceCategoryResponse,
  IRegexConfig,
} from '@shared/interfaces';
import {
  generateAdditionalFieldDropdownOptions,
  getModuleWiseAdditionalFields,
  setBackCheckboxes,
} from '@shared/utils';

import { noWhitespaceValidator } from '../../core/helpers/form-field-white-space-validator';
import {
  IAddCriteria,
  IConfigureAdditionalFieldElement,
  IConfigureConfigurableFieldElement,
  IRadioCheckboxOptions,
  IRegexField,
} from '../types';

import { DisplayConfigurableFieldService } from './display-configurable-field.service';

type FormGenerationOptions = {
  defaultCheckboxes: IConfigurableFieldConfigCheckBox[];
  referenceCategories: IReferenceCategoryResponse[];
  validityChecker: {
    validate: () => void;
    validateUntil: Observable<void>;
  };
  disableMetaFields?: boolean;
  allowWhiteSpacesInName?: boolean;
  mode?: FIELD_MODE_TYPES;
};

@Injectable({ providedIn: 'root' })
export class ConfigureConfigurableFieldService {
  constructor(
    private translate: TranslateService,
    private displayConfigurableFieldService: DisplayConfigurableFieldService
  ) {}

  private getEmptyField(module?: MODULES): IConfigurableFieldConfig {
    return {
      name: '',
      type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      additional_fields: getModuleWiseAdditionalFields(module),
    };
  }

  async generateSingleFormField(
    options: FormGenerationOptions,
    module: MODULES,
    oldFieldConfig?: IConfigurableFieldConfig
  ): Promise<IConfigureConfigurableFieldElement> {
    const field = oldFieldConfig ?? this.getEmptyField(module);
    const regexValidator = (control: FormControl<string>) => {
      let isValid = true;
      try {
        new RegExp(control.value);
      } catch (e) {
        isValid = false;
      }
      return isValid ? null : { invalidRegex: true };
    };

    const validateAndMarkAsPristine = (formField: FormControl) => {
      if (options.validityChecker) {
        formField.valueChanges
          .pipe(takeUntil(options.validityChecker.validateUntil))
          .subscribe(() => options.validityChecker.validate());
      }

      formField.markAsPristine();
    };

    const nameFieldValidators = [Validators.required];
    if (!options.allowWhiteSpacesInName) {
      nameFieldValidators.push(noWhitespaceValidator);
    }

    const name = new FormControl(
      {
        value: field.name,
        disabled: options.disableMetaFields || field.mandatory,
      },
      nameFieldValidators
    );
    validateAndMarkAsPristine(name);

    const fieldType = new FormControl<CONFIGURABLE_FIELD_DATA_TYPES>(
      {
        value: field.type,
        disabled: options.disableMetaFields || field.mandatory,
      },
      Validators.required
    );
    validateAndMarkAsPristine(fieldType);

    const referenceCategory = new FormControl({
      value: field?.reference_type_field_config?.category_id?.toString(),
      disabled: options.disableMetaFields,
    });
    validateAndMarkAsPristine(referenceCategory);

    const referenceField = new FormControl({
      value: field?.reference_type_field_config?.field_id?.toString(),
      disabled: options.disableMetaFields,
    });
    validateAndMarkAsPristine(referenceField);

    const defaultValue = new FormControl<IConfigurableFieldDataTypes>(
      field.default_value
    );
    validateAndMarkAsPristine(defaultValue);

    const layout = new FormControl(field.layout, [
      ...(field.mode === FIELD_MODE_TYPES.ADVANCED
        ? [Validators.required]
        : []),
    ]);
    const uniqueID = new FormControl(
      field.uniqueID ?? field.name,
      Validators.required
    );

    const checkboxOptions: IRadioCheckboxOptions[] = (
      field.checkboxOptions ?? []
    ).map((checkbox) => {
      const displayValue = new FormControl(
        checkbox.displayValue,
        Validators.required
      );
      const value = new FormControl(checkbox.value, Validators.required);

      validateAndMarkAsPristine(displayValue);
      validateAndMarkAsPristine(value);

      return {
        displayValue,
        value,
        checked: checkbox.checked,
      };
    });

    const radioButtonOptions: IRadioCheckboxOptions[] = (
      field.radioButtonOptions ?? []
    ).map((radioButton) => {
      const displayValue = new FormControl(
        radioButton.displayValue,
        Validators.required
      );
      const value = new FormControl(radioButton.value, Validators.required);

      validateAndMarkAsPristine(displayValue);
      validateAndMarkAsPristine(value);

      return {
        displayValue,
        value,
        checked: radioButton.checked,
      };
    });

    const criteria: IAddCriteria[] = (field.criteria ?? []).map((criterion) => {
      const criteria = new FormControl(criterion.criteria, Validators.required);
      const dependent_field = new FormControl(
        criterion.dependent_field,
        Validators.required
      );
      const compare_with = new FormControl(
        criterion.compare_with,
        Validators.required
      );
      const comparator = new FormControl(criterion.comparator);
      const compare_value = new FormControl(
        criterion.compare_value,
        Validators.required
      );

      validateAndMarkAsPristine(criteria);
      validateAndMarkAsPristine(dependent_field);
      validateAndMarkAsPristine(compare_with);
      validateAndMarkAsPristine(comparator);
      validateAndMarkAsPristine(compare_value);

      return {
        criteria,
        dependent_field,
        compare_with,
        comparator,
        compare_value,
      };
    });

    const regexField: IRegexField = {
      regex: new FormControl(field.regexField?.regex, regexValidator),
      regexType: new FormControl(field.regexField?.type),
    };
    const booleanLabels = {
      trueLabel: new FormControl(
        field.booleanLabels?.trueLabel ??
          this.translate.instant('configurable-fields.boolean-fields.true')
      ),
      falseLabel: new FormControl(
        field.booleanLabels?.falseLabel ??
          this.translate.instant('configurable-fields.boolean-fields.false')
      ),
    };

    const additionalFormFields: IConfigureAdditionalFieldElement[] = [];
    if (field?.additional_fields && field?.additional_fields?.length > 0) {
      field?.additional_fields?.forEach((additional_filed) => {
        const additionalFormField: IConfigureAdditionalFieldElement = {
          field: new FormControl({
            value: additional_filed.value,
            disabled: additional_filed.isDisabled,
          }),
          options: generateAdditionalFieldDropdownOptions(
            additional_filed.dropdown_options_generator
          ),
          key: additional_filed.key,
          label: additional_filed.label,
          errorMessage: additional_filed.errorMessage,
          placeholder: additional_filed.placeholder,
          type: additional_filed.type,
          isRequired: additional_filed.isRequired,
          isDisabled: additional_filed.isDisabled,
          dropdown_options_generator:
            additional_filed.dropdown_options_generator,
        };

        if (options.validityChecker) {
          additionalFormField.field.valueChanges
            .pipe(takeUntil(options.validityChecker.validateUntil))
            .subscribe(() => options.validityChecker.validate());
        }
        additionalFormFields.push(additionalFormField);
      });
    }

    const checkboxes = setBackCheckboxes(
      options.defaultCheckboxes ?? [],
      field?.checkboxes
    );

    let referenceFieldsForSelectedCategory: ELAutocompleteElement[] = [];
    if (
      field.mode === FIELD_MODE_TYPES.ADVANCED &&
      field.type === CONFIGURABLE_FIELD_DATA_TYPES.REFERENCES &&
      field.reference_type_field_config?.category_id &&
      field.reference_type_field_config?.field_id
    ) {
      referenceFieldsForSelectedCategory =
        await this.displayConfigurableFieldService.getReferenceAutocompleteElements(
          'internal',
          field.reference_type_field_config.category_id.toString(),
          field.reference_type_field_config.field_id.toString(),
          true
        );
    }

    return {
      _id: field._id,
      mode: options?.mode ?? field.mode ?? FIELD_MODE_TYPES.BASIC,
      mandatory: field.mandatory,
      type: fieldType,
      name,
      uniqueID,
      layout,
      reference_category: referenceCategory,
      reference_field: referenceField,
      checkboxes,
      // TODO: came from BOMS. Remove if not needed
      // checkboxes: this.defaultCheckboxes.map(
      //   (checkbox) =>
      //     field.checkboxes.find(
      //       (fieldCheckbox) => checkbox.type === fieldCheckbox.type
      //     ) ?? { ...checkbox }
      // ),
      selectedCategory:
        field.type === CONFIGURABLE_FIELD_DATA_TYPES.REFERENCES
          ? (options.referenceCategories ?? []).find(
              (category) =>
                category._id === field.reference_type_field_config.category_id
            )
          : undefined,
      referencesForSelectedField: [],
      additionalFormFields,
      displayTheDefaultFieldItem: {
        field_value: defaultValue,
        configuration: {
          name: 'Default Value',
          type: fieldType.value,
        },
      },
      booleanLabels,
      referenceFieldsForSelectedCategory,
      checkboxOptions,
      radioButtonOptions,
      regexField,
      criteria,
    };
  }

  async generateFormFields(
    options: FormGenerationOptions,
    module: MODULES,
    fields: IConfigurableFieldConfigResponse[]
  ): Promise<IConfigureConfigurableFieldElement[]> {
    return Promise.all(
      fields.map((field) =>
        this.generateSingleFormField(options, module, field)
      )
    );
  }

  getValueFromSingleFieldConfig(
    _field: IConfigureConfigurableFieldElement
  ): IConfigurableFieldConfigResponse {
    const field = cloneDeep(_field);

    const reference_type_field_config = {
      category_id: field.selectedCategory
        ? field.reference_category.value
        : undefined,
      field_id: field.selectedCategory
        ? field.reference_field.value
        : undefined,
      field: undefined,
      category: undefined,
    };

    const checkboxes: IConfigurableFieldConfigCheckBox[] = [];

    // make field default
    field.checkboxes.push({
      key: 'Default',
      isChecked: true,
      type: CONFIGURABLE_FIELD_CHECKBOX_TYPES.DEFAULT,
    });

    field?.checkboxes?.forEach((checkbox) => {
      checkboxes.push(checkbox);
    });

    let regexField: IRegexConfig;

    if (field.regexField?.regexType.value && field.regexField?.regex.value) {
      regexField = {
        type: field.regexField?.regexType.value,
        regex: field.regexField?.regex.value,
      };
    }

    const checkboxOptions: ICheckBoxRadioButtonOptionsConfig[] = [];
    if (field.checkboxOptions.length > 0) {
      field.checkboxOptions.forEach((option) => {
        checkboxOptions.push({
          displayValue: option.displayValue.value,
          value: option.value.value,
          checked: option.checked,
        });
      });
    }

    const radioButtonOptions: ICheckBoxRadioButtonOptionsConfig[] = [];
    if (field.radioButtonOptions.length > 0) {
      field.radioButtonOptions.forEach((option) => {
        radioButtonOptions.push({
          displayValue: option.displayValue.value,
          value: option.value.value,
          checked: option.checked,
        });
      });
    }

    const criteria: ICriteriaConfig[] = [];
    if (field.criteria.length > 0) {
      field.criteria.forEach((criterion) => {
        criteria.push({
          criteria: criterion.criteria.value,
          dependent_field: criterion.dependent_field.value,
          compare_with: criterion.compare_with.value,
          comparator: criterion.comparator?.value,
          compare_value: criterion.compare_value.value,
        });
      });
    }

    let checkedValues;
    if (field.type.value === CONFIGURABLE_FIELD_DATA_TYPES.RADIO_BUTTON) {
      // get the checked radio button option
      checkedValues = field.radioButtonOptions.find((option) => option.checked)
        ?.value.value;
    } else if (field.type.value === CONFIGURABLE_FIELD_DATA_TYPES.CHECKBOX) {
      // get all checked checkbox options
      checkedValues = field.checkboxOptions
        .filter((_option) => _option.checked)
        .map((option) => option.value.value);
    }

    return {
      _id: field._id,
      mode: FIELD_MODE_TYPES.ADVANCED,
      uniqueID: field.uniqueID.value,
      reference_type_field_config,
      name: field.name.value,
      type: field.type.value,
      layout: field.layout.value,
      regexField,
      default_value: [
        CONFIGURABLE_FIELD_DATA_TYPES.RADIO_BUTTON,
        CONFIGURABLE_FIELD_DATA_TYPES.CHECKBOX,
      ].includes(field.type.value)
        ? checkedValues
        : field.displayTheDefaultFieldItem.field_value.value,
      checkboxes,
      checkboxOptions,
      radioButtonOptions,
      criteria,
      booleanLabels: {
        trueLabel: field.booleanLabels?.trueLabel?.value,
        falseLabel: field.booleanLabels?.falseLabel?.value,
      },
    };
  }
}
