import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PremiumCalculationData, PremiumCalSubInfo } from '@shared/interfaces';
import { thousandSeparatorAdd } from '@shared/utils';

import { FlowControlService } from '../../../../services/flow-control.service';
import { CurrenciesService } from '../../../setup/currencies/services/currencies.service';

@Component({
  selector: 'premium-displayer',
  templateUrl: './premium-displayer.component.html',
  styleUrls: ['./premium-displayer.component.scss'],
})
export class DisplayPremiumSectionComponent implements OnInit {
  @Input() premiumCalculationData: PremiumCalculationData;
  @Input() baseCurrency: string;

  covers: PremiumCalSubInfo[] = [];
  fees_and_charges: PremiumCalSubInfo[] = [];
  premium: number;

  thousandSeparatorAdd = thousandSeparatorAdd;

  constructor(
    route: ActivatedRoute,
    private router: Router,
    private flowControlService: FlowControlService,
    private currenciesService: CurrenciesService
  ) {
    this.flowControlService.updateRouteInfo(
      this.router.url.split('?')[0],
      route.snapshot.data
    );
  }

  ngOnInit(): void {
    this.baseCurrency = this.baseCurrency
      ? `(${this.baseCurrency})`
      : `(${this.currenciesService.baseCurrency.code})`;
    this.covers = this.premiumCalculationData?.covers;
    this.fees_and_charges = this.premiumCalculationData?.fees_and_charges;
    this.premium = this.premiumCalculationData?.premium_amount;
  }
}
