export enum COMPETITION_STATUSES {
  IN_PROGRESS = 'IN PROGRESS',
  FINISHED = 'FINISHED',
  STOPPED = 'STOPPED',
  PENDING = 'PENDING',
}

export enum COMPETITION_TYPES {
  TARGETS = 'Targets',
  ACTIVITIES = 'Activities',
}
