import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  IterableDiffers,
  OnInit,
  Output,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSelectChange } from '@angular/material/select';
import { ExportToCsv } from 'export-to-csv';

import { getValueFromDataElement } from './services/data-list-table.service';
import { ResizeService, SCREEN_SIZE } from './services/screen-size.service';
import {
  DataElementActionProperties,
  DataListTableActionElement,
  DataListTableActionEvent,
  DataListTableDroppedElement,
  DataListTableElement,
  DataListTableElementCheckBoxClickedEvent,
} from './types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'el-data-list-table',
  templateUrl: 'el-data-list-table.component.html',
  styleUrls: ['el-data-list-table.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ElDataListTable implements OnInit, DoCheck {
  @Input() data: DataListTableElement[] = [];
  @Input() totalCount = 0;
  @Input() pageSize = 10;
  @Input() getAllButtonEnabled = false;
  @Input() downloadButtonEnabled = false;
  @Input() showMoreButtonEnabled = false;
  @Input() forcedSingleColumnView = false;
  @Input() alwaysShowIcon = false;
  @Input() showSelectAllCheckbox = false;
  @Input() disableSelectAllCheckbox = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClickAction = new EventEmitter<DataListTableActionEvent>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClickGetAll = new EventEmitter<DataListTableElement[]>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClickShowMore = new EventEmitter<boolean>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onChangePageSize = new EventEmitter<number>();

  // expand collapse cards
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onElementExpand = new EventEmitter<number>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onElementCollapse = new EventEmitter<number>();

  // CheckBox click
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onCheckBoxClickedAction =
    new EventEmitter<DataListTableElementCheckBoxClickedEvent>();

  // DataElementButton Related
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onDataElementClickAction =
    new EventEmitter<DataElementActionProperties>();

  iterableDiffer: any;
  loadingShowMore = false;
  panelOpenState = false;
  generatingCsv = false;
  emittedOnce = false;
  expandAll = false;
  previousLength = 0;
  defaultPageSize = 10;
  screenWidth?: number;
  filter = false;
  isMobileView = false;
  selectAllCheckboxValue = false;

  public steps = [10, 25, 50, 100];

  filterValue = '';
  filteredData: DataListTableElement[] = [];

  // for the column view
  @Input() columnViewEnabled = false;
  @Input() states: string[] = [];
  @Input() statesKey = '';
  @Input() draggable = false;
  @Output() droppedElement: EventEmitter<DataListTableDroppedElement> =
    new EventEmitter();

  columnView = false;
  columnViseFilteredData: {
    filterValue: string;
    data: DataListTableElement[];
  }[] = [];

  constructor(
    private _iterableDiffers: IterableDiffers,
    private resizeService: ResizeService
  ) {
    this.iterableDiffer = this._iterableDiffers.find(this.data).create();
    this.resizeService.onWindowResize.subscribe((size) => {
      setTimeout(() => {
        this.isMobileView =
          size.size === SCREEN_SIZE.XS || this.forcedSingleColumnView;
      });
    });
  }

  ngOnInit() {
    if (this.totalCount === 0 && this.data) {
      this.totalCount = this.data.length;
    }
    this.filterData();
  }

  ngDoCheck() {
    const changes = this.iterableDiffer.diff(this.data);

    if (changes) {
      if (this.totalCount === 0) {
        this.totalCount = changes.length;
      }

      this.loadingShowMore = false;
      this.filterValue = '';
      this.filteredData = [];
      this.columnViseFilteredData = [];
      this.pageSize = 10;
      this.filterData();
      this.previousLength = changes.length;
    }

    // set screenWidth on page load
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
    if (this.screenWidth > 800) {
      this.filter = true;
    }
  }

  onClickFilter() {
    this.filter = true;
  }

  triggerShowMore() {
    this.loadingShowMore = true;
    this.onClickShowMore.emit(true);
    this.pageSize = this.defaultPageSize;
    this.onChangePageSize.emit(this.pageSize);
  }

  triggerAction(
    panel: MatExpansionPanel,
    e: DataListTableActionElement,
    index: number
  ) {
    if (e?.action && e?.data) {
      const obj = {
        ...e,
        index: index - 1,
      };
      this.onClickAction.emit(obj);
      panel.expanded = false;
    }
  }

  onClickExpandAll() {
    this.filteredData = this.filteredData.map((element) => {
      if (!element.expanded) {
        element.expanded = true;
      }
      return element;
    });
    this.expandAll = true;
  }

  onClickCollapseAll() {
    this.filteredData = this.filteredData.map((element) => {
      if (element.expanded) {
        element.expanded = false;
      }
      return element;
    });
    this.expandAll = false;
  }

  exportAsCsv() {
    this.generatingCsv = true;
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const data: { [key: string]: string }[] = [];
    const keys = new Set<string>();
    const csvExporter = new ExportToCsv(options);
    if (this.data) {
      for (const element of this.data) {
        if (element?.data?.length) {
          const obj: { [key: string]: string } = {};
          element.data.forEach((item) => {
            const keyValueStringPair = getValueFromDataElement(item);
            if (keyValueStringPair) {
              obj[keyValueStringPair.key] = keyValueStringPair.value ?? '-';

              if (data.length && !keys.has(keyValueStringPair.key)) {
                data.forEach((_, i) => {
                  data[i][keyValueStringPair.key] = '-';
                });
              }

              keys.add(keyValueStringPair.key);
            } else if (item.key) {
              obj[item.key] = '-';
            }
          });
          data.push(obj);
        }
      }
    }
    csvExporter.generateCsv(data);
    this.generatingCsv = false;
  }

  getAll() {
    this.emittedOnce = true;
    this.onClickGetAll.emit(this.data);
  }

  pageSizeChanged(e: MatSelectChange) {
    this.pageSize = e.value;
    this.onChangePageSize.emit(this.pageSize);
  }

  filterData() {
    this.filteredData = this.data.filter((data) => {
      if (!this.filterValue || this.filterValue.toString().trim() === '') {
        return true;
      }

      const filterKey = this.filterValue.toString().toLowerCase().trim();
      const stringData = data.data.map((_data) => {
        if (!('value' in _data)) return '';

        return _data?.value?.toString();
      });
      return stringData.some((_stringData) =>
        (_stringData ?? '').toLowerCase().includes(filterKey)
      );
    });

    // For the column view
    if (this.statesKey && this.states.length > 0) {
      this.columnViseFilteredData = this.states.map((state) => ({
        filterValue: state,
        data: this.filteredData.filter((data) =>
          this.checkForState(data, state)
        ),
      }));
    }
  }

  // Column View Related Functions
  onClickListView() {
    this.columnView = false;
  }

  onClickColumnView() {
    this.columnView = true;
  }

  checkForState(item: DataListTableElement, state: string) {
    if (item?.data) {
      const stateData = item.data.find((data) => {
        return data.key === this.statesKey && (data as any)?.value === state;
      });
      return !!stateData;
    } else {
      return false;
    }
  }

  drop(event: CdkDragDrop<DataListTableElement[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.droppedElement.emit({
        data: event.container.data[event.currentIndex],
        sourceContainer:
          this.states[Number(event.previousContainer.id.substring(14))],
        destinationContainer:
          this.states[Number(event.container.id.substring(14))],
      });
    }
  }

  getColumnDataList(state: string) {
    const data = this.columnViseFilteredData.find(
      (_data) => _data.filterValue === state
    );
    return data?.data || [];
  }

  // End of column view related functions

  // expand collapse cards
  onExpandElement(index: number) {
    this.data[index - 1].expanded = true;
    this.onElementExpand.emit(index - 1); // because we manually adding +1 from html side on-purpose
  }

  onCollapseElement(index: number) {
    if (this.data[index - 1]) {
      this.data[index - 1].expanded = false;
      this.onElementExpand.emit(index - 1); // because we manually adding +1 from html side on-purpose
    }
  }

  // Begin:: Check box related
  onCheckBoxClicked(
    event: MatCheckboxChange,
    data: DataListTableElement,
    index: number
  ) {
    if (data) {
      const obj: DataListTableElementCheckBoxClickedEvent = {
        index,
        checkedStatus: event.checked,
        originalData: data,
      };
      this.onCheckBoxClickedAction.emit(obj);

      this.selectAllCheckboxValue = this.data.every(
        (data) => data?.checkBoxConfig?.isSelected
      );
    }
  }

  onPressSelectAllCheckbox(event: MatCheckboxChange) {
    this.data.forEach((_data, index) => {
      if (
        _data?.checkBoxConfig &&
        !_data.checkBoxConfig.isDisabled &&
        _data.checkBoxConfig.isSelected !== event.checked
      ) {
        _data.checkBoxConfig.isSelected = event.checked;
        const obj: DataListTableElementCheckBoxClickedEvent = {
          index,
          checkedStatus: event.checked,
          originalData: _data,
        };
        this.onCheckBoxClickedAction.emit(obj);
      }
    });
  }
  // End:: Check box related

  // Begin:: Data element button related
  dataElementActionClicked(action: string, index: number) {
    const obj: DataElementActionProperties = {
      action,
      index,
    };
    this.onDataElementClickAction.emit(obj);
  }
  // End:: Data element button related
}
