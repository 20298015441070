import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  AMENDMENT_TYPES,
  CUSTOMER_TYPES,
  ENDPOINTS,
  POLICY_CLASSES,
} from '@shared/constants';
import { PolicyCoverNoteRequestDTO } from '@shared/dtos';
import {
  CommonResponseDTO,
  IConfigurableFieldValueResponse,
  IExchangeRate,
  IPartnerExternalSystems,
  IPolicy,
  IPolicyResponse,
  IProductExternalSystems,
  IReferenceResponse,
  IResubmitQuotationRequest,
  IWorkflow,
  PolicyCancelInformation,
  PremiumCalculationData,
  ProductInformation,
  ProposalDocumentInformation,
  ProposalInformation,
  RenewalRequest,
  ShareData,
  TravelPremiumInformation,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class PoliciesService {
  public $selectedAction = new BehaviorSubject<string>(
    'policies.add-edit.add-title'
  );

  public $nic = new BehaviorSubject<string>(' ');
  public $notShowButton = new BehaviorSubject<boolean>(false);
  public $MarketValue = new BehaviorSubject<string>(' ');

  private readonly _customerTypeSub: BehaviorSubject<CUSTOMER_TYPES> =
    new BehaviorSubject<CUSTOMER_TYPES>(CUSTOMER_TYPES.INDIVIDUAL);

  customerTypeSub = this._customerTypeSub.asObservable();

  constructor(private http: HttpClient) {}

  createNewPolicy(data: IPolicy): Observable<CommonResponseDTO<IPolicy>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_ADD_NEW,
    });
    return this.http.post<CommonResponseDTO<IPolicy>>(url, data);
  }

  createNewVehicleFleetPolicy(
    data: IPolicy
  ): Observable<CommonResponseDTO<IPolicy>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_ADD_NEW_VEHICLE_FLEET,
    });
    return this.http.post<CommonResponseDTO<IPolicy>>(url, data);
  }

  createNewMedicalGroupPolicy(
    data: IPolicy
  ): Observable<CommonResponseDTO<IPolicy>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_ADD_NEW_MEDICAL_GROUP,
    });
    return this.http.post<CommonResponseDTO<IPolicy>>(url, data);
  }

  createNewTravelGroupPolicy(
    data: IPolicy
  ): Observable<CommonResponseDTO<IPolicy>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_ADD_NEW_TRAVEL_GROUP,
    });
    return this.http.post<CommonResponseDTO<IPolicy>>(url, data);
  }

  getPremiumCalculationData(
    data: ProductInformation | IPolicy,
    type: POLICY_CLASSES
  ): Observable<
    CommonResponseDTO<PremiumCalculationData | TravelPremiumInformation>
  > {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_PREMIUM_CALCULATION,
      params: { type },
    });
    return this.http.post<
      CommonResponseDTO<PremiumCalculationData | TravelPremiumInformation>
    >(url, data);
  }

  getAllProductsFromBOMS(): Observable<
    CommonResponseDTO<IProductExternalSystems[]>
  > {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_GET_ALL_BOMS_PRODUCTS,
    });
    return this.http.get<CommonResponseDTO<IProductExternalSystems[]>>(url);
  }

  getWorkflowFromBOMS(id: string): Observable<CommonResponseDTO<IWorkflow>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_GET_ONE_BOMS_WORKFLOW,
      params: { id },
    });
    return this.http.get<CommonResponseDTO<IWorkflow>>(url);
  }

  getAllPartnersFromBOMS(): Observable<
    CommonResponseDTO<IPartnerExternalSystems[]>
  > {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_GET_ALL_BOMS_PARTNERS,
    });
    return this.http.get<CommonResponseDTO<IPartnerExternalSystems[]>>(url);
  }

  // REQUIRED REFERENCE CATEGORIES ENUM NEED TO ADD TO THE CONTROLLER
  getReferencesFromBOMS(): Observable<CommonResponseDTO<IReferenceResponse[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_GET_BOMS_REFERENCES,
    });
    return this.http.get<CommonResponseDTO<IReferenceResponse[]>>(url);
  }

  getAllPolicies(
    params: HttpParams
  ): Observable<CommonResponseDTO<IPolicyResponse[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_GET_ALL,
    });
    return this.http.get<CommonResponseDTO<IPolicyResponse[]>>(url, {
      params,
    });
  }

  getPolicyData(
    params: HttpParams
  ): Observable<CommonResponseDTO<IPolicyResponse[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_DATA,
    });
    return this.http.get<CommonResponseDTO<IPolicyResponse[]>>(url, {
      params,
    });
  }

  getPolicy(id: string): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_GET_ONE,
      params: { id },
    });
    return this.http.get<CommonResponseDTO<IPolicyResponse>>(url);
  }

  updatePolicy(
    data: IPolicy,
    id: string
  ): Observable<CommonResponseDTO<IPolicy>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_UPDATE_ONE,
      params: { id },
    });
    return this.http.put<CommonResponseDTO<IPolicy>>(url, data);
  }

  addProposal(
    data: ProposalInformation,
    id: string
  ): Observable<CommonResponseDTO<IPolicy>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_ADD_PROPOSAL,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IPolicy>>(url, data);
  }

  cancelProposal(
    id: string,
    data: { reason: string }
  ): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_CANCEL_PROPOSAL,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, data);
  }

  verifyReturnDocument(
    id: string,
    data: ProposalDocumentInformation
  ): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_VERIFY_PROPOSAL_DOCUMENTS,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, { data });
  }

  proposalDocumentsResubmission(
    id: string,
    data: ProposalDocumentInformation[]
  ): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_PROPOSAL_RESUBMISSION,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, { data });
  }

  proposalRaisedDocumentsSubmission(
    id: string,
    data: ProposalDocumentInformation[]
  ): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_PROPOSAL_RAISED_DOCUMENTS_SUBMISSION,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, { data });
  }

  proposalDocumentsFinalVerification(
    id: string
  ): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_VERIFY_PROPOSAL_DOCUMENTS_FINAL,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, {});
  }

  proposalDocumentsView(
    id: string
  ): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_VIEW_PROPOSAL,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, {});
  }

  resubmitQuotationRequest(
    data: IResubmitQuotationRequest,
    id: string
  ): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_RESUBMIT,
      params: { id },
    });

    return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, data);
  }

  requestCoverNote(
    id: string,
    data: PolicyCoverNoteRequestDTO
  ): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_PROPOSAL_REQUEST_COVER_NOTE,
      params: { id },
    });

    return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, data);
  }

  deletePolicy(id: string): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_DELETE_ONE,
      params: { id },
    });
    return this.http.delete<CommonResponseDTO<IPolicyResponse>>(url);
  }

  restorePolicy(id: string): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_RESTORE_ONE,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, {});
  }

  shareToCustomerLink(
    data: ShareData
  ): Observable<CommonResponseDTO<ShareData>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_SHARE_TO_CUSTOMER_LINK,
    });
    return this.http.post<CommonResponseDTO<ShareData>>(url, data);
  }
  requestAmendment(
    id: string,
    data: { amendment_type: AMENDMENT_TYPES; amendment_remark: string }
  ): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_REQUEST_AMENDMENT,
      params: { id },
    });

    return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, data);
  }
  updateDocDelete(
    id: string,
    data: { file: string; _id: string }
  ): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_UPDATE_DOC_DELETE,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, data);
  }
  requestAmendmentStatus(
    id: string,
    data: { amendment_type: AMENDMENT_TYPES }
  ): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_REQUEST_AMENDMENT_STATUS,
      params: { id },
    });

    return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, data);
  }

  cancelAmendment(id: string): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_CANCEL_AMENDMENT,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, {});
  }

  // COMMON FUNC FOR BOMS REF GETTER
  extractValueFromKey(
    references: IReferenceResponse[],
    key: string,
    fieldType: string
  ) {
    const val = references.find((ref) => ref._id === key)?.reference || [];

    return (
      val
        ?.find((ref) => ref?.field?.name?.toUpperCase()?.trim() === fieldType)
        ?.value?.toString() || ''
    );
  }

  getListValueFromReference = (
    reference: IConfigurableFieldValueResponse[],
    type: string
  ) => {
    return (
      reference
        ?.find((ref) => ref?.field?.name?.toUpperCase()?.trim() === type)
        ?.value?.toString() || ''
    );
  };
  public listenToActionChange(value: string) {
    this.$selectedAction.next(value);
  }

  public getProposerNic(value: string) {
    this.$nic.next(value);
  }

  public listenToShowButtonChange(value: boolean) {
    this.$notShowButton.next(value);
  }

  public getMarketValue(value: string) {
    this.$MarketValue.next(value);
  }

  listenToCustomerTypeChange(value: CUSTOMER_TYPES) {
    this._customerTypeSub.next(value);
  }

  cancelPolicy(
    id: string,
    data: PolicyCancelInformation
  ): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_POLICY_CANCELATION,
      params: { id },
    });

    return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, data);
  }

  getAllExchangeRates(
    params: HttpParams
  ): Observable<CommonResponseDTO<IExchangeRate[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.EXCHANGE_RATES_GET_ALL,
    });
    return this.http.get<CommonResponseDTO<IExchangeRate[]>>(url, { params });
  }

  requestRenewal(
    id: string,
    data: RenewalRequest
  ): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_REQUEST_POLICY_RENEWALS,
      params: { id },
    });

    return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, data);
  }
}
