export enum USER_TYPES {
  EL_ADMIN = 'EL',
  USER = 'U',
}

export enum ACCOUNT_STATES {
  NEW = 'NEW',
  VERIFIED = 'VERIFIED',
  NEW_BLOCKED = 'NEW_BLOCKED',
  VERIFIED_BLOCKED = 'VERIFIED_BLOCKED',
  DELETED = 'DELETED',
}

export enum IDENTITIES_BULK_FIELDS {
  SALUTATION = 'salutation',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  EMAIL = 'email',
  REPORTS_TO_USERNAME = 'reports_to_username',
  USERNAME = 'username',
  PASSWORD = 'password',
  DIAL_CODE = 'dial_code',
  PHONE_NUMBER = 'phone_number',
  INTERNAL_FIELDS = 'internal_fields',
}

export enum IDENTITIES_BULK_FIELDS_FOR_CSV {
  SALUTATION = 'salutation',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  EMAIL = 'email',
  REPORTS_TO_USERNAME = 'reports_to_username',
  USERNAME = 'username',
  PASSWORD = 'password',
  DIAL_CODE = 'dial_code',
  PHONE_NUMBER = 'phone_number',
}

export enum SALUTATIONS {
  ORG = 'Org.',
  MR = 'Mr.',
  MRS = 'Mrs.',
  MISS = 'Miss',
  DR = 'Dr.',
  MS = 'Ms.',
  PROF = 'Prof.',
  REV = 'Rev.',
  LADY = 'Lady',
  SIR = 'Sir',
  CAPT = 'Capt.',
  MAJOR = 'Major',
  LT_COL = 'Lt.-Col.',
  COL = 'Col.',
  LT_CMDR = 'Lt.-Cmdr.',
  HON = 'The Hon.',
  CMDR = 'Cmdr.',
  FLT_LT = 'Flt. Lt.',
  BRIGADIER = 'Brgdr.',
  JUDGE = 'Judge',
  LORD = 'Lord',
  HON_MRS = 'The Hon. Mrs',
  WNG_CMDR = 'Wng. Cmdr.',
  GRP_CPT = 'Group Capt.',
  RT_HON_LORD = 'Rt. Hon. Lord',
  REV_FATHER = 'Revd. Father',
  REV_CANON = 'Revd Canon',
  MAJ_GEN = 'Maj.-Gen.',
  AIR_CDRE = 'Air Cdre.',
  VISCOUNT = 'Viscount',
  DAME = 'Dame',
  REAR_ADMRL = 'Rear Admrl.',
}

export const LANGUAGE_CODES = [
  'ab',
  'aa',
  'af',
  'ak',
  'sq',
  'am',
  'ar',
  'an',
  'hy',
  'as',
  'av',
  'ae',
  'ay',
  'az',
  'bm',
  'ba',
  'eu',
  'be',
  'bn',
  'bi',
  'bs',
  'br',
  'bg',
  'my',
  'ca',
  'ch',
  'ce',
  'ny',
  'zh',
  'cu',
  'cv',
  'kw',
  'co',
  'cr',
  'hr',
  'cs',
  'da',
  'dv',
  'nl',
  'dz',
  'en',
  'eo',
  'et',
  'ee',
  'fo',
  'fj',
  'fi',
  'fr',
  'fy',
  'ff',
  'gd',
  'gl',
  'lg',
  'ka',
  'de',
  'el',
  'kl',
  'gn',
  'gu',
  'ht',
  'ha',
  'he',
  'hz',
  'hi',
  'ho',
  'hu',
  'is',
  'io',
  'ig',
  'id',
  'ia',
  'ie',
  'iu',
  'ik',
  'ga',
  'it',
  'ja',
  'jv',
  'kn',
  'kr',
  'ks',
  'kk',
  'km',
  'ki',
  'rw',
  'ky',
  'kv',
  'kg',
  'ko',
  'kj',
  'ku',
  'lo',
  'la',
  'lv',
  'li',
  'ln',
  'lt',
  'lu',
  'lb',
  'mk',
  'mg',
  'ms',
  'ml',
  'mt',
  'gv',
  'mi',
  'mr',
  'mh',
  'mn',
  'na',
  'nv',
  'nd',
  'nr',
  'ng',
  'ne',
  'no',
  'nb',
  'nn',
  'ii',
  'oc',
  'oj',
  'or',
  'om',
  'os',
  'pi',
  'ps',
  'fa',
  'pl',
  'pt',
  'pa',
  'qu',
  'ro',
  'rm',
  'rn',
  'ru',
  'se',
  'sm',
  'sg',
  'sa',
  'sc',
  'sr',
  'sn',
  'sd',
  'si',
  'sk',
  'sl',
  'so',
  'st',
  'es',
  'su',
  'sw',
  'ss',
  'sv',
  'tl',
  'ty',
  'tg',
  'ta',
  'tt',
  'te',
  'th',
  'bo',
  'ti',
  'to',
  'ts',
  'tn',
  'tr',
  'tk',
  'tw',
  'ug',
  'uk',
  'ur',
  'uz',
  've',
  'vi',
  'vo',
  'wa',
  'cy',
  'wo',
  'xh',
  'yi',
  'yo',
  'za',
  'zu',
];

export enum IDENTITIES_CUSTOM_PERMISSIONS {
  IDENTITIES_HIERARCHY = 'HIERARCHY:IDENTITIES',
  IDENTITIES_INTEGRATION = 'INTEGRATION:IDENTITIES',
  IDENTITIES_IMPERSONATE = 'IMPERSONATE:IDENTITIES',
}

export const IDENTITIES_ACTIONS = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  RESTORE: 'RESTORE',
  RESET: 'RESET',
  BLOCK: 'BLOCK',
  UNBLOCK: 'UNBLOCK',
  RESEND: 'RESEND',
  CONNECT: 'CONNECT',
  VIEW: 'VIEW',
  ADD: 'ADD',
  IMPERSONATE: 'IMPERSONATE',
  VIEW_ACHIEVEMENTS: 'VIEW_ACHIEVEMENTS',
  VIEW_TARGETS: 'VIEW_TARGETS',
};

export const IDENTITIES_ACCOUNT_STATE = {
  NEW: 'NEW',
  VERIFIED: 'VERIFIED',
  NEW_BLOCKED: 'NEW_BLOCKED',
  VERIFIED_BLOCKED: 'VERIFIED_BLOCKED',
  DELETED: 'DELETED',
};

export const IDENTITIES_SPEED_DIAL = {
  ADD_NEW: 'ADD_NEW',
  VIEW_HIERARCHY: 'VIEW_HIERARCHY',
  EXPORT_AS_CSV: 'EXPORT_AS_CSV',
};
