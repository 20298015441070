import { isNumber } from 'lodash';

export const thousandSeparatorRemove = (
  value: number | string,
  currencyCode?: string
): number | null => {
  value = String(value);
  if (value.includes(`${currencyCode} `)) {
    const levels = value.split(`${currencyCode} `);
    value = String(levels[1]);
  }
  value = value.replace(/,/g, '');
  value = value.replace(/\s/g, '');

  return value.length ? Number(value) : null;
};

export const thousandSeparatorAdd = (
  num: string | number,
  options?: {
    currencyCode?: string;
    onlyTwoDecimals?: boolean;
    negativeValuesInsideBrackets?: boolean;
    replaceNegativeValuesWithZero?: boolean;
  }
) => {
  const number = thousandSeparatorRemove(String(num));

  let numValue = number;

  if (
    options?.replaceNegativeValuesWithZero &&
    numValue !== null &&
    numValue < 0
  ) {
    numValue = 0;
  }

  let numString = numValue?.toString();
  if (isNumber(numValue) && options?.onlyTwoDecimals) {
    numString = numValue.toFixed(2);
  }
  const n = numString ?? '';
  const isNegative = n.startsWith('-');
  const p = n.indexOf('.');
  const prefix = options?.currencyCode ? `${options?.currencyCode} ` : '';
  const value =
    prefix +
    n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) =>
      p < 0 || i < p ? `${m},` : m
    );
  if (options?.negativeValuesInsideBrackets && isNegative) {
    return `(${value.replace('-', '')})`;
  }

  return value;
};
