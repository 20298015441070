<mat-card
  class="widget__container"
  *ngIf="hasPermissions && clickable"
  matRipple
  (dblclick)="onClickWidget()"
>
  <mat-card-content *ngIf="isLoading">
    <app-group-bar-chart-skeleton></app-group-bar-chart-skeleton>
  </mat-card-content>

  <mat-card-content
    *ngIf="(isFailedToLoad && !isLoading) || !loggedUserInfo"
    class="widget__content"
  >
    <div class="load-error">
      <div class="error-content">
        <mat-icon class="error-icon">error_outline</mat-icon>
        <div class="error-message">
          <div class="pl-1 error-text">
            <span class="failed-message-bold">{{
              'widgets.error.failed-to-load' | translate
            }}</span>
            <span class="failed-message">{{
              'widgets.error.refresh' | translate
            }}</span>
          </div>
        </div>
      </div>
      <button mat-icon-button (click)="refreshWidget()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </mat-card-content>

  <div
    class="widget__details"
    *ngIf="!isFailedToLoad && loggedUserInfo && !isLoading"
  >
    <div class="widget__description">
      <span class="title">{{ widget?.title | translate }}</span>
      <!-- <span class="subtitle">{{
        widget?.category_description | translate
      }}</span> -->
      <span class="subtitle">{{ description }}</span>
    </div>
    <button mat-icon-button (click)="refreshWidget()">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>

  <mat-card-content
    class="widget__content"
    *ngIf="!isFailedToLoad && loggedUserInfo && !isLoading"
  >
    <div class="widget__wrapper">
      <ngx-charts-bar-vertical-2d
        [view]="view"
        [results]="result"
        [xAxis]="true"
        [yAxis]="true"
        [showGridLines]="true"
        [legend]="true"
        [roundDomains]="true"
        [roundEdges]="true"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        [groupPadding]="5"
        [barPadding]="0.5"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [yAxisTicks]="[0, 20, 40, 60, 80, 100]"
        [yScaleMax]="100"
      >
      </ngx-charts-bar-vertical-2d>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="widget__container" *ngIf="hasPermissions && !clickable">
  <mat-card-content *ngIf="isLoading">
    <app-bar-chart-skeleton></app-bar-chart-skeleton>
  </mat-card-content>

  <mat-card-content
    *ngIf="isFailedToLoad && !isLoading"
    class="widget__content"
  >
    <div class="load-error">
      <div class="error-content">
        <mat-icon class="error-icon">error_outline</mat-icon>
        <div class="error-message">
          <div class="pl-1 error-text">
            <span class="failed-message-bold">{{
              'widgets.error.failed-to-load' | translate
            }}</span>
            <span class="failed-message">{{
              'widgets.error.refresh' | translate
            }}</span>
          </div>
        </div>
      </div>
      <button mat-icon-button (click)="refreshWidget()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </mat-card-content>

  <div class="widget__details" *ngIf="!isFailedToLoad && !isLoading">
    <div class="widget__description">
      <span class="title">{{ widget?.title | translate }}</span>
      <!-- <span class="subtitle">{{
        widget?.category_description | translate
      }}</span> -->
      <span class="subtitle">{{ description }}</span>
    </div>
    <button mat-icon-button (click)="refreshWidget()">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>

  <mat-card-content
    class="widget__content"
    *ngIf="!isFailedToLoad && !isLoading"
  >
    <div class="widget__wrapper">
      <ngx-charts-bar-vertical-2d
        [view]="view"
        [results]="result"
        [xAxis]="true"
        [yAxis]="true"
        [showGridLines]="true"
        [legend]="true"
        [roundDomains]="true"
        [roundEdges]="true"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        [groupPadding]="5"
        [barPadding]="0.5"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [yAxisTicks]="[0, 20, 40, 60, 80, 100]"
        [yScaleMax]="100"
        [class.dark-mode-x]="darkMode"
        [class.dark-mode-y]="darkMode"
      >
      </ngx-charts-bar-vertical-2d>
    </div>
  </mat-card-content>
</mat-card>
