import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { isString } from 'lodash';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';

import {
  COMPARISON_TYPES,
  CONFIGURABLE_FIELD_DATA_TYPES,
  FIELD_LAYOUT_TYPES,
} from '@shared/constants';
import {
  ICheckBoxRadioButtonOptionsConfig,
  IReferenceCategoryResponse,
} from '@shared/interfaces';
import { isDefault } from '@shared/utils';

import { IDisplayConfigurableFieldElement } from '../../types';

@Component({
  selector: 'app-display-configurable-field',
  templateUrl: './display-configurable-field.component.html',
  styleUrls: ['./display-configurable-field.component.scss'],
})
export class DisplayConfigurableFieldComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() fieldArray: IDisplayConfigurableFieldElement[];
  @Input() referenceCategory?: IReferenceCategoryResponse;
  @Input() disableAll = false;
  @Input() readonlyAll = false;

  // TODO: Need to change this to observable
  @Input() clearAllFields: Subject<void>;

  @Output() onCheckValidity: EventEmitter<boolean> = new EventEmitter();
  @Output() onFieldDeleted: EventEmitter<boolean> = new EventEmitter();
  @Output() onDeletedFieldEmit: EventEmitter<IDisplayConfigurableFieldElement> =
    new EventEmitter();
  @Output() onSetNullValue: EventEmitter<boolean> = new EventEmitter();
  @Output() onFieldValueChange?: EventEmitter<void> = new EventEmitter();

  defaultFields: IDisplayConfigurableFieldElement[] = [];
  nonDefaultFields: IDisplayConfigurableFieldElement[] = [];
  usedNonDefaultFields: {
    field_name: FormControl;
    field: IDisplayConfigurableFieldElement;
  }[] = [];

  FIELD_LAYOUT_TYPES = FIELD_LAYOUT_TYPES;

  private onDestroyClearFields$ = new Subject<void>();

  ngOnChanges(changes: SimpleChanges): void {
    const fieldArrayChanges = changes.fieldArray;
    const fieldArray: IDisplayConfigurableFieldElement[] =
      fieldArrayChanges?.currentValue ?? [];
    const oldFieldArray: IDisplayConfigurableFieldElement[] =
      fieldArrayChanges?.previousValue ?? [];

    if (oldFieldArray !== fieldArray) {
      this.defaultFields = [];
      this.nonDefaultFields = [];
    }

    if (
      fieldArray.length > 0 &&
      this.defaultFields.length === 0 &&
      this.nonDefaultFields.length === 0
    ) {
      this.fieldArray?.forEach((field) => {
        if (isDefault(field.configuration.checkboxes)) {
          this.defaultFields.push(field);
        } else {
          this.nonDefaultFields.push(field);
          if (
            field?.field_value?.value !== '' &&
            field?.field_value?.value !== null
          ) {
            this.usedNonDefaultFields.push({
              field_name: new FormControl(field.configuration.name),
              field,
            });
          }
        }
      });
    }
  }

  onAddField() {
    this.usedNonDefaultFields.push({
      field_name: new FormControl(),
      field: null,
    });
  }

  ngOnInit(): void {
    if (this.clearAllFields) {
      this.clearAllFields
        .pipe(takeUntil(this.onDestroyClearFields$))
        .subscribe(() => {
          this.usedNonDefaultFields = [];
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroyClearFields$.next();
    this.onDestroyClearFields$.complete();
  }

  selectedFieldChange(event: MatSelectChange, index: number) {
    if (index > -1 && index < this.usedNonDefaultFields.length) {
      if (this.usedNonDefaultFields[index].field) {
        this.usedNonDefaultFields[index].field.used = false;
        this.usedNonDefaultFields[index].field.field_value.setValue('');
        this.usedNonDefaultFields[index].field.field_value.markAsDirty();
        this.usedNonDefaultFields[index].field.field_value.markAsTouched();
      }

      const selectedField = this.nonDefaultFields.find(
        (field) => field.configuration.name === event.value
      );

      if (selectedField) {
        selectedField.used = true;
        this.usedNonDefaultFields[index].field = selectedField;
      }
    }
  }

  haveTheFieldUsedAlready(name: string) {
    return !!this.usedNonDefaultFields.find(
      (field) => !!field.field && field.field.configuration.name === name
    );
  }

  onClickRemove(index: number) {
    this.onDeletedFieldEmit.emit(
      this.usedNonDefaultFields.splice(index, 1)?.[0]?.field
    );
    this.onFieldDeleted.emit(true);
    this.onCheckValidity.emit(true);
  }

  conditionIsSatisfied(
    fieldType: CONFIGURABLE_FIELD_DATA_TYPES,
    fieldValue: any,
    comparingValue: any,
    comparator: COMPARISON_TYPES
  ): boolean {
    let conditionState = false;

    switch (fieldType) {
      case CONFIGURABLE_FIELD_DATA_TYPES.TEXT:
      case CONFIGURABLE_FIELD_DATA_TYPES.NUMBER:
      case CONFIGURABLE_FIELD_DATA_TYPES.BOOLEAN:
      case CONFIGURABLE_FIELD_DATA_TYPES.RADIO_BUTTON:
      case CONFIGURABLE_FIELD_DATA_TYPES.REFERENCES: {
        // since text, boolean and reference types cannot be compared with comparators other than equal and not equal,
        // those comparators cannot be set when configuring additional fields on boms side
        // hence it is assumed that the comparator value is either equal or not equal when fieldType is text, boolean or reference
        // note: in reference type, the comparingValue and fieldValue is the reference field id
        switch (comparator) {
          case COMPARISON_TYPES.EQUAL:
            conditionState = fieldValue === comparingValue;
            break;

          case COMPARISON_TYPES.LESS_THAN:
            conditionState = fieldValue < comparingValue;
            break;

          case COMPARISON_TYPES.LESS_THAN_OR_EQUAL:
            conditionState = fieldValue <= comparingValue;
            break;

          case COMPARISON_TYPES.GREATER_THAN:
            conditionState = fieldValue > comparingValue;
            break;

          case COMPARISON_TYPES.GREATER_THAN_OR_EQUAL:
            conditionState = fieldValue >= comparingValue;
            break;

          case COMPARISON_TYPES.NOT_EQUAL:
            conditionState = fieldValue !== comparingValue;
            break;
        }

        break;
      }

      case CONFIGURABLE_FIELD_DATA_TYPES.CHECKBOX: {
        const selectedCheckboxes = (
          fieldValue as Array<ICheckBoxRadioButtonOptionsConfig>
        ).filter((option) => option.checked);

        switch (comparator) {
          case COMPARISON_TYPES.EQUAL:
            conditionState =
              selectedCheckboxes.findIndex(
                (option) => option.value === comparingValue
              ) !== -1;
            break;

          case COMPARISON_TYPES.LESS_THAN:
            conditionState =
              selectedCheckboxes.findIndex(
                (option) => option.value < comparingValue
              ) !== -1;
            break;

          case COMPARISON_TYPES.LESS_THAN_OR_EQUAL:
            conditionState =
              selectedCheckboxes.findIndex(
                (option) => option.value <= comparingValue
              ) !== -1;
            break;

          case COMPARISON_TYPES.GREATER_THAN:
            conditionState =
              selectedCheckboxes.findIndex(
                (option) => option.value > comparingValue
              ) !== -1;
            break;

          case COMPARISON_TYPES.GREATER_THAN_OR_EQUAL:
            conditionState =
              selectedCheckboxes.findIndex(
                (option) => option.value >= comparingValue
              ) !== -1;
            break;

          case COMPARISON_TYPES.NOT_EQUAL:
            conditionState =
              selectedCheckboxes.findIndex(
                (option) => option.value !== comparingValue
              ) !== -1;
            break;
        }

        break;
      }

      case CONFIGURABLE_FIELD_DATA_TYPES.TIME:
      case CONFIGURABLE_FIELD_DATA_TYPES.DATE_TIME:
      case CONFIGURABLE_FIELD_DATA_TYPES.DATE: {
        switch (comparator) {
          case COMPARISON_TYPES.EQUAL:
            conditionState = moment(fieldValue).isSame(moment(comparingValue));
            break;

          case COMPARISON_TYPES.LESS_THAN:
            conditionState = moment(fieldValue).isBefore(
              moment(comparingValue)
            );
            break;

          case COMPARISON_TYPES.LESS_THAN_OR_EQUAL:
            conditionState = moment(fieldValue).isSameOrBefore(
              moment(comparingValue)
            );
            break;

          case COMPARISON_TYPES.GREATER_THAN:
            conditionState = moment(fieldValue).isAfter(moment(comparingValue));
            break;

          case COMPARISON_TYPES.GREATER_THAN_OR_EQUAL:
            conditionState = moment(fieldValue).isSameOrAfter(
              moment(comparingValue)
            );
            break;

          case COMPARISON_TYPES.NOT_EQUAL:
            conditionState = !moment(fieldValue).isSame(moment(comparingValue));
            break;
        }
        break;
      }
    }

    return conditionState;
  }

  fieldValueChange(index: number) {
    const modifiedFieldUniqueID = this.fieldArray[index].configuration.uniqueID;
    const modifiedField = this.fieldArray[index];

    this.fieldArray.forEach((field) => {
      // disable according to criteria
      if (field.configuration.criteria?.length > 0) {
        field.configuration.criteria.forEach((criterion) => {
          const hasMatchingDependent =
            criterion.dependent_field === modifiedFieldUniqueID;

          // is more than one criteria has the matching dependent,
          // then disableOnCriteria get the value according to the last criteria
          if (hasMatchingDependent && modifiedField.field_value.value) {
            field.advancedConfig.disableOnCriteria = this.conditionIsSatisfied(
              modifiedField.configuration.type,
              modifiedField.configuration.type ===
                CONFIGURABLE_FIELD_DATA_TYPES.CHECKBOX
                ? modifiedField.configuration.checkboxOptions
                : modifiedField.field_value.value,
              criterion.compare_value,
              criterion.comparator
            );
            if (field.advancedConfig.disableOnCriteria) {
              field.field_value.disable();
            } else {
              field.field_value.enable();
            }
          }
        });
      }

      if (
        field.inputType ===
          (CONFIGURABLE_FIELD_DATA_TYPES.TEXT ||
            CONFIGURABLE_FIELD_DATA_TYPES.RICH_TEXT ||
            CONFIGURABLE_FIELD_DATA_TYPES.NUMBER) &&
        !isString(field.field_value.value)
      ) {
        field.field_value.setValue(null);
        this.onSetNullValue.emit(true);
      }

      // filter according to criteria
      if (
        modifiedField.configuration.type ===
          CONFIGURABLE_FIELD_DATA_TYPES.REFERENCES &&
        field.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.REFERENCES &&
        field.configuration.uniqueID !== modifiedFieldUniqueID
      ) {
        field.configuration.criteria.forEach((criterion) => {
          // check if currently looping field has modified field as a dependent in criteria
          const hasMatchingDependent =
            criterion.dependent_field === modifiedFieldUniqueID;

          // filter the currently looping field's references which match the modified field's selected reference value
          if (hasMatchingDependent && modifiedField.field_value.value) {
            field.referencesFilteredFromCriteria = field.references.filter(
              (reference) =>
                reference.originalData?.reference?.find(
                  (ref) => ref?.field_id?.toString() === criterion.compare_value
                )?.value === modifiedField.field_value.value
            );
          }
        });
      }
    });
    setTimeout(() => {
      this.onFieldValueChange.emit();
    }, 100);
  }
}
