import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  isPrinting = false;

  constructor(private titleService: Title) {}

  openWindow(url: string) {
    window.open(url);
  }

  public onDataReady(titleName: string) {
    setTimeout(() => {
      this.titleService.setTitle(titleName);
      window.print();
      this.isPrinting = false;
      window.close();
    }, 1000);
  }
}
