export enum REFERENCE_FILTER_COMPARISON_TYPES {
  DIRECT = 'DIRECT',
  OTHER_FIELD = 'OTHER_FIELD',
  AGENT_ATTRIBUTE = 'AGENT_ATTRIBUTE',
}

export enum REFERENCE_FILTER_COMPARISON_OPERATORS {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
}
