import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GridstackModule } from 'gridstack/dist/angular';

import { MaterialModule } from '../../material.module';

import { DynamicContainerComponent } from './components/dynamic-container/dynamic-container.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { WidgetContainerSkeletonComponent } from './components/widget-container/wdiget-container-skeleton/widget-container-skeleton.component';
import { WidgetContainerComponent } from './components/widget-container/widget-container.component';
import { WidgetMenuComponent } from './components/widget-menu/widget-menu.component';
import { WidgetPanelComponent } from './components/widget-panel/widget-panel.component';
import { BarChartComponent } from './components/widgets/bar-chart.component/bar-chart.component';
import { BarChartSkeletonComponent } from './components/widgets/bar-chart.component/bar-chart-skeleton/bar-chart-skeleton.component';
import { CountWidgetComponent } from './components/widgets/count-widget/count-widget.component';
import { CountWidgetSkeletonComponent } from './components/widgets/count-widget/count-widget-skeleton/count-widget-skeleton.component';
import { DonutWidgetComponent } from './components/widgets/donut-widget/donut-widget.component';
import { DonutWidgetSkeletonComponent } from './components/widgets/donut-widget/donut-widget-skeleton/donut-widget-skeleton.component';
import { GroupBarChartComponent } from './components/widgets/group-bar-chart/group-bar-chart.component';
import { GroupBarChartSkeletonComponent } from './components/widgets/group-bar-chart/group-bar-chart-skeleton/group-bar-chart-skeleton.component';
import { LineChartComponent } from './components/widgets/line-chart/line-chart.component';
import { LineChartSkeletonComponent } from './components/widgets/line-chart/line-chart-skeleton/line-chart-skeleton.component';
import { NumberCardWidgetComponent } from './components/widgets/number-card-widget/number-card-widget.component';
import { NumberCardWidgetSkeletonComponent } from './components/widgets/number-card-widget/number-card-widget-skeleton/number-card-widget-skeleton.component';
import { ProgressBarWidgetComponent } from './components/widgets/progress-bar-widget/progress-bar-widget.component';
import { ProgressBarWidgetSkeletonComponent } from './components/widgets/progress-bar-widget/progress-bar-widget-skeleton/progress-bar-widget-skeleton.component';

const components = [
  WidgetPanelComponent,
  WidgetMenuComponent,
  DynamicContainerComponent,
  LineChartComponent,
  BarChartComponent,
  CountWidgetComponent,
  WidgetContainerComponent,
  MenuItemComponent,
  WidgetContainerSkeletonComponent,
  LineChartSkeletonComponent,
  CountWidgetSkeletonComponent,
  BarChartSkeletonComponent,
  DonutWidgetComponent,
  DonutWidgetSkeletonComponent,
  NumberCardWidgetComponent,
  NumberCardWidgetSkeletonComponent,
  GroupBarChartComponent,
  GroupBarChartSkeletonComponent,
  ProgressBarWidgetComponent,
  ProgressBarWidgetSkeletonComponent,
];
@NgModule({
  declarations: [...components],
  imports: [
    GridstackModule,
    CommonModule,
    MaterialModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    TranslateModule,
    FormsModule,
    NgxChartsModule,
    ReactiveFormsModule,
  ],
  exports: [...components],
  providers: [],
})
export class WidgetModule {}
