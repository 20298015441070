<div [class.disabled-overlay]="isDisabled">
  <div class="formula-builder-component row mat-4 mb-4">
    <div class="col-md-12">
      <div class="row formula-container">
        <div class="col-md-12">
          <div class="row card-title">
            <span
              class="field-name"
              (click)="panelOpened = true"
              [style]="
                (selectedElements.length < 2
                  ? 'font-size: smaller;'
                  : 'font-size: xx-small;') +
                (errorFormula ? 'color: red;' : 'color: gray')
              "
            >
              {{ fieldName }}
              <mat-spinner
                *ngIf="validatingFormula"
                [diameter]="selectedElements.length < 2 ? 20 : 14"
              >
              </mat-spinner>
            </span>
            <button
              *ngIf="panelOpened && !isDisabled"
              class="expand-collapse-button"
              mat-icon-button
              (click)="panelOpened = false"
            >
              ▲
            </button>
            <button
              *ngIf="!panelOpened && !isDisabled"
              class="expand-collapse-button"
              mat-icon-button
              (click)="panelOpened = true"
            >
              ▼
            </button>
          </div>
          <div class="row formula-box">
            <div
              class="formula-chip-list-container"
              (click)="panelOpened = true"
            >
              <mat-chip-list class="row formula-chip-list">
                <span *ngFor="let element of selectedElements; let i = index">
                  <mat-chip
                    *ngIf="!cursorElement(element)"
                    class="formula-chip"
                  >
                    <span *ngIf="operatorElement(element)">{{
                      operatorElement(element).operator.symbol
                    }}</span>
                    <span
                      *ngIf="constantElement(element)"
                      class="row"
                      (keydown)="onKeyDown($event)"
                    >
                      <input
                        class="col-md-10"
                        matInput
                        class="constant-input"
                        [placeholder]="
                          constantElement(element).constant.displayText
                        "
                        [formControl]="formControllers[i]"
                        [type]="
                          constantElement(element).constant.type.toLowerCase()
                        "
                        required
                      />
                    </span>
                    <span *ngIf="dataReferenceElement(element)">
                      {{
                        dataReferenceElement(element).dataReference.module
                          | titlecase
                      }}:
                      {{ dataReferenceElement(element).dataReference.field }}
                    </span>
                    <span *ngIf="referenceElement(element)">
                      {{
                        referenceElement(element).reference.category.name
                          | titlecase
                      }}:
                      {{ referenceElement(element).reference.field.name }}
                      <button
                        class="row"
                        mat-icon-button
                        (click)="defineFilters(i)"
                      >
                        <svg
                          *ngIf="
                            !referenceElement(element).reference.filters?.length
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          enable-background="new 0 0 24 24"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#aaaaaa"
                        >
                          <g>
                            <path d="M0,0h24 M24,24H0" fill="none" />
                            <path
                              d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"
                            />
                            <path d="M0,0h24v24H0V0z" fill="none" />
                          </g>
                        </svg>
                        <svg
                          *ngIf="
                            referenceElement(element).reference.filters?.length
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          enable-background="new 0 0 24 24"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#555555"
                        >
                          <g>
                            <path d="M0,0h24 M24,24H0" fill="none" />
                            <path
                              d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"
                            />
                            <path d="M0,0h24v24H0V0z" fill="none" />
                          </g>
                        </svg>
                      </button>
                    </span>
                    <span *ngIf="integrationElement(element)">
                      {{
                        integrationElement(element).integration.endpoint.name
                      }}
                      -
                      {{ integrationElement(element).integration.mapping.name }}
                      <button
                        class="row"
                        mat-icon-button
                        (click)="defineIntegration(i)"
                      >
                        <mat-icon
                          [class]="
                            isValidIntegrationMapping(element)
                              ? 'config'
                              : 'config invalid'
                          "
                        >
                          settings
                        </mat-icon>
                      </button>
                    </span>
                    <span *ngIf="fileDataElement(element)">
                      {{
                        getFileConfigName(
                          fileDataElement(element).file_data.file_config_id
                        )
                      }}
                      -
                      {{
                        getFieldsName(
                          fileDataElement(element).file_data.file_config_id,
                          fileDataElement(element).file_data.field_id
                        )
                      }}
                      <button
                        class="row"
                        mat-icon-button
                        (click)="
                          defineFileFilters(
                            i,
                            fileDataElement(element).file_data.file_config_id
                          )
                        "
                      >
                        <svg
                          *ngIf="
                            !fileDataElement(element).file_data.filters?.length
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          enable-background="new 0 0 24 24"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#aaaaaa"
                        >
                          <g>
                            <path d="M0,0h24 M24,24H0" fill="none" />
                            <path
                              d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"
                            />
                            <path d="M0,0h24v24H0V0z" fill="none" />
                          </g>
                        </svg>
                        <svg
                          *ngIf="
                            fileDataElement(element).file_data.filters?.length
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          enable-background="new 0 0 24 24"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#555555"
                        >
                          <g>
                            <path d="M0,0h24 M24,24H0" fill="none" />
                            <path
                              d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"
                            />
                            <path d="M0,0h24v24H0V0z" fill="none" />
                          </g>
                        </svg>
                      </button>
                    </span>
                    <span *ngIf="customVariableElement(element)">
                      {{
                        customVariableElement(element).customVariable
                          .variableName ||
                          customVariableElement(element).customVariable
                            ?.displayText
                      }}
                      <button
                        class="row"
                        mat-icon-button
                        *ngIf="
                          !customVariableElement(element).customVariable
                            .isConstant
                        "
                        (click)="defineCustomVariable(i)"
                      >
                        <mat-icon
                          [class]="
                            customVariableElement(element).customVariable
                              .variableName
                              ? 'config'
                              : 'config invalid'
                          "
                        >
                          settings
                        </mat-icon>
                      </button>
                    </span>
                    <span *ngIf="externalDataElement(element)">{{
                      externalDataElement(element).externalData.displayText
                    }}</span>
                    <span *ngIf="productElement(element)">
                      {{
                        getClassName(productElement(element).product.productId)
                      }}
                      -
                      {{
                        getProductName(
                          productElement(element).product.productId
                        )
                      }}
                      - GWP
                      <button
                        class="row"
                        mat-icon-button
                        (click)="defineProduct(i)"
                      >
                        <mat-icon class="config"> settings </mat-icon>
                      </button>
                    </span>
                    <span *ngIf="targetElement(element)">
                      {{
                        getConfigName(targetElement(element).config.configId)
                      }}
                      <button
                        class="row"
                        mat-icon-button
                        (click)="defineTarget(i)"
                      >
                        <mat-icon class="config"> settings </mat-icon>
                      </button>
                    </span>
                  </mat-chip>
                  <span
                    *ngIf="cursorElement(element) && panelOpened"
                    class="cursor"
                    >|</span
                  >
                </span>
              </mat-chip-list>
            </div>
            <div *ngIf="!isDisabled" class="backspace-button-container">
              <div class="row">
                <button
                  [disabled]="isMoveLeftDisabled()"
                  mat-icon-button
                  (click)="moveLeft()"
                  class="arrow-button"
                  matTooltip="Move the cursor left"
                >
                  <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <button
                  [disabled]="isMoveRightDisabled()"
                  mat-icon-button
                  (click)="moveRight()"
                  class="arrow-button"
                  matTooltip="Move the cursor right"
                >
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
              </div>
              <button
                [disabled]="isMoveLeftDisabled()"
                class="row backspace"
                mat-icon-button
                (click)="removeElement()"
              >
                <mat-icon>backspace</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="!isDisabled"
        #formulaBuilderContainer
        [style.display]="panelOpened ? 'block' : 'none'"
        class="row formula-builder-container"
      >
        <form class="col-md-12">
          <mat-form-field width="100%" appearance="fill" class="filter-chips">
            <mat-label>Filter Chips</mat-label>
            <input matInput [formControl]="myControl" />
          </mat-form-field>
        </form>
        <div class="col-md-12">
          <div class="header">
            <button
              *ngIf="expandAllMainSections"
              mat-icon-button
              type="button"
              matTooltip="{{ 'setup.common.collapse' | translate }}"
              (click)="expandAllMainSections = false"
            >
              <mat-icon> unfold_less </mat-icon>
            </button>
            <button
              *ngIf="!expandAllMainSections"
              mat-icon-button
              type="button"
              matTooltip="{{ 'setup.common.expand' | translate }}"
              (click)="expandAllMainSections = true"
            >
              <mat-icon> unfold_more </mat-icon>
            </button>
          </div>
          <mat-accordion multi>
            <!-- GENERAL chips -->
            <mat-expansion-panel
              *ngIf="generalConstants.length > 0"
              [expanded]="expandAllMainSections"
              class="category-panel mb-2"
            >
              <mat-expansion-panel-header> GENERAL </mat-expansion-panel-header>

              <mat-chip-list class="category-chip-list">
                <mat-chip
                  *ngFor="let constant of generalConstants"
                  (click)="selectFormulaElement(constant)"
                >
                  {{ constant.constant.displayText }}
                </mat-chip>
              </mat-chip-list>
            </mat-expansion-panel>

            <!-- BRACKETS chips -->
            <mat-expansion-panel
              *ngIf="bracketOperators.length > 0"
              [expanded]="expandAllMainSections"
              class="category-panel mb-2"
            >
              <mat-expansion-panel-header>
                BRACKETS
              </mat-expansion-panel-header>
              <mat-chip-list class="row category-chip-list">
                <mat-chip
                  *ngFor="let operator of bracketOperators"
                  (click)="selectFormulaElement(operator)"
                >
                  {{ operator.operator.displayText }}
                  {{ operator.operator.symbol }}
                </mat-chip>
              </mat-chip-list>
            </mat-expansion-panel>

            <!-- COMPARATIVE OPERATORS chips -->
            <mat-expansion-panel
              *ngIf="comparativeOperators.length > 0"
              [expanded]="expandAllMainSections"
              class="category-panel mb-2"
            >
              <mat-expansion-panel-header>
                COMPARATIVE OPERATORS
              </mat-expansion-panel-header>
              <mat-chip-list class="row category-chip-list">
                <mat-chip
                  *ngFor="let operator of comparativeOperators"
                  (click)="selectFormulaElement(operator)"
                >
                  {{ operator.operator.displayText }}
                  {{ operator.operator.symbol }}
                </mat-chip>
              </mat-chip-list>
            </mat-expansion-panel>

            <!-- BASIC OPERATORS chips -->
            <mat-expansion-panel
              *ngIf="basicOperators.length > 0"
              [expanded]="expandAllMainSections"
              class="category-panel mb-2"
            >
              <mat-expansion-panel-header>
                BASIC OPERATORS
              </mat-expansion-panel-header>
              <mat-chip-list class="row category-chip-list">
                <mat-chip
                  *ngFor="let operator of basicOperators"
                  (click)="selectFormulaElement(operator)"
                >
                  {{ operator.operator.displayText }}
                  {{ operator.operator.symbol }}
                </mat-chip>
              </mat-chip-list>
            </mat-expansion-panel>

            <!-- CONDITIONAL OPERATORS chips -->
            <mat-expansion-panel
              *ngIf="conditionalOperators.length > 0"
              [expanded]="expandAllMainSections"
              class="category-panel mb-2"
            >
              <mat-expansion-panel-header>
                CONDITIONAL OPERATORS
              </mat-expansion-panel-header>
              <mat-chip-list class="row category-chip-list">
                <mat-chip
                  *ngFor="let operator of conditionalOperators"
                  (click)="selectFormulaElement(operator)"
                >
                  {{ operator.operator.displayText }}
                </mat-chip>
              </mat-chip-list>
            </mat-expansion-panel>

            <!-- CUSTOM VARIABLES hips -->
            <mat-expansion-panel
              *ngIf="customVariables.length > 0"
              [expanded]="expandAllMainSections"
              class="category-panel mb-2"
            >
              <mat-expansion-panel-header>
                CUSTOM VARIABLES
              </mat-expansion-panel-header>
              <mat-chip-list class="row category-chip-list">
                <mat-chip
                  *ngFor="let variable of customVariables"
                  (click)="selectFormulaElement(variable)"
                >
                  {{ variable.customVariable.displayText }}
                </mat-chip>
              </mat-chip-list>
            </mat-expansion-panel>

            <!-- DATA REFERENCES chips -->
            <mat-expansion-panel
              *ngIf="dataReferences.length > 0"
              [expanded]="expandAllMainSections"
              class="category-panel mb-2"
            >
              <mat-expansion-panel-header>
                DATA REFERENCES
              </mat-expansion-panel-header>
              <mat-chip-list class="row category-chip-list">
                <mat-chip
                  *ngFor="let reference of dataReferences"
                  (click)="selectFormulaElement(reference)"
                >
                  {{ reference.dataReference.module | titlecase }}:
                  {{ reference.dataReference.field }}
                </mat-chip>
              </mat-chip-list>
            </mat-expansion-panel>

            <!-- EXTERNAL DATA REFERENCES chips -->
            <mat-expansion-panel
              *ngIf="externalData.length > 0"
              [expanded]="expandAllMainSections"
              class="category-panel mb-2"
            >
              <mat-expansion-panel-header>
                EXTERNAL DATA REFERENCES
              </mat-expansion-panel-header>
              <mat-chip-list class="row category-chip-list">
                <mat-chip
                  *ngFor="let element of externalData"
                  (click)="selectFormulaElement(element)"
                >
                  {{ element.externalData.displayText }}
                </mat-chip>
              </mat-chip-list>
            </mat-expansion-panel>

            <!-- REFERENCES chips -->
            <mat-expansion-panel
              *ngIf="sectionedReferences.length > 0"
              [expanded]="expandAllMainSections"
              class="category-panel mb-2"
            >
              <mat-expansion-panel-header>
                REFERENCES
              </mat-expansion-panel-header>

              <!-- Reference sections -->
              <mat-expansion-panel
                *ngFor="
                  let sectionedReference of sectionedReferences;
                  let i = index
                "
                [expanded]="expandAllReferenceSections"
                class="section-panel mb-2"
              >
                <mat-expansion-panel-header>
                  {{ sectionedReference.section }}
                </mat-expansion-panel-header>

                <mat-chip-list class="row category-chip-list">
                  <!-- Reference categories under section -->
                  <mat-expansion-panel
                    *ngFor="
                      let reference of sectionedReference.references || []
                    "
                    [expanded]="expandAllReferenceCategories"
                    class="section-panel mb-2"
                  >
                    <mat-expansion-panel-header>
                      {{ reference.name }}
                    </mat-expansion-panel-header>

                    <mat-chip-list class="row category-chip-list">
                      <mat-chip
                        *ngFor="let field of reference.fields"
                        (click)="selectReference(reference, field)"
                      >
                        {{ reference.name | titlecase }}: {{ field.name }}
                      </mat-chip>
                    </mat-chip-list>
                  </mat-expansion-panel>
                </mat-chip-list>
              </mat-expansion-panel>
            </mat-expansion-panel>

            <!-- INTEGRATIONS chips -->
            <mat-expansion-panel
              *ngIf="integrationEndpoints.length > 0"
              [expanded]="expandAllMainSections"
              class="category-panel mb-2"
            >
              <mat-expansion-panel-header>
                INTEGRATIONS
              </mat-expansion-panel-header>

              <!-- Endpoints -->
              <mat-expansion-panel
                *ngFor="let endpoint of integrationEndpoints; let i = index"
                [expanded]="
                  expandAllIntegrationEndpoints && endpoint.mappings.length > 0
                "
                class="section-panel mb-2"
                [disabled]="endpoint.mappings.length === 0"
              >
                <mat-expansion-panel-header>
                  {{ endpoint.name }}
                </mat-expansion-panel-header>

                <mat-chip-list class="row category-chip-list">
                  <!-- Mappings under endpoint -->
                  <mat-chip
                    *ngFor="let mapping of endpoint.mappings || []"
                    (click)="selectIntegrationMapping(endpoint, mapping)"
                    [disabled]="
                      !integrationMappingConfigurationsValid(endpoint, mapping)
                    "
                  >
                    {{ mapping.name | titlecase }}
                  </mat-chip>
                </mat-chip-list>
              </mat-expansion-panel>
            </mat-expansion-panel>
            <mat-expansion-panel
              *ngIf="fileConfigs.length > 0"
              class="category-panel mb-2"
            >
              <mat-expansion-panel-header> FILES </mat-expansion-panel-header>
              <!-- CONFIGURATIONS-->
              <mat-expansion-panel
                *ngFor="let config of fileConfigs; let i = index"
                class="section-panel mb-2"
              >
                <mat-expansion-panel-header>
                  {{ config.name }}
                </mat-expansion-panel-header>

                <mat-chip-list class="row category-chip-list">
                  <!-- Fields under configuration -->
                  <mat-chip
                    *ngFor="let field of config.fields || []"
                    (click)="selectFileData(config._id, field._id)"
                    [disabled]="
                      field.type !== CONFIGURABLE_FIELD_DATA_TYPES.NUMBER
                    "
                  >
                    {{ field.name }}
                  </mat-chip>
                </mat-chip-list>
              </mat-expansion-panel>
            </mat-expansion-panel>
            <!-- PRODUCT chips -->
            <mat-expansion-panel
              *ngIf="bomsData && bomsData?.system_enable"
              [expanded]="expandAllMainSections"
              class="category-panel mb-2"
            >
              <mat-expansion-panel-header>
                PRODUCT-WISE GROSS WRITTEN PREMIUM
              </mat-expansion-panel-header>

              <!-- Products -->

              <mat-chip-list class="row category-chip-list">
                <mat-chip
                  *ngFor="let product of bomsProducts || []"
                  (click)="selectProduct(product)"
                >
                  {{ getClassName(product._id.toString()) | titlecase }}
                  - {{ getProductName(product._id.toString()) | titlecase }} -
                  GWP
                </mat-chip>
              </mat-chip-list>
            </mat-expansion-panel>
            <mat-expansion-panel
              *ngIf="targetConfigs.length > 0"
              class="category-panel mb-2"
            >
              <mat-expansion-panel-header> TARGETS </mat-expansion-panel-header>
              <!-- Targets-->

              <mat-chip-list class="row category-chip-list">
                <mat-chip
                  *ngFor="let config of targetConfigs || []"
                  (click)="selectTarget(config)"
                >
                  {{ getConfigName(config._id.toString()) }}
                </mat-chip>
              </mat-chip-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
