export enum STATEMENT_FIELD {
  STATEMENT_CODE = 'CODE',
  STATEMENT = 'STATEMENT',
}

export enum CATEGORY_FIELD {
  CATEGORY_CODE = 'CODE',
  CATEGORY = 'CATEGORY',
  STATEMENT = 'STATEMENT CODE',
}

export enum TYPE_FIELD {
  TYPE_CODE = 'CODE',
  TYPE = 'TYPE',
  CATEGORY_CODE = 'CATEGORY CODE',
  ENABLE = 'ISENABLE',
}
