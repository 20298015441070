import { KeyValue, TitleCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';

import {
  IContactAddress,
  IContactEmail,
  IContactNumber,
  IName,
  ProposalInformation,
  TravelSchemeAgeRange,
} from '@shared/interfaces';
import {
  extractDefaultValue,
  getArrangedName,
  thousandSeparatorAdd,
} from '@shared/utils';

import { DateTimeFormatService } from '../../../../services/date-time-format.service';
import { ClientSetupResponseDTO } from '../../../setup/general/services/client-data.service';

@Component({
  selector: 'app-print-travel-proposal-component',
  templateUrl: './print-travel-proposal.component.html',
  styleUrls: ['./print-travel-proposal.component.scss'],
})
export class TravelProposalTemplateComponent {
  @Input() proposal_information: ProposalInformation;
  @Input() ref_number: string;
  @Input() client_information: ClientSetupResponseDTO;
  @Input() created_on: string;
  @Input() communication_modes: string;
  @Input() communication_languages: string;
  @Input() schedule_currency: string;
  @Input() isTravelGroup: boolean;
  @Input() sections: KeyValue<string, string>[];
  @Input() plans: KeyValue<string, string>[];
  @Input() covers: KeyValue<string, string>[];
  @Input() countries_list: KeyValue<string, string>[] = [];

  thousandSeparatorAdd = thousandSeparatorAdd;

  constructor(
    public dateTimeFormatService: DateTimeFormatService,
    public titleCasePipe: TitleCasePipe
  ) {}

  getFormattedDate(value: string | Date) {
    return this.dateTimeFormatService?.formatDateAsString(new Date(value));
  }

  getName(name: IName) {
    return getArrangedName(name);
  }

  getPhone(contact: IContactNumber[]) {
    return extractDefaultValue({
      type: 'PHONE',
      value: contact,
    });
  }

  getEmail(email: IContactEmail[]) {
    return extractDefaultValue({
      type: 'EMAIL',
      value: email,
    });
  }

  getAddress(address: IContactAddress[]) {
    return extractDefaultValue({
      type: 'ADDRESS',
      value: address,
    });
  }

  getStringForBoolean(value: boolean): 'YES' | 'NO' {
    return value ? 'YES' : 'NO';
  }

  getPlanName(key: string): string {
    return this.plans?.find((a) => a?.key === key)?.value;
  }

  getCoverName(key: string): string {
    return this.covers?.find((a) => a?.key === key)?.value;
  }

  getSectionName(key: string): string {
    return this.sections?.find((a) => a?.key === key)?.value;
  }

  getAgeRanges(ages: TravelSchemeAgeRange[]) {
    const show = [];
    ages?.forEach((a) => {
      show.push(a?.range + ' (' + a?.count + ') ');
    });

    return show?.join(', ');
  }

  getTravelAreaValueString(values: string[]) {
    const output = [];

    values?.forEach((v) => {
      output?.push(this.countries_list?.find((c) => c?.key === v)?.value);
    });

    return output?.join(', ');
  }
}
