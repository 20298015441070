import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Types } from 'mongoose';
import { BehaviorSubject, firstValueFrom, Observable, tap } from 'rxjs';

import { ENDPOINTS } from '@shared/constants';
import { ProductNameResponseDTO } from '@shared/dtos';
import { CommonResponseDTO, IProductExternalSystems } from '@shared/interfaces';
import { generateURL } from '@shared/utils';

@Injectable({ providedIn: 'root' })
export class ProductsService {
  private _allProducts = new BehaviorSubject<IProductExternalSystems[]>([]);

  constructor(private http: HttpClient) {}

  async getAllProductsFromBOMS(): Promise<IProductExternalSystems[]> {
    const fetchedProducts = this._allProducts.value;
    if (fetchedProducts.length) {
      return fetchedProducts;
    }

    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_GET_ALL_BOMS_PRODUCTS,
    });
    const response = await firstValueFrom(
      this.http.get<CommonResponseDTO<IProductExternalSystems[]>>(url).pipe(
        tap((tappedRes) => {
          this._allProducts.next(tappedRes.data);
        })
      )
    );

    return response.data;
  }

  getProductNamesListFromBOMS(
    productIds: (string | Types.ObjectId)[]
  ): Observable<CommonResponseDTO<ProductNameResponseDTO[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.EXTERNAL_REFERENCES_PRODUCTS_GET_ALL,
    });
    const body = productIds;

    return this.http.patch<CommonResponseDTO<ProductNameResponseDTO[]>>(
      url,
      body
    );
  }
}
