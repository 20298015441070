import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import {
  ASSIGNMENT_TYPES,
  DATE_FIELDS,
  RUNTIME_COLLECTABLE_FIELDS,
} from '@shared/constants';
import {
  IConfigurableFieldConfigResponse,
  IEndpoint,
  IFormulaIntegrationElementParam,
  IFormulaProductElementData,
  IKeyValuePair,
  IReferenceCategoryResponse,
} from '@shared/interfaces';
import { enumToKeyValuePairs } from '@shared/utils';

import { ReferenceCategoryService } from '../../../../../references/services/reference-category.service';

export interface DefineProductPopupData {
  product: IFormulaProductElementData;
  localReference: IReferenceCategoryResponse;
}

@Component({
  selector: 'app-define-integration-popup',
  templateUrl: 'define-product-popup.component.html',
  styleUrls: ['define-product-popup.component.scss'],
})
export class DialogContentDefineProductComponent implements OnInit, OnDestroy {
  endpoint: IEndpoint;
  foreignReferenceFields: IConfigurableFieldConfigResponse[];
  localReference: IReferenceCategoryResponse;
  categoryArray: IReferenceCategoryResponse[] = [];
  ASSIGNMENT_TYPES = ASSIGNMENT_TYPES;
  runtimeCollectableFields: IKeyValuePair<string>[] = [];

  fields: {
    parameterName: FormControl<string>;
    assignmentType: FormControl<ASSIGNMENT_TYPES>;
    assignmentValue: FormControl<string>;
    disable: FormControl<boolean>;
    predefinedValeHint: FormControl<string>;
  }[] = [];

  private onDestroy$ = new Subject<void>();

  constructor(
    titleCasePipe: TitleCasePipe,
    private dialogRef: MatDialogRef<DialogContentDefineProductComponent>,
    private referenceCategoryService: ReferenceCategoryService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    private data: DefineProductPopupData
  ) {
    this.localReference = data?.localReference;
    this.fields = [];

    Object.values(DATE_FIELDS).forEach((param) => {
      this.fields.push({
        parameterName: new FormControl(param, Validators.required),
        assignmentType: new FormControl(
          ASSIGNMENT_TYPES.LOCAL,
          Validators.required
        ),
        assignmentValue: new FormControl(null, Validators.required),
        predefinedValeHint: new FormControl(null),
        disable: new FormControl(false),
      });
    });

    this.runtimeCollectableFields = enumToKeyValuePairs(
      RUNTIME_COLLECTABLE_FIELDS,
      (key) => titleCasePipe.transform(key.replace(/_/g, ' '))
    );
  }

  ngOnInit(): void {
    this.referenceCategoryService.activeCategories
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((activeCategories) => {
        this.categoryArray = activeCategories;
      });
    if (this.data.product.params.length > 0) {
      this.data.product.params.forEach((param) => {
        const field = this.fields.find(
          (field) => field.parameterName.value === param.parameterName
        );
        if (field) {
          field.assignmentType.setValue(param.assignmentType);
          field.assignmentValue.setValue(param.assignmentValue);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async onChangeCompareWithField(index: number) {
    const field = this.fields?.[index];

    if (!field) return;

    field.assignmentValue.setValue(undefined);
    field.assignmentValue.markAsUntouched();
  }

  addForm() {
    this.fields.push({
      parameterName: new FormControl('', Validators.required),
      assignmentType: new FormControl(
        ASSIGNMENT_TYPES.LOCAL,
        Validators.required
      ),
      assignmentValue: new FormControl('', Validators.required),
      predefinedValeHint: new FormControl(''),
      disable: new FormControl(false),
    });
  }

  removeForm(index: number) {
    this.fields.splice(index, 1);
    if (this.fields.length === 0) {
      this.addForm();
    }
  }

  save() {
    const newFields: IFormulaIntegrationElementParam[] = this.fields
      .map(({ assignmentValue, assignmentType, parameterName }) => {
        if (
          assignmentValue.valid &&
          assignmentType.valid &&
          parameterName.valid
        ) {
          return {
            parameterName: parameterName.value,
            assignmentType: assignmentType.value,
            assignmentValue: assignmentValue.value,
          };
        }
        return null;
      })
      .filter(Boolean);

    this.dialogRef.close(newFields);
  }

  isAllValid() {
    const valid = this.fields.every(
      ({ assignmentValue, assignmentType, parameterName }) => {
        return (
          assignmentValue.valid && assignmentType.valid && parameterName.valid
        );
      }
    );
    return !valid;
  }
}
