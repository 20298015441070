export enum VEHICLE_USAGE {
  PERSONAL = 'PERSONAL',
  HIRE = 'HIRE',
  RENT = 'RENT',
}

export enum GOOD_TYPE {
  OWN_GOOD = 'OWN GOOD',
  HIRE = 'HIRE',
  CONTRACT = 'CONTRACT',
}

export enum POLICY_TYPE {
  THIRD_PARTY = 'THIRD PARTY',
  COMPREHENSIVE = 'COMPREHENSIVE',
}

export enum CUSTOMER_SEGMENTATION {
  NORMAL = 'NORMAL',
  VIP = 'VIP',
}

export enum MODE_OF_INTIMATION {
  CALL_CENTER = 'CALL CENTER',
  MOBILE_APP = 'MOBILE APP',
  HOSPITAL = 'HOSPITAL',
  GARAGE = 'GARAGE',
}

export enum POLICE_REPORTED_BY {
  INSURED = 'INSURED',
  DRIVER = 'DRIVER',
  OTHER = 'OTHER',
}

export enum MEASURING_UNITS {
  COUNT = 'COUNT',
  KILOGRAMS = 'KILOGRAMS',
  LITRES = 'LITRES',
}

export enum TYPE_OF_CLAIM {
  THIRD_PARTY = 'THIRD PARTY',
  OWN_DAMAGE = 'OWN DAMAGE',
  THEFT_OF_VEHICLE = 'THEFT OF VEHICLE',
  THEFT_OF_SPARE_PARTS = 'THEFT OF SPARE PARTS',
}

export enum CLAIMS_DOC_TYPES {
  //MOTOR
  DRIVING_LICENSE = 'DRIVING LICENSE',
  ACCIDENT_SKETCH = 'ACCIDENT SKETCH',
  OWN_DAMAGE_GARAGE_ESTIMATION = 'OWN DAMAGE-GARAGE ESTIMATION',
  POLICE_STATEMENT = 'POLICE STATEMENT',
  SIGNED_CLAIM_FORM = 'SIGNED CLAIM FORM',

  //MEDICAL
  FINAL_DIAGNOSIS_REPORT = 'FINAL_DIAGNOSIS_REPORT',
  HOSPITAL_BILL = 'HOSPITAL_BILL',
  //SIGNED_CLAIM_FORM = 'SIGNED_CLAIM_FORM',
  SIGNED_DOCTOR_FORM = 'SIGNED_DOCTOR_FORM',
}

export enum CLAIMS_REFERENCE_CATEGORIES {
  NATURE_OF_LOSS = 'NATURE OF LOSSES',
  SPARE_PARTS = 'SPARE PARTS',
  INSURANCE_COMPANIES = 'INSURANCE COMPANIES',
}

export enum WORKBENCH_MENUS {
  ANALYSIS = 'ANALYSIS',
  VIRTUAL_INSPECTION = 'VIRTUAL INSPECTION',
  ONSITE_INSPECTION = 'ONSITE INSPECTION',
  SALVAGES = 'SALVAGES',
  DOCUMENTS = 'DOCUMENTS',
  ACTIVITY_HISTORY = 'ACTIVITY HISTORY',
  ENGINEER_INSPECTION = 'ENGINEER INSPECTION',
  GARAGE_INSPECTION = 'GARAGE INSPECTION',
  CLAIM_FORM = 'CLAIM FORM',

  MEDICAL_ANALYSIS = 'MEDICAL_ANALYSIS',
  MEDICAL_ONSITE_INSPECTION = 'MEDICAL_ONSITE_INSPECTION INSPECTION',
  MEDICAL_DOCUMENTS = 'MEDICAL_DOCUMENTS',
  MEDICAL_ACTIVITY_HISTORY = 'MEDICAL_ACTIVITY_HISTORY',
  MEDICAL_CLAIM_FORM = 'MEDICAL_CLAIM_FORM',
}
//TODO:merge both above and below enums
export enum WORKBENCH_SECTION {
  SALVAGES = 'SALVAGES',
  ONSITE_SESSION = 'ONSITE_SESSION',
  VIRTUAL_SESSION = 'VIRTUAL_SESSION',
  ENGINEER_SESSION = 'ENGINEER_SESSION',
  GARAGE_SESSION = 'GARAGE_SESSION',
  PAYMENT_APPROVAL = 'PAYMENT_APPROVAL',
  SUPERVISOR_DECISION = 'SUPERVISOR_DECISION',

  MEDICAL_ONSITE_SESSION = 'MEDICAL_ONSITE_SESSION',
  MEDICAL_PAYMENT_APPROVAL = 'MEDICAL_PAYMENT_APPROVAL',
  MEDICAL_SUPERVISOR_DECISION = 'MEDICAL_SUPERVISOR_DECISION',
}

export enum REQUEST_DOCUMENT_TYPES {
  VALUATION = 'VALUATION REPORT',
  DISMANTLE = 'DISMANTLE REPORT',
  VEHICLE_INVENTORY = 'VEHICLE INVENTORY REPORT',
  OTHER = 'OTHER',
}

export enum CLAIM_HANDLER_DECISION_TYPES {
  HOLD = 'HOLD',
  REJECT = 'REJECT',
  REFER_TO_REINSURER = 'REFER TO INSURER',
  REFER_TO_NITF = 'REFER TO NITF',
  REFER_TO_INVESTIGATION = 'REFER TO INVESTIGATION',
}

export enum SUPERVISOR_DECISIONS {
  APPROVE = 'APPROVE',
  REDO = 'REDO',
  REFER = 'REFER',
  REJECT = 'REJECT',
}

export enum CLAIM_HANDLER_ASSIGNEES {
  VIRTUAL_ASSESSOR = 'VIRTUAL ASSESSOR',
  ONSITE_INSPECTOR = 'ONSITE INSPECTOR',
  ENGINEER = 'ENGINEER',
  GARAGE_INSPECTOR = 'GARAGE INSPECTOR',

  MEDICAL_ONSITE_INSPECTION = 'MEDICAL_ONSITE_INSPECTION INSPECTION',
}

export enum ASSESSMENT_STATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',

  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
}

export enum SALVAGES_STATUS {
  REQUESTED = 'REQUESTED',
}

export enum CLAIMS_STATUS {
  DRAFT = 'DRAFT',
  INTIMATED = 'INTIMATED',
  PENDING_ASSESSMENT = 'PENDING_ASSESSMENT',
  PENDING_ONSITE_INSPECTION = 'PENDING_ONSITE_INSPECTION',
  PENDING_ENGINEER_INSPECTION = 'PENDING_ENGINEER_INSPECTION',
  PENDING_GARAGE_INSPECTION = 'PENDING_GARAGE_INSPECTION',
  PENDING_VIRTUAL_INSPECTION = 'PENDING_VIRTUAL_INSPECTION',
  PENDING_REVIEW = 'PENDING_REVIEW',
  PENDING_AUTHORIZATION = 'PENDING_AUTHORIZATION',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  ON_HOLD = 'ON HOLD',
  SENT_TO_NITF = 'SENT_TO_NITF',
  SENT_TO_LITIGATION = 'SENT_TO_LITIGATION',
  APPROVED = 'APPROVED',
  PENDING_RE_INSPECTION = 'PENDING_RE_INSPECTION',
}

export enum CLAIMS_ROLES {
  CLAIM_HANDLER = 'CLAIM_HANDLER',
  CLAIM_DESK_ASSESSOR = 'CLAIM_DESK_ASSESSOR',
  CLAIM_ONSITE_ASSESSOR = 'CLAIM_ONSITE_ASSESSOR',
  CLAIM_ENGINEER = 'CLAIM_ENGINEER',
  CLAIM_GARAGE_INSPECTOR = 'CLAIM_GARAGE_INSPECTOR',
  CLAIM_SUPERVISOR = 'CLAIM_SUPERVISOR',
}

export enum ACCIDENT_TYPES {
  REAR_END_COLLISIONS = 'Rear-End Collisions',
  HEAD_ON_COLLISIONS = 'Head-On Collisions',
  SIMPLE_IMPACT_COLLISIONS = 'Side-Impact Collisions',
  ROLLOVER_ACCIDENTS = 'Rollover Accidents',
  SINGLE_VEHICLE_ACCIDENTS = 'Single-Vehicle Accidents',
  MULTIPLE_VEHICLE_PILEUPS = 'Multi-Vehicle Pileups',
  HIT_RUN_ACCIDENT = 'Hit-and-Run Accidents',
  SIDE_SWIPE_COLLISIONS = 'Side-Swipe Collisions',
  INTERSECTION_ACCIDENTS = 'Intersection Accidents',
  DISTRACTED_DRIVING = 'Distracted Driving Accidents',
  DRUNK_DRIVE = 'Drunk Driving Accidents',
  SPEEDING = 'Speeding Accidents',
  WEATHER_RELATED = 'Weather-Related Accidents',
  FATIGUE_RELATED = 'Fatigue-Related Accidents',
  AGGRESSIVE_DRIVING = 'Aggressive Driving Accidents',
  MECHANICAL_FAILURE = 'Mechanical Failure Accidents',
  ANIMAL_RELATED = 'Animal-Related Accidents',
  PEDESTRIAN = 'Pedestrian Accidents',
  CYCLIST = 'Cyclist Accidents',
  MOTORCYCLE = 'Motorcycle Accidents',
  OTHER = 'Other',
}

export enum LABOUR_CHARGE {
  REMOVE_REFITTING = 'Remove and Refitting',
  REPAIR = 'Repair',
  REPLACEMENT = 'Replacement',
  REPAINT = 'Repaint',
}

export enum CLAIMS_PAYMENT_OPTIONS {
  BANK_TRANSFER = 'Bank Transfer',
  MOBILE_TRANSFER = 'Mobile Transfer',
}
