import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DataListTableDataElement,
  DataListTableLinkDataElement,
  DataListTableSeparatorDataElement,
  DLT_DATA_TYPES,
} from '@el-data-list-table';
import { isArray, isObject } from 'lodash';
import { firstValueFrom } from 'rxjs';

import { CONFIGURABLE_FIELD_DATA_TYPES, ENDPOINTS } from '@shared/constants';
import {
  CommonResponseDTO,
  IConfigurableFieldDataTypes,
  IContactAddress,
  IContactEmail,
  IContactNumber,
  IName,
} from '@shared/interfaces';
import {
  extractDefaultValue,
  generateURL,
  thousandSeparatorAdd,
} from '@shared/utils';

import { DateTimeFormatService } from '../../../services/date-time-format.service';
import { CurrenciesService } from '../../setup/currencies/services/currencies.service';
import { IItemToBeConvertedToDLT } from '../types/data-manipulations';

import { AddressService } from './address.service';

type MultiFieldDataValidationResponse = CommonResponseDTO<boolean>;

@Injectable({ providedIn: 'root' })
export class MultiFieldService {
  constructor(
    private http: HttpClient,
    private currenciesService: CurrenciesService,
    private dateTimeFormatService: DateTimeFormatService,
    private addressService: AddressService
  ) {}

  private sanitizeDataArray<T>(arr?: T[] | string) {
    if (!arr || !isArray(arr)) return [] as T[];

    return arr;
  }

  private sanitizeNameObject(name?: IName | string) {
    if (!name || !isObject(name)) return {} as IName;

    return name;
  }

  async isValidName(data?: IName): Promise<boolean> {
    if (!data) return false;

    const url = generateURL({
      endpoint: ENDPOINTS.CONFIGURABLE_FIELDS_VALIDATIONS_NAME,
    });
    const validation = await firstValueFrom(
      this.http.post<MultiFieldDataValidationResponse>(url, data)
    );

    return validation?.data ?? false;
  }

  async isValidAddress(data?: IContactAddress): Promise<boolean> {
    if (!data) return false;

    const url = generateURL({
      endpoint: ENDPOINTS.CONFIGURABLE_FIELDS_VALIDATIONS_ADDRESS,
    });
    const validation = await firstValueFrom(
      this.http.post<MultiFieldDataValidationResponse>(url, data)
    );

    return validation?.data ?? false;
  }

  async isValidPhone(data?: IContactNumber): Promise<boolean> {
    if (!data) return false;

    const url = generateURL({
      endpoint: ENDPOINTS.CONFIGURABLE_FIELDS_VALIDATIONS_PHONE_NUMBER,
    });
    const validation = await firstValueFrom(
      this.http.post<MultiFieldDataValidationResponse>(url, data)
    );

    return validation?.data ?? false;
  }

  async isValidEmail(data?: IContactEmail): Promise<boolean> {
    if (!data) return false;

    const url = generateURL({
      endpoint: ENDPOINTS.CONFIGURABLE_FIELDS_VALIDATIONS_EMAIL,
    });
    const validation = await firstValueFrom(
      this.http.post<MultiFieldDataValidationResponse>(url, data)
    );

    return validation?.data ?? false;
  }

  async generateDataElementForDLT(
    fieldValue: IItemToBeConvertedToDLT,
    options?:
      | {
          type: DLT_DATA_TYPES;
          chipBackgroundColor?: string;
          chipColor?: string;
          isAchievements?: boolean;
          colorCompleted?: string;
          colorInProgress?: string;
        }
      | undefined
  ): Promise<DataListTableDataElement> {
    if (!fieldValue.name || !fieldValue.type) {
      return {
        key: fieldValue.name ?? '-',
        lazy: true,
        value: fieldValue.value?.toString() ?? '-',
      };
    }

    const key = fieldValue.name;
    let value = '-';
    let type: DLT_DATA_TYPES;
    let linkType: string;
    let href: string;
    let chipBackgroundColor: string;
    let chipColor: string;
    let progress: number;
    let colorCompleted: string;
    let colorInProgress: string;

    switch (fieldValue.type) {
      case CONFIGURABLE_FIELD_DATA_TYPES.CONTACT_NUMBER: {
        value = extractDefaultValue({
          type: 'PHONE',
          value: this.sanitizeDataArray<IContactNumber>(
            fieldValue.value as IContactNumber[]
          ),
        });
        if (value) {
          type = DLT_DATA_TYPES.LINK;
          linkType = 'external';
          href = `tel:${extractDefaultValue(
            {
              type: 'PHONE',
              value: this.sanitizeDataArray<IContactNumber>(
                fieldValue.value as IContactNumber[]
              ),
            },
            true
          )}`;
        }

        break;
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.EMAIL: {
        value = extractDefaultValue({
          type: 'EMAIL',
          value: this.sanitizeDataArray<IContactEmail>(
            fieldValue?.value as IContactEmail[]
          ),
        });
        if (value) {
          type = DLT_DATA_TYPES.LINK;
          linkType = 'external';
          href = `mailto:${extractDefaultValue(
            {
              type: 'EMAIL',
              value: this.sanitizeDataArray<IContactEmail>(
                fieldValue?.value as IContactEmail[]
              ),
            },
            true
          )}`;
        }

        break;
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.ADDRESS: {
        const address = this.sanitizeDataArray<IContactAddress>(
          fieldValue.value as IContactAddress[]
        );
        const promises = (address as IContactAddress[]).map(
          async (item, index) => {
            address[index] = await this.addressService.bindAddressData(item);
          }
        );

        await Promise.all(promises);
        value = extractDefaultValue({
          type: 'ADDRESS',
          value: address,
        });
        break;
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.NAME: {
        value = extractDefaultValue({
          type: 'NAME',
          value: this.sanitizeNameObject(fieldValue.value as IName),
        });
        linkType = 'internal';
        href = extractDefaultValue(
          {
            type: 'NAME',
            value: this.sanitizeNameObject(fieldValue.value as IName),
          },
          false
        );
        break;
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.CURRENCY: {
        if (
          options?.type === DLT_DATA_TYPES.PROGRESS_BAR &&
          options.isAchievements
        ) {
          value = this.getProgressBarValues(fieldValue.type, fieldValue.value);
        } else {
          value = thousandSeparatorAdd(fieldValue.value?.toString(), {
            currencyCode: this.currenciesService.baseCurrency.code,
            onlyTwoDecimals: true,
          });
        }
        break;
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.NUMBER: {
        if (
          options?.type === DLT_DATA_TYPES.PROGRESS_BAR &&
          options?.isAchievements
        ) {
          value = this.getProgressBarValues(fieldValue.type, fieldValue.value);
        } else {
          value = fieldValue?.value?.toString();
        }
        break;
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.DATE: {
        value = this.dateTimeFormatService.formatDateWithoutTime(
          fieldValue.value?.toString()
        );
        break;
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.DATE_TIME: {
        value = this.dateTimeFormatService.formatDateTime(
          fieldValue.value?.toString()
        );
        break;
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.TIME: {
        value = this.dateTimeFormatService.formatTime(
          fieldValue.value?.toString()
        );
        break;
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.SEPARATOR: {
        return {
          type: DLT_DATA_TYPES.SEPARATOR,
        } as DataListTableSeparatorDataElement;
      }
      default: {
        value = fieldValue.value?.toString();
        break;
      }
    }

    if (options?.type === DLT_DATA_TYPES.CHIP) {
      type = DLT_DATA_TYPES.CHIP;
      chipBackgroundColor = options?.chipBackgroundColor;
      chipColor = options?.chipColor;
    }
    if (options?.type === DLT_DATA_TYPES.PROGRESS_BAR) {
      type = DLT_DATA_TYPES.PROGRESS_BAR;
      progress = this.getProgress(fieldValue.value);
      colorCompleted = options?.colorCompleted;
      colorInProgress = options?.colorInProgress;
    }

    if (!value) value = '-';

    return {
      key,
      value,
      type,
      linkType,
      href,
      chipBackgroundColor,
      chipColor,
      progress,
      colorCompleted,
      colorInProgress,
    } as DataListTableLinkDataElement;
  }

  private getProgressBarValues(
    type: CONFIGURABLE_FIELD_DATA_TYPES,
    value: IConfigurableFieldDataTypes
  ) {
    const values = value.toString().includes(',')
      ? value.toString().split(',')
      : [value];
    if (type === CONFIGURABLE_FIELD_DATA_TYPES.CURRENCY) {
      const value_1 = thousandSeparatorAdd(values[0]?.toString(), {
        currencyCode: this.currenciesService.baseCurrency.code,
        onlyTwoDecimals: true,
      });
      const value_2 = thousandSeparatorAdd(values[1]?.toString(), {
        currencyCode: this.currenciesService.baseCurrency.code,
        onlyTwoDecimals: true,
      });
      return `${value_1} / ${value_2}`;
    }
    if (type === CONFIGURABLE_FIELD_DATA_TYPES.NUMBER) {
      return `${values[0]} / ${values[1]}`;
    }

    return '-';
  }

  private getProgress(value: IConfigurableFieldDataTypes) {
    const values = value.toString().includes(',')
      ? value.toString().split(',')
      : [value];
    return (parseFloat(`${values[0]}`) / parseFloat(`${values[1]}`)) * 100 ?? 0;
  }
}
