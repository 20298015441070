<div class="mb-5">
  <div class="col-sm-12 pl-0 pr-0">
    <!--If there are any results-->
    <el-data-list-table
      *ngIf="dataListArray?.length > 0 && !isListLoading; else noData"
      [data]="dataListArray"
      [totalCount]="totalCount"
      [pageSize]="pageSize"
      [showMoreButtonEnabled]="showMoreButtonEnabled"
      [downloadButtonEnabled]="downloadButtonEnabled"
      [showSelectAllCheckbox]="showSelectAllCheckbox"
      [getAllButtonEnabled]="getAllButtonEnabled"
      (onChangePageSize)="onChangePageSize.emit($event)"
      (onClickShowMore)="onClickShowMore.emit($event)"
      (onClickAction)="onClickAction.emit($event)"
      (onElementExpand)="onElementExpand.emit($event)"
      (onDataElementClickAction)="onDataElementClickAction.emit($event)"
      (onCheckBoxClickedAction)="onCheckBoxClickedAction.emit($event)"
      (onClickGetAll)="onClickGetAll.emit($event)"
    >
    </el-data-list-table>

    <!--If there are no results-->
    <ng-template #noData>
      <app-no-results-found
        *ngIf="!isListLoading"
        [disableDescription]="disableNoResultsDescription"
      ></app-no-results-found>
    </ng-template>

    <app-skeleton-data-list-table
      *ngIf="isListLoading"
      style="width: 100%"
    ></app-skeleton-data-list-table>
  </div>
</div>

<app-action-button
  [buttons]="filteredActionButtons"
  (clickAction)="onClickActionButton.emit($event)"
></app-action-button>
