export enum COVER_NOTE_GENERATE_TYPES {
  SYSTEM = 'SYSTEM',
  AGENT_REQUESTED = 'AGENT_REQUESTED',
}

export enum COVER_NOTE_STATUS {
  REQUESTED = 'REQUESTED',
  ISSUED = 'ISSUED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
}
