import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currency' })
export class CurrencySpacePipe implements PipeTransform {
  transform(
    num: any,
    currencyCode: string,
    display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = undefined,
    digits: string = undefined,
    local: string = undefined
  ): any {
    const value = new CurrencyPipe(navigator.language).transform(
      num,
      currencyCode,
      display,
      digits,
      local
    );
    const firstDigit = value.match(/\d/);
    const symbol = value.slice(0, firstDigit.index);
    const amount = value.slice(firstDigit.index);
    return symbol + ' ' + amount;
  }
}
