export enum FILE_STATUS {
  APPROVED = 'APPROVED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED = 'REJECTED',
}

export enum AUTHORIZER_APPROVAL_STATUS {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum FILE_FIELD_VALIDATION_ERROR_TYPES {
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE',
  TYPE_MISMATCH = 'TYPE_MISMATCH',
}
