export const AGE_MAX_SCORE = 15;
export const EDUCATION_MAX_SCORE = 10;
export const MARTIAL_STATUS_MAX_SCORE = 5;
export const SPORTS_ACHIEVEMENTS_MAX_SCORE = 10;
export const LEADERSHIP_ACHIEVEMENTS_MAX_SCORE = 8;
export const CLUB_ACHIEVEMENTS_MAX_SCORE = 10;
export const OTHER_ACHIEVEMENTS_MAX_SCORE = 8;
export const SALES_EXPERIENCE_MAX_SCORE = 5; // NON INDUSTRY
export const INDUSTRY_SALES_EXPERIENCE_MAX_SCORE = 10;
export const PASS_PERCENTAGE = 50;

export enum RECRUITMENT_REFERENCE_CATAGORIES {
  AL_SUBJECTS = 'A/L Subjects',
  CATEGORIES = 'Categories',
  CLUB_SOCIETY_POSITIONS = 'Club and Society Positions',
  CLUB_SOCIETY_POSITIONS_SCORE = 'Club and Society Positions Score',
  HIGHER_EDUCATIONAL_QUALIFICATIONS = 'Higher Education Qualifications',
  HIGHER_EDUCATIONAL_QUALIFICATIONS_SCORE = 'Higher Education Qualifications Score',
  INDUSTRY_EXPERIENCES = 'Industry Experiences',
  INDUSTRY_EXPERIENCES_SCORE = 'Industry Experiences Score',
  LEADERSHIP_POSITIONS = 'Leadership Positions',
  LEADERSHIP_POSITIONS_SCORE = 'Leadership Positions Score',
  MARITAL_STATUS = 'Martial Status',
  MARITAL_STATUS_SCORE = 'Martial Status Score',
  NATIONALITIES = 'Nationalities',
  OL_SUBJECTS = 'O/L Subjects',
  OL_SUBJECTS_CATEGORY = 'O/L Subjects Categories',
  OTHER_ACHIEVEMENTS = 'Other Achievements',
  OTHER_ACHIEVEMENTS_SCORE = 'Other Achievements Score',
  PROFESSIONS = 'Professions',
  RELATIONSHIPS = 'Relationships',
  SALES_EXPERIENCES = 'Sales Experiences',
  SALES_EXPERIENCES_SCORE = 'Sales Experiences Score',
  SPORT_TEAM_POSITIONS = 'Sports Team Positions',
  SPORT_TEAM_POSITIONS_SCORE = 'Sports Team Positions Score',
  GENDER = 'Gender',
  AL_STREAMS = 'A/L Streams',
  AL_SUBJECT_STREAM = 'A/L Subject Stream',
  AGE_SCORE = 'Age Score',
  AGE_MAX = 'Maximum Age',
  AGE_MIN = 'Minimum Age',
  SOFT_SKILLS_TYPE = 'Soft Skill Type',
  SOFT_SKILLS_CATEGORY = 'Soft Skill Category',
  SOFT_SKILLS_MAX_SCORE = 'Soft Skill Maximum Score',
  CUTOFF_MARK = 'Cutoff mark',
  DOCUMENT_LIST = 'Document List',
  CAREER_LEVELS = 'Career Levels',
  POSITIONS = 'Positions',
  DESIGNATIONS = 'Designations',
  BANKS = 'Banks',
  BANK_BRANCHES = 'Bank Branches',
  EXAM_LANGUAGES = 'Exam Languages',
  EXAM_INSTITUTES = 'Exam Institutes',
  BRANCHES_JANASHAKTHI = 'Branches Janashakthi',
  ZONE = 'Zones',
  REGION = 'Regions',
  BRANCHES = 'Branches',
}

export enum INTERVIEW_STATUS {
  CANCELLED = 'CANCELLED',
  EXITED = 'EXITED',
  NOTHING = '-',
  RESCHEDULED = 'RESCHEDULED',
}

export enum DOCUMENT_STATUSES {
  UPLOADED = 'UPLOADED',
  RESUBMIT = 'RESUBMIT',
}

export enum DOCUMENT_VERIFICATION_STATUSES {
  VERIFIED = 'Verified',
  RETURNED = 'Returned',
  PENDING = 'Pending',
}

export enum PROSPECT_CATEGORIES {
  AGENT = 'Agent',
  STAFF = 'Staff',
}

export enum OL_SUBJECT_CATEGORIES {
  RELIGION = 'Religion',
  SECOND_GROUP_SUBJECT = '2nd Group Subject',
  FIRST_GROUP_SUBJECT = '1st Group Subject',
  THIRD_GROUP_SUBJECT = '3rd Group Subject',
  CORE = 'Core',
  LANGUAGE_AND_LITERATURE = 'Language and Literature',
}

export enum DECISIONS {
  REFER_NEXT_INTERVIEW = 'REFER_NEXT_INTERVIEW',
  REFER_EXAM = 'REFER_EXAM',
  REFER_CODE_CREATION = 'REFER_CODE_CREATION',
  RETURN = 'RETURN',
  RETURN_DOCUMENT = 'RETURN_DOCUMENT',
  REJECTED = 'REJECTED',
  PROCEED_CODE_CREATION = 'PROCEED_CODE_CREATION',
}

export enum RECRUITMENT_EXAM_TYPES {
  MOCK = 'MOCK',
  SLII = 'SLII',
}

export enum RECRUITMENT_EXAM_STATUSES {
  PASS = 'Pass',
  PENDING = 'Pending',
  FAIL = 'Fail',
}

export enum RECRUITMENT_TRAINING_STATUSES {
  PENDING = 'Pending',
  COMPLETED = 'Completed',
}

export enum RECRUITMENT_EXAM_MODE_TYPES {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export enum CARDER_TYPE {
  PERMANENT = 'PERMANENT',
  NON_PERMANENT = 'NON_PERMANENT',
}

export enum DOCUMENTS_KINDS {
  CV = 'CV',
  RECRUITMENT_FORM = 'RECRUITMENT_FORM',
  BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE',
  APPLICATION = 'APPLICATION',
  RESULT = 'RESULT',
  OL = 'OL',
  AL = 'AL',
  PHOTO = 'PHOTO',
}
