import * as moment from 'moment';

export const formatDateTime = (
  dateTime: string | Date | moment.Moment,
  format = 'DD/MM/YYYY (hh:mm A)'
): string => {
  return moment(dateTime).format(format);
};

export const formatTime = (
  dateTime: string | Date | moment.Moment,
  format = 'hh:mm A'
): string => {
  return moment(dateTime).format(format);
};

export const formatDateWithoutTime = (
  date: string | Date,
  format = 'DD/MM/YYYY'
): string => {
  return moment(new Date(date)).format(format);
};

export const combineAndFormatDateTime = (
  date: Date | string,
  time: Date | string,
  dateFormat = 'DD/MM/YYYY',
  timeFormat = 'hh:mm A'
): string => {
  return `${moment(new Date(date)).format(dateFormat)} ${
    time ? '(' + moment(time).format(timeFormat) + ')' : ''
  }`;
};

export const formatDateAsString = (
  date: string | Date,
  format = 'MMMM DD, YYYY '
): string => {
  return moment(new Date(date)).format(format);
};

export const formatShortenedDate = (
  date: string | Date,
  format = 'DD-MMM'
): string => {
  return moment(new Date(date)).format(format);
};

export const formatMonthOnly = (
  date: string | Date,
  format = 'MMMM'
): string => {
  return moment(new Date(date)).format(format);
};
