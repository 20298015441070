import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, tap } from 'rxjs';

import { ENDPOINTS } from '@shared/constants';
import { IFileConfigRequest, IFileConfigResponse } from '@shared/interfaces';
import { generateURL } from '@shared/utils';

interface FileConfigResponse {
  data: IFileConfigResponse[];
  message: string;
  success: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FileConfigsService {
  private _configurations = new BehaviorSubject<IFileConfigResponse[]>([]);

  constructor(private http: HttpClient) {}

  public async getFileConfigs(): Promise<IFileConfigResponse[]> {
    const loadedConfigurations = this._configurations.value;
    if (loadedConfigurations?.length) return loadedConfigurations;

    const url = generateURL({
      endpoint: ENDPOINTS.INTEGRATION_FILE_CONFIGS_GET_ALL,
    });
    const { data } = await firstValueFrom(
      this.http.get<FileConfigResponse>(url)
    );

    this._configurations.next(data);
    return data;
  }

  public setFileConfigs(
    fields: IFileConfigRequest[]
  ): Observable<FileConfigResponse> {
    const url = generateURL({
      endpoint: ENDPOINTS.INTEGRATION_FILE_CONFIGS_UPDATE,
    });

    return this.http.post<FileConfigResponse>(url, { fields }).pipe(
      tap((response) => {
        this._configurations.next(response.data);
      })
    );
  }
}
