import * as moment from 'moment';

export const RunningBatchDetector = (
  start_day: number,
  end_day: number
): boolean => {
  const currentDate = moment();
  let startDate;
  let endDate;
  if (end_day < start_day) {
    startDate = moment().subtract(1, 'month').date(start_day);
    endDate = moment().date(end_day);
  } else {
    startDate = moment().date(start_day);
    endDate = moment().date(end_day);
  }
  return (
    currentDate.isSameOrAfter(startDate) && currentDate.isSameOrBefore(endDate)
  );
};
