export enum VEHICLE_USE_TYPES {
  PRIVATE = 'PRIVATE',
  HIRING = 'HIRING',
  RENT = 'RENT',
}

export enum VEHICLE_TYPES {
  CAR = 'CAR',
  VAN = 'VAN',
  LORRY = 'LORRY',
  BUS = 'BUS',
}

export enum FINANCIAL_INTEREST_TYPES {
  LEASE = 'LEASE',
  LOAN = 'LOAN',
  HIRE = 'HIRE',
  NONE = 'NONE',
}

export enum COMMUNICATION_MODES {
  EMAIL = 'EMAIL',
  LETTERS = 'LETTERS',
  FAX = 'FAX',
}

export enum COMMUNICATION_LANGUAGES {
  SINHALA = 'SINHALA',
  ENGLISH = 'ENGLISH',
  TAMIL = 'TAMIL',
}

export enum COVER_TYPES {
  COMPREHENSIVE = 'COMPREHENSIVE',
  THIRD_PARTY = 'THIRD PARTY FIRE THEFT',
  THIRD_ONLY = 'THIRD ONLY',
}

// For Dropdowns
export enum FUEL_TYPES {
  PETROL = 'PETROL',
  DIESEL = 'DIESEL',
  HYBRID = 'HYBRID',
  ELECTRIC = 'ELECTRIC',
}

// For Dropdowns
export enum NATURE_OF_LOSS {
  NORMAL_ACCIDENTS = 'Normal Accidents',
  RIOT_AND_STRIKE = 'Riot & Strike',
  HIT_AND_RUN = 'Hit & Run',
  FLOOD = 'Flood',
  THEFT = 'Theft',
}
