import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseWidget, NgCompInputs } from 'gridstack/dist/angular';
import { Observable } from 'rxjs';

import {
  ANALYZING_MODES,
  CONFIGURABLE_FIELD_DATA_TYPES,
  WIDGET_CATEGORIES,
} from '@shared/constants';
import {
  CommonResponseDTO,
  INumberCardWidgetResponse,
  IWidget,
  IWidgetEndpointResponse,
} from '@shared/interfaces';
import { thousandSeparatorAdd } from '@shared/utils';

import { PermissionFiltrationService } from '../../../../../services/permission-filtration.service';
import {
  LoggedUserService,
  UserInfoResponseDTO,
} from '../../../../auth/services';
import { CurrenciesService } from '../../../../setup/currencies/services/currencies.service';
import { WidgetApiService } from '../../../services/widgets.api.service';

import { SnackbarService } from './../../../../../services/snackbar.service';

@Component({
  selector: 'app-number-card-widget',
  templateUrl: 'number-card-widget.component.html',
  styleUrls: ['number-card-widget.component.scss'],
})
export class NumberCardWidgetComponent
  extends BaseWidget
  implements OnChanges, OnInit
{
  @Input() widget: IWidget;
  @Input() clickable: boolean;
  @Input() closable: boolean;

  loggedUserInfo: UserInfoResponseDTO;
  isLoading = true;
  hasFailed = true;
  hasPermissions = false;
  value = 0;
  subValue = 0;
  valueFormat: CONFIGURABLE_FIELD_DATA_TYPES;
  subValueFormat: CONFIGURABLE_FIELD_DATA_TYPES;
  barBackground: string;

  colors = ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'];

  constructor(
    private router: Router,
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private widgetApiService: WidgetApiService,
    private permissionFiltrationService: PermissionFiltrationService,
    private currenciesService: CurrenciesService,
    private loggedUserService: LoggedUserService
  ) {
    super();
  }

  public override serialize(): NgCompInputs | undefined {
    return this.widget ? { text: this.widget } : undefined;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.loggedUserService.dataStore.subscribe((data) => {
      this.loggedUserInfo = data;
      if (data) {
        this.refreshWidget();
      }
    });
    const randomIndex = Math.floor(Math.random() * this.colors.length);
    this.barBackground = this.colors[randomIndex];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget?.currentValue?.endpoint) {
      this.isLoading = true;
      this.refreshWidget();
    }
  }

  onClickWidget() {
    switch (this.widget.category_key) {
      case WIDGET_CATEGORIES.LOGS:
        this.router.navigate([`/app/logs`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.CONNECTED_APPS:
        this.router.navigate([`/app/apps`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.CONNECTIONS:
        this.router.navigate([`/app/connections`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.DRIVES:
        this.router.navigate([`app/storage/drives`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.IDENTITIES:
        this.router.navigate([`/app/identities`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.KNOWLEDGEBASE:
        this.router.navigate([`/app/knowledgebase`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.MESSAGES:
        this.router.navigate([`/app/messages`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.MODULES:
        this.router.navigate([`/app/modules`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.NOTICES:
        this.router.navigate([`/app/notices`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.PERMISSIONS:
        this.router.navigate([`/app/authorization/permissions`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.PRIVILEGES:
        this.router.navigate([`/app/authorization/privileges`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.ROLES:
        this.router.navigate([`/app/authorization/roles`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.ACTIVITIES:
        this.router.navigate([`/app/authorization/activities`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.AGENTS:
        this.router.navigate([`/app/agents`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.CLAIMS:
        this.router.navigate([`/app/claims`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.COVER_NOTES:
        this.router.navigate([`/app/cover-notes`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.ENDORSEMENTS:
        this.router.navigate([`/app/endorsements`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.INVOICES:
        this.router.navigate([`/app/invoices`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.LEADS:
        this.router.navigate([`/app/leads`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.POLICIES:
        this.router.navigate([`/app/policies`], {
          queryParams: { is_search: true },
        });
        break;
      case WIDGET_CATEGORIES.TARGETS:
        this.router.navigate([`/app/targets`], {
          queryParams: { isSearch: true },
        });
        break;
      case WIDGET_CATEGORIES.ACHIEVEMENTS:
        this.router.navigate([`/app/achievements`], {
          queryParams: { isSearch: true },
        });
        break;
      case WIDGET_CATEGORIES.PAYOUTS:
        this.router.navigate([`/app/payouts`], {
          queryParams: { isSearch: true },
        });
        break;

      default:
        this.router.navigate([`/app/launchpad`]);
        break;
    }
  }

  refreshWidget() {
    this.isLoading = true;
    this.hasPermissions = this.permissionFiltrationService.validatePermissions({
      analyzingMode: ANALYZING_MODES.EVERY,
      permissions: this.widget.permissions,
    });
    let subscription: Observable<CommonResponseDTO<IWidgetEndpointResponse>>;
    if (this.widget.config) {
      subscription = this.widgetApiService.getConfigTypeWidgetData(
        this.widget.endpoint,
        this.widget.config
      );
    } else {
      subscription = this.widgetApiService.getWidgetData(this.widget.endpoint);
    }
    subscription.subscribe({
      next: (res: CommonResponseDTO<INumberCardWidgetResponse>) => {
        this.value = res?.data?.value;
        this.subValue = res?.data?.subValue;
        this.valueFormat = res?.data?.valueFormat;
        this.subValueFormat = res.data?.subValueFormat;
        this.isLoading = false;
        this.hasFailed = false;
      },
      error: () => {
        this.isLoading = false;
        this.hasFailed = true;
        this.snackbar.error(
          this.translate.instant('widgets.count-widget.failed-to-load-widget')
        );
      },
    });
  }

  formatValue(data: number, format: CONFIGURABLE_FIELD_DATA_TYPES): string {
    if (format === CONFIGURABLE_FIELD_DATA_TYPES.CURRENCY) {
      return thousandSeparatorAdd(data, {
        currencyCode: this.currenciesService.baseCurrency.code,
        onlyTwoDecimals: true,
      });
    }
    return data?.toString() ?? '-';
  }
}
