<div *ngIf="loading" class="loading-overlay">
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="contents">
  <div class="row">
    <div class="col-2 d-flex align-items-center">
      <div class="file-logo">
        <img
          [src]="client_information?.logo_path_light"
          width="auto"
          height="auto"
          [alt]="client_information?.website"
        />
      </div>
    </div>
    <div class="col-10">
      <div class="row d-flex justify-content-start">
        <p class="company-name">
          {{ client_information?.name?.toUpperCase() }}
        </p>
      </div>
      <div class="row">
        <mat-icon>mail</mat-icon>
        <p class="mx-2">
          {{ client_information?.address }}
        </p>
        <mat-icon class="ml-1">phone</mat-icon>
        <p class="mx-2">
          {{
            client_information?.phone_number?.dialCode +
              client_information?.phone_number?.number
          }}
        </p>
        <mat-icon>alternate_email</mat-icon>
        <p class="mx-2">
          {{ client_information?.email }}
        </p>
      </div>
    </div>
  </div>
  <div class="address">
    <p>{{ 'payouts.configuration.bank-order.manager' | translate }},</p>
    <p *ngFor="let line of address; let last = last">
      {{ line }}<span *ngIf="!last">,</span>
    </p>
  </div>
  <div class="note">
    <p>{{ printed_date }}</p>
    <p>
      {{ 'payouts.configuration.bank-order.dear' | translate }}
      {{ bankOrderFields.salutation }},
    </p>
  </div>

  <div class="title-content">
    <h5 class="title">
      {{ title }}
    </h5>
  </div>

  <div class="note mt-4">
    <p>{{ bankOrderFields.description }}</p>
  </div>
  <div class="row end">
    <div class="col">
      <p>{{ 'payouts.configuration.bank-order.debit-amount' | translate }} :</p>
      <p style="font-weight: 600">{{ grandTotal }}</p>
    </div>
    <div class="col">
      <p>{{ 'payouts.processing.account-number' | translate }} :</p>
      <p style="font-weight: 600">{{ bankOrderFields.account_number }}</p>
    </div>
  </div>
  <div class="table-container">
    <table class="bank-order-table">
      <tbody>
        <tr [style.background-color]="headingColor">
          <th class="table_header p-2">
            {{ 'payouts.processing.date' | translate }}
          </th>
          <th class="table_header p-2">
            {{ 'payouts.agent-code' | translate }}
          </th>
          <th class="table_header p-2">
            {{ 'payouts.processing.name' | translate }}
          </th>
          <th class="table_header p-2">
            {{ 'payouts.processing.account-number' | translate }}
          </th>
          <th class="table_header p-2">
            {{ 'payouts.net-payable' | translate }}
          </th>
        </tr>
        <tr *ngFor="let payout of paymentData">
          <td class="table_result p-2">
            {{ printed_date }}
          </td>
          <td class="table_result p-2">
            {{ payout['payouts.agent-code' | translate] }}
          </td>
          <td class="table_result p-2">
            {{ payout['payouts.processing.name' | translate] }}
          </td>
          <td class="table_result p-2">
            {{ payout['payouts.processing.account-number' | translate] }}
          </td>
          <td class="table_result last-column p-2">
            {{ payout['payouts.net-payable' | translate] }}
          </td>
        </tr>
        <tr class="last-row">
          <td colspan="4" class="grand-total p-2">
            {{ 'payouts.configuration.bank-order.grand-total' | translate }}
          </td>
          <td class="last-column p-2">{{ grandTotal }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="end-note">
    <div class="note mt-4">
      <p>{{ 'payouts.configuration.bank-order.thank-you' | translate }}</p>
    </div>
    <div class="end">
      <p>
        {{ 'payouts.configuration.bank-order.yours-faithfully' | translate }}
      </p>
      <p>{{ client_information?.name }}</p>
    </div>
  </div>
  <div class="row justify-content-start end-info">
    <div class="col">
      <p>
        {{ 'payouts.configuration.bank-order.generated-date' | translate }}
      </p>
      <p>{{ generatedDateTime }}</p>
    </div>
    <div class="col">
      <p>
        {{ 'payouts.configuration.bank-order.generated-by' | translate }}
      </p>
      <p>{{ generatedBy.toUpperCase() }}</p>
    </div>
  </div>
</div>

<div class="footer">
  <span>{{ 'payouts.payslip.computer-generated-message' | translate }}</span>
</div>
