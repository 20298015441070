import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-processing-data',
  templateUrl: './processing-data.component.html',
  styleUrls: ['./processing-data.component.scss'],
})
export class ProcessingDataComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() disableTitle = false;
  @Input() disableDescription = false;
}
