import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { groupBy, indexOf } from 'lodash';

import { ANALYZING_MODES, WIDGET_CATEGORIES } from '@shared/constants';
import { IStorageQuota, IWidget } from '@shared/interfaces';

import { StorageSetupService } from '../../../setup/storage/services/storage-setup.service';

import { PermissionFiltrationService } from './../../../../services/permission-filtration.service';

@Component({
  selector: 'app-widget-menu',
  templateUrl: './widget-menu.component.html',
  styleUrls: ['./widget-menu.component.scss'],
})
export class WidgetMenuComponent implements OnChanges, OnInit {
  @Input() widgets: IWidget[];
  @Input() isDisabled: boolean;
  @Output() onAdd: EventEmitter<IWidget> = new EventEmitter();
  @Output() onRemove: EventEmitter<IWidget> = new EventEmitter();

  categories: WIDGET_CATEGORIES[] = [];
  groupedCategories: { [key: string]: IWidget[] };

  isEmptyModules = false;
  filteredModuleCards = [];
  searchValue = '';
  expandAll = false;
  storageConfig: IStorageQuota;

  constructor(
    private permissionFiltrationService: PermissionFiltrationService,
    private storageConfigService: StorageSetupService
  ) {}

  ngOnInit() {
    this.storageConfigService.dataStore.subscribe({
      next: (data) => {
        this.storageConfig = data?.storage_quota;
      },
    });
    this.sortWidgetsByTitle(this.widgets);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgets?.currentValue) {
      this.groupWidgetCategories(changes.widgets?.currentValue);
    }
  }

  filter(str: string) {
    return (
      str?.toLowerCase().search(this.searchValue.trim().toLowerCase()) > -1
    );
  }

  checkFilterEmpty() {
    this.isEmptyModules =
      this.filteredModuleCards.find((value) => this.filter(value.searchKey)) ==
      undefined;
  }

  groupWidgetCategories(widgets: IWidget[]): void {
    const filteredWidgets = widgets.filter((widget) =>
      this.permissionFiltrationService.validatePermissions({
        analyzingMode: ANALYZING_MODES.EVERY,
        permissions: widget.permissions,
      })
    );

    this.filteredModuleCards = filteredWidgets;

    this.groupedCategories = groupBy<IWidget>(
      filteredWidgets,
      // widgets,
      (category) => category.category_key
    );
    this.categories = Object.keys(
      this.groupedCategories
    ) as WIDGET_CATEGORIES[];

    if (!this.storageConfig?.is_enabled) {
      this.categories.splice(
        indexOf(this.categories, WIDGET_CATEGORIES.STORAGE),
        1
      );
    }
  }

  //to sort achievements related widgets by title
  sortWidgetsByTitle(widgets: IWidget[]) {
    widgets
      ?.sort((a, b) => {
        const aName = a.title.toLowerCase();
        const bName = b.title.toLowerCase();

        const aIsTeam = aName.endsWith('team');
        const bIsTeam = bName.endsWith('team');
        const aIsLastMonths = aName.startsWith('last months');
        const bIsLastMonths = bName.startsWith('last months');

        if (aIsTeam && !bIsTeam) {
          return -1;
        }
        if (!aIsTeam && bIsTeam) {
          return 1;
        }
        if (aIsLastMonths && !bIsLastMonths) {
          return -1;
        }
        if (!aIsLastMonths && bIsLastMonths) {
          return 1;
        }
        return aName.localeCompare(bName);
      })
      .reverse();
  }

  onAddWidget(widget: IWidget) {
    this.onAdd.emit(widget);
  }

  onRemoveWidget(widget: IWidget) {
    this.onRemove.emit(widget);
  }
}
