<mat-card
  class="widget__container"
  *ngIf="hasPermissions && clickable"
  (dblclick)="onClickWidget()"
  matRipple
>
  <mat-card-content *ngIf="isLoading || !loggedUserInfo">
    <app-progress-bar-widget-skeleton></app-progress-bar-widget-skeleton>
  </mat-card-content>

  <mat-card-content
    *ngIf="!isLoading && hasFailed && !loggedUserInfo"
    class="widget__content"
  >
    <div class="load-error">
      <div class="error-content px-3">
        <mat-icon class="error-icon">error_outline</mat-icon>
        <div class="error-message">
          <div class="pl-1 error-text">
            <span class="failed-message-bold">{{
              'widgets.error.failed-to-load' | translate
            }}</span>
            <span class="failed-message">{{
              'widgets.error.refresh' | translate
            }}</span>
          </div>
        </div>
        <button mat-icon-button (click)="refreshWidget()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>

  <mat-card-content *ngIf="!hasFailed && !isLoading && loggedUserInfo">
    <div class="widget__content row px-3">
      <div class="col-12">
        <div class="row" style="justify-content: space-between">
          <div class="col-10">
            <div class="row name">{{ name }}</div>
            <div class="row values">
              {{ formatValue(targetValue, achievedValue, valueFormat) }}
            </div>
          </div>
          <div class="col-2 percentage">
            <div class="row justify-content-end">{{ percentage }}%</div>
          </div>
        </div>
        <div class="row" style="align-items: center">
          <div class="progress-linear col-10 w-75">
            <div
              class="bar-active"
              [ngStyle]="{
                'width.%': percentage,
                'background-color': progressBarColor
              }"
            ></div>
          </div>
          <div class="col-2">
            <div class="row justify-content-end mb-2">
              <button mat-icon-button (click)="refreshWidget()">
                <mat-icon>refresh</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="widget__container" *ngIf="hasPermissions && !clickable">
  <mat-card-content *ngIf="isLoading">
    <app-progress-bar-widget-skeleton></app-progress-bar-widget-skeleton>
  </mat-card-content>

  <mat-card-content *ngIf="!isLoading && hasFailed">
    <div class="widget__content">
      <div class="load-error">
        <div class="error-content px-3">
          <mat-icon class="error-icon">error_outline</mat-icon>
          <div class="error-message">
            <div class="pl-1 error-text">
              <span class="failed-message-bold">{{
                'widgets.error.failed-to-load' | translate
              }}</span>
              <span class="failed-message">{{
                'widgets.error.refresh' | translate
              }}</span>
            </div>
          </div>
          <button mat-icon-button (click)="refreshWidget()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>

  <mat-card-content *ngIf="!hasFailed && !isLoading">
    <div class="widget__content row px-3">
      <div class="col-12">
        <div class="row" style="justify-content: space-between">
          <div class="col-10">
            <div class="row name">{{ name }}</div>
            <div class="row values">
              {{ formatValue(targetValue, achievedValue, valueFormat) }}
            </div>
          </div>
          <div class="col-2 percentage">
            <div class="row justify-content-end">{{ percentage }}%</div>
          </div>
        </div>
        <div class="row" style="align-items: center">
          <div class="progress-linear col-10 w-75">
            <div
              class="bar-active"
              [ngStyle]="{
                'width.%': percentage,
                'background-color': progressBarColor
              }"
            ></div>
          </div>
          <div class="col-2">
            <div class="row justify-content-end mb-2">
              <button mat-icon-button (click)="refreshWidget()">
                <mat-icon>refresh</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
