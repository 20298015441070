import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Color } from '@angular-material-components/color-picker';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import {
  IAgentInformation,
  IPayoutConfigBankOrderFieldsResponse,
  IPayoutTransaction,
} from '@shared/interfaces';
import {
  formatDateTime,
  formatDateWithoutTime,
  getArrangedAddress,
  hexToRgb,
} from '@shared/utils';

import { DateTimeFormatService } from '../../../../services/date-time-format.service';
import { PrintService } from '../../../../services/print.service';
import { LoggedUserService } from '../../../auth/services';
import { AddressService } from '../../../configurable-fields/services/address.service';
import { CurrenciesService } from '../../../setup/currencies/services/currencies.service';
import { ClientSetupResponseDTO } from '../../../setup/general/services/client-data.service';
import { SystemDataService } from '../../../setup/general/services/system-data.service';
import { BatchInfo } from '../payout-processing/payout-processing.component';

@Component({
  selector: 'app-bank-order-template',
  templateUrl: './bank-order-template.component.html',
  styleUrls: ['./bank-order-template.component.scss'],
})
export class BankOrderTemplateComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();
  client_information: ClientSetupResponseDTO;

  agent_information: IAgentInformation;
  paymentData: any[];
  bankOrderFields: IPayoutConfigBankOrderFieldsResponse;
  printed_date: string;
  headingColor: string;
  currencyCode: string;
  address: string[];
  title: string;
  year: number;
  month: string;
  batches: string;
  grandTotal: string;
  generatedBy: string;
  generatedDateTime: string;

  loading = true;

  constructor(
    private printService: PrintService,
    private localStorage: LocalStorage,
    private systemDataService: SystemDataService,
    private datePipe: DatePipe,
    private currenciesService: CurrenciesService,
    private dateTimeService: DateTimeFormatService,
    private addressService: AddressService,
    private translate: TranslateService,
    private loggedUserService: LoggedUserService
  ) {}

  ngOnInit(): void {
    this.currencyCode = this.currenciesService.baseCurrency.code;
    this.initializeData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  initializeData() {
    this.localStorage.getItem('bank-order').subscribe((data: any) => {
      this.client_information = data.company;
      this.year = data.year;
      this.month = data.month;
      const batchData: BatchInfo[] = data.batches;
      this.batches = batchData
        .map((data) => {
          return `${data.batch} (${formatDateWithoutTime(
            data.start_date
          )} - ${formatDateWithoutTime(data.end_date)})`;
        })
        .join(',');
      this.title = `${this.translate.instant(
        'payouts.configuration.bank-order.pdf-title'
      )} - ${data.month.toUpperCase()} - ${
        data.year
      } - ${this.batches.toUpperCase()} `;
      this.grandTotal = data.totalNetPayable;
      this.paymentData = data.paymentData;
      this.bankOrderFields = data.bankOrderFields;
      this.fetchAddressData();

      this.printed_date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
      this.generatedDateTime = formatDateTime(new Date());
      this.localStorage.removeItem('bank-order');
    });
    this.systemDataService.dataStore
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        if (data && typeof data.color_palette !== 'string') {
          const bgColor = hexToRgb(data.color_palette.primaryColor);
          this.headingColor = new Color(
            bgColor.r,
            bgColor.g,
            bgColor.b
          ).toRgba();
        }
      });
    this.loggedUserService.dataStore
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.generatedBy = `${data.first_name} ${
          data.last_name ? data.last_name : ''
        }`;
      });

    this.print();
  }

  private print() {
    setTimeout(() => {
      this.loading = false;
      setTimeout(() => {
        if (this.client_information && this.paymentData) {
          this.printService.onDataReady(this.title);
        } else {
          window.close();
        }
      }, 50);
    }, 2000);
  }

  getBatchAndDateRange(payout: IPayoutTransaction) {
    return `${payout.batch_no} (${this.dateTimeService.formatDateWithoutTime(
      payout.coverage_start
    )}-${this.dateTimeService.formatDateWithoutTime(payout.coverage_end)})`;
  }

  async fetchAddressData(): Promise<void> {
    const address = await this.addressService.bindAddressData(
      this.bankOrderFields.address_to.find((add) => add.is_primary)
    );
    const addressString = getArrangedAddress(address);
    this.address = addressString.split(',');
  }
}
