import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {} from '@nestjs/common';

@Component({
  selector: 'app-ExitRecruitment-popup',
  templateUrl: './exit-recruitment.component.html',
  styleUrls: ['./exit-recruitment.component.scss'],
})
export class ExitRecruitmentPopupComponent {
  title: string;
  form: FormGroup = new FormGroup({
    stop_remark: new FormControl('', Validators.required),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ExitRecruitmentPopupComponent,
    private matDialog: MatDialogRef<ExitRecruitmentPopupComponent>
  ) {
    this.title = data.title;
  }

  onClickYes() {
    if (this.form.valid) {
      this.matDialog.close(this.form.controls.stop_remark.value);
    }
  }
}
