import {
  IConfigurableFieldConfigResponse,
  IConfigurableFieldDataTypes,
  IModuleResponse,
  IReferenceResponse,
} from '@shared/interfaces';

export const extractReferenceValuesByModuleConfig = (
  moduleConfig: IModuleResponse,
  fieldName: string,
  reference: IReferenceResponse
): IConfigurableFieldDataTypes | undefined => {
  const fieldConfig = (moduleConfig?.fields || []).find(
    (field) => field.name === fieldName
  );

  if (!fieldConfig) return undefined;

  return extractReferenceValuesByFieldConfig(fieldConfig, reference);
};

export const extractReferenceValuesByFieldConfig = <
  T extends IConfigurableFieldDataTypes
>(
  fieldConfig: IConfigurableFieldConfigResponse,
  reference: IReferenceResponse
): T | undefined => {
  if (!fieldConfig?.reference_type_field_config) return undefined;

  const refValue = (reference?.reference || []).find(
    (refValue) =>
      refValue.field_id === fieldConfig.reference_type_field_config?.field_id
  );

  return refValue?.value as T;
};
