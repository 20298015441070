import {
  CONFIGURABLE_FIELD_DROPDOWN_OPTION_GENERATORS,
  LEADS_DEFAULT_ADDITIONAL_FIELDS_CHANNEL_TYPE_DROPDOWN_OPTIONS,
  LEADS_DEFAULT_ADDITIONAL_FIELDS_LEAD_TYPE_DROPDOWN_OPTIONS,
} from '@shared/constants';

export const generateAdditionalFieldDropdownOptions = (
  method: CONFIGURABLE_FIELD_DROPDOWN_OPTION_GENERATORS
) => {
  switch (method) {
    case CONFIGURABLE_FIELD_DROPDOWN_OPTION_GENERATORS.LEADS_CHANNEL_TYPE:
      return [
        {
          value:
            LEADS_DEFAULT_ADDITIONAL_FIELDS_CHANNEL_TYPE_DROPDOWN_OPTIONS.AGENTS,
        },
        {
          value:
            LEADS_DEFAULT_ADDITIONAL_FIELDS_CHANNEL_TYPE_DROPDOWN_OPTIONS.PUBLIC_API,
        },
      ];

    case CONFIGURABLE_FIELD_DROPDOWN_OPTION_GENERATORS.LEADS_LEAD_TYPE:
      return [
        {
          value:
            LEADS_DEFAULT_ADDITIONAL_FIELDS_LEAD_TYPE_DROPDOWN_OPTIONS.CUSTOMER,
        },
        {
          value:
            LEADS_DEFAULT_ADDITIONAL_FIELDS_LEAD_TYPE_DROPDOWN_OPTIONS.RECRUITMENT,
        },
      ];
    case CONFIGURABLE_FIELD_DROPDOWN_OPTION_GENERATORS.LEADS_BRANCH:
      return [];
  }
};
