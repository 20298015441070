import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ELAutocompleteElement } from '@el-autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { Types } from 'mongoose';
import {
  BehaviorSubject,
  firstValueFrom,
  map,
  Observable,
  Subject,
  tap,
} from 'rxjs';

import {
  ENDPOINTS,
  FIELD_VALIDATION_ERROR_TYPES,
  PREDEFINED_REFERENCE_CATEGORIES,
  REFERENCE_PERMISSION_ACTIONS,
  RESPONSE_MESSAGES,
} from '@shared/constants';
import {
  CommonResponseDTO,
  IBulkReferenceJobQueueResponse,
  IBulkReferenceRequest,
  IBulkReferenceResponse,
  ICityReference,
  IConfigurableFieldValueRequest,
  ICountryReference,
  IDistrictReference,
  IGenericObject,
  IReference,
  IReferenceFieldValueResponse,
  IReferenceResponse,
  IStateReference,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

import { JobQueueService } from '../../../services/job-queue.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { LoggedUserService, UserInfoResponseDTO } from '../../auth/services';
import { validateReferenceModulePermissions } from '../../core/helpers';
import { notDeleted } from '../helpers';

import { ReferenceCategoryService } from './reference-category.service';

export enum REFERENCE_ACTIONS {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',
  TEST_FORMULA = 'TEST_FORMULA',
  DISPLAY_RICH_TEXT = 'DISPLAY_RICH_TEXT',
}

export interface ReferenceStoreElement {
  all: IReferenceResponse[];
  valid: IReferenceResponse[];
}

type ReferenceStore = IGenericObject<ReferenceStoreElement>;
type GetOneReference = CommonResponseDTO<IReferenceResponse>;
type GetAllReferences = CommonResponseDTO<IReferenceResponse[]>;
type BulkReferenceResponse = CommonResponseDTO<IBulkReferenceResponse>;

@Injectable({ providedIn: 'root' })
export class ReferenceService {
  private loggedUser: UserInfoResponseDTO;
  private references = new BehaviorSubject<ReferenceStore>({});
  private fieldValues = new BehaviorSubject<
    IGenericObject<IReferenceFieldValueResponse>
  >({});
  onReferencesChange = new Subject<void>();

  constructor(
    private http: HttpClient,
    private referenceCategoryService: ReferenceCategoryService,
    private jobQueueService: JobQueueService,
    private snackBar: SnackbarService,
    private translate: TranslateService,
    loggedUserService: LoggedUserService
  ) {
    this.references.subscribe(() => {
      this.onReferencesChange.next();
    });
    loggedUserService.dataStore.subscribe((loggedUser) => {
      this.loggedUser = loggedUser;
    });
  }

  async getReferences(
    categoryId: string,
    type: keyof ReferenceStoreElement = 'all',
    forced?: boolean,
    fetchReferencesFrom: 'internal' | 'iam' | { app: string } = 'internal' // 'internal', 'iam' or connected-app id
  ): Promise<IReferenceResponse[]> {
    if (!categoryId) return [];

    const category = await this.referenceCategoryService.getCategoryById(
      categoryId
    );
    if (
      // TODO:@lathes check the situation regarding 'iam'
      typeof fetchReferencesFrom === 'object' ||
      validateReferenceModulePermissions(
        this.loggedUser,
        [category],
        REFERENCE_PERMISSION_ACTIONS.READ_CATEGORY_ITEMS
      ) !== 'none' ||
      category?.is_public
    ) {
      let references = this.references.value[categoryId];

      if (forced || !references) {
        let url: string;
        switch (fetchReferencesFrom) {
          case 'internal': {
            url = generateURL({
              endpoint: ENDPOINTS.REFERENCE_CATEGORIES_REFERENCES_GET_ALL_V2,
              params: { id: categoryId },
            });
            break;
          }
          case 'iam': {
            url = generateURL({
              endpoint: ENDPOINTS.IAM_REFERENCE_CATEGORIES_REFERENCES_GET_ALL,
              params: { id: categoryId },
            });
            break;
          }
          default: {
            url = generateURL({
              endpoint:
                ENDPOINTS.CONNECTED_APPS_GET_ALL_REFERENCES_OF_A_CONNECTED_APP,
              params: { id: fetchReferencesFrom.app, categoryId },
            });
            break;
          }
        }

        references = await firstValueFrom(
          this.http.get<GetAllReferences>(url).pipe(
            map((response) => {
              const references: ReferenceStoreElement = {
                all: response.data,
                valid: response.data.filter(notDeleted),
              };

              const storedReferences = this.references.value;
              storedReferences[categoryId] = references;
              this.references.next(storedReferences);

              return references;
            })
          )
        );
      }

      return references[type];
    } else {
      // Return empty array for handle frontend infinite loading
      return [];
    }
  }

  async getReferencesAsElAutocomplete(
    categoryId: string,
    fieldId: string,
    type: keyof ReferenceStoreElement = 'all',
    forced?: boolean,
    fetchReferencesFrom: 'internal' | 'iam' | { app: string } = 'internal'
  ): Promise<ELAutocompleteElement[]> {
    const references = await this.getReferences(
      categoryId,
      type,
      forced,
      fetchReferencesFrom
    );

    return (
      await Promise.all(
        references.map(async (reference) => {
          const fieldValue = await this.getFieldValueFromBackend({
            referenceId: reference._id,
            fieldId,
          });
          if (!fieldValue) return null;

          return {
            value: reference._id.toString(),
            displayValue: fieldValue.value.toString(),
            originalData: reference,
          } as ELAutocompleteElement;
        })
      )
    ).filter(Boolean);
  }

  async getReferenceById(categoryId: string, referenceId: string) {
    const references = await this.getReferences(categoryId);
    return references.find((reference) => {
      return !!reference?._id && reference._id.toString() === referenceId;
    });
  }

  async getReferencesForAddressPopup(
    type: PREDEFINED_REFERENCE_CATEGORIES,
    parent_ref_id?: string
  ): Promise<
    | IReference<
        | ICountryReference
        | IStateReference
        | IDistrictReference
        | ICityReference
      >[]
    | []
  > {
    let references:
      | IReference<
          | ICountryReference
          | IStateReference
          | IDistrictReference
          | ICityReference
        >[]
      | [] = [];
    const url = generateURL({
      endpoint: ENDPOINTS.REFERENCE_CATEGORIES_REFERENCES_GET_ALL,
      params: { id: type },
    });
    let params = new HttpParams();
    if (parent_ref_id) {
      params = params.append('parent_ref_id', parent_ref_id);
    }

    references = await firstValueFrom(
      this.http.get<GetAllReferences>(url, { params }).pipe(
        map((response) => {
          return (
            response.data as
              | IReference<
                  | ICountryReference
                  | IStateReference
                  | IDistrictReference
                  | ICityReference
                >[]
              | []
          ).filter(notDeleted);
        })
      )
    );

    return references;
  }

  async addReference(
    categoryId: string,
    reference: IConfigurableFieldValueRequest[]
  ): Promise<GetOneReference> {
    const category = await this.referenceCategoryService.getCategoryById(
      categoryId
    );
    return new Promise((resolve, reject) => {
      if (
        validateReferenceModulePermissions(
          this.loggedUser,
          [category],
          REFERENCE_PERMISSION_ACTIONS.WRITE_CATEGORY_ITEMS
        ) !== 'none'
      ) {
        const url = generateURL({
          endpoint: ENDPOINTS.REFERENCE_CATEGORIES_REFERENCES_ADD_NEW,
          params: { id: categoryId },
        });

        this.http.post<GetOneReference>(url, { reference }).subscribe({
          next: (response) => {
            const allReferences = this.references.value;

            const references = allReferences[categoryId] || {
              all: [],
              valid: [],
            };
            references.all.push(response.data);
            references.valid.push(response.data);

            allReferences[categoryId] = references;
            this.references.next(allReferences);
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
        });
      } else {
        reject(new Error('references.root.no-privileged'));
      }
    });
  }

  async bulkUpload(
    categoryId: string,
    data: IBulkReferenceRequest
  ): Promise<IBulkReferenceJobQueueResponse[]> {
    const category = await this.referenceCategoryService.getCategoryById(
      categoryId
    );
    return new Promise((resolve, reject) => {
      if (
        validateReferenceModulePermissions(
          this.loggedUser,
          [category],
          REFERENCE_PERMISSION_ACTIONS.WRITE_CATEGORY_ITEMS
        ) !== 'none'
      ) {
        const url = generateURL({
          endpoint: ENDPOINTS.REFERENCE_CATEGORIES_REFERENCES_BULK_ADD,
          params: { id: categoryId },
        });

        this.http
          .post<BulkReferenceResponse>(url, data)
          .subscribe(async (bulkUploadResponse) => {
            const jobCompleteResponse =
              await this.jobQueueService.checkJobStatus<
                IBulkReferenceJobQueueResponse[]
              >(bulkUploadResponse.data.jobId);

            const allReferences = this.references.value;
            const references = allReferences[categoryId] || {
              all: [],
              valid: [],
            };

            jobCompleteResponse.data.forEach((jobResponse) => {
              if (jobResponse.success === true) {
                references.all.push(jobResponse.data);
                references.valid.push(jobResponse.data);
              }
            });

            allReferences[categoryId] = references;
            this.references.next(allReferences);

            resolve(jobCompleteResponse.data);
          });
      } else {
        reject(new Error('references.root.no-privileged'));
      }
    });
  }

  private refreshReferencesList(
    categoryId: string,
    reference: IReferenceResponse
  ): void {
    const referencesInStore = this.references.value[categoryId];
    const allReferencesListIndex = referencesInStore?.all.findIndex(
      (ref) => ref._id === reference._id
    );

    if (allReferencesListIndex === -1) throw new Error();

    referencesInStore.all[allReferencesListIndex] = reference;
    referencesInStore.valid = referencesInStore.all.filter(notDeleted);

    this.references.next({
      ...this.references.value,
      [categoryId]: referencesInStore,
    });
  }

  async editReference(
    categoryId: string,
    referenceId: string,
    reference: IConfigurableFieldValueRequest[]
  ): Promise<GetOneReference> {
    const category = await this.referenceCategoryService.getCategoryById(
      referenceId
    );
    return new Promise((resolve, reject) => {
      if (
        validateReferenceModulePermissions(
          this.loggedUser,
          [category],
          REFERENCE_PERMISSION_ACTIONS.EDIT_CATEGORY_ITEMS
        ) !== 'none'
      ) {
        const url = generateURL({
          endpoint: ENDPOINTS.REFERENCE_EDIT,
          params: { categoryId, referenceId },
        });

        this.http.patch<GetOneReference>(url, { reference }).subscribe({
          next: (updatedReference) => {
            this.refreshReferencesList(categoryId, updatedReference.data);
            resolve(updatedReference);
          },
          error: (error) => {
            if (error.error.error === FIELD_VALIDATION_ERROR_TYPES.UNIQUE) {
              this.snackBar.error(
                this.translate.instant('references.unique-error')
              );
            }

            reject(error);
          },
        });
      } else {
        reject(new Error('references.root.no-privileged'));
      }
    });
  }

  private deleteRestoreReference(
    categoryId: string,
    url: string
  ): Observable<GetOneReference> {
    return this.http
      .delete<GetOneReference>(url)
      .pipe(
        tap((updatedReference) =>
          this.refreshReferencesList(categoryId, updatedReference.data)
        )
      );
  }

  async deleteReference(
    categoryId: string,
    referenceId: string
  ): Promise<GetOneReference> {
    const category = await this.referenceCategoryService.getCategoryById(
      referenceId
    );
    return new Promise((resolve, reject) => {
      if (
        validateReferenceModulePermissions(
          this.loggedUser,
          [category],
          REFERENCE_PERMISSION_ACTIONS.DELETE_CATEGORY_ITEMS
        ) !== 'none'
      ) {
        const url = generateURL({
          endpoint: ENDPOINTS.REFERENCE_DELETE,
          params: { categoryId, referenceId },
        });
        this.deleteRestoreReference(categoryId, url).subscribe(resolve);
      } else {
        reject(new Error('references.root.no-privileged'));
      }
    });
  }

  async restoreReference(
    categoryId: string,
    referenceId: string
  ): Promise<GetOneReference> {
    const category = await this.referenceCategoryService.getCategoryById(
      referenceId
    );
    return new Promise((resolve, reject) => {
      if (
        validateReferenceModulePermissions(
          this.loggedUser,
          [category],
          REFERENCE_PERMISSION_ACTIONS.RESTORE_CATEGORY_ITEMS
        ) !== 'none'
      ) {
        const url = generateURL({
          endpoint: ENDPOINTS.REFERENCE_RESTORE,
          params: { categoryId, referenceId },
        });
        this.deleteRestoreReference(categoryId, url).subscribe(resolve);
      } else {
        reject(new Error('references.root.no-privileged'));
      }
    });
  }

  async getFieldValueFromBackend({
    referenceId,
    fieldId,
    app_id,
    external_category,
  }: {
    referenceId: Types.ObjectId | string;
    fieldId: Types.ObjectId | string;
    app_id?: string;
    external_category?: string | undefined;
  }): Promise<IReferenceFieldValueResponse | undefined> {
    return new Promise((resolve, reject) => {
      if (!referenceId || !fieldId)
        return reject(new Error(RESPONSE_MESSAGES.INVALID_ID));

      const storedRefValues = this.fieldValues.value;
      const key = `${referenceId}-${fieldId}`;

      if (storedRefValues[key]) return resolve(storedRefValues[key]);

      const url = generateURL({
        endpoint: ENDPOINTS.REFERENCES_FIELDS_GET_VALUE,
        params: {
          id: referenceId.toString(),
          field_id: fieldId.toString(),
        },
      });
      firstValueFrom(
        this.http.get<CommonResponseDTO<IReferenceFieldValueResponse>>(url, {
          params: {
            ...(app_id ? { app_id } : undefined),
            ...(external_category ? { external_category } : undefined),
          },
        })
      )
        .then(({ data }) => {
          this.fieldValues.next({
            ...storedRefValues,
            [key]: data,
          });

          resolve(data);
        })
        .catch(() => {
          resolve(undefined);
        });
    });
  }
}
