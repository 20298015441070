import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs';

import { MODULES, USER_TYPES } from '@shared/constants';

import { FlowControlService } from '../../../services/flow-control.service';
import { LoggedUserService } from '../../auth/services';
import { ModulesService } from '../../modules/services/modules.service';

@Injectable({
  providedIn: 'root',
})
export class ModuleGuard implements CanLoad, CanActivate {
  constructor(
    private modulesService: ModulesService,
    private router: Router,
    private location: Location,
    private loggedUserService: LoggedUserService,
    private flowControlService: FlowControlService,
    private translateService: TranslateService
  ) {}

  getTranslation(module: MODULES): string {
    switch (module) {
      case MODULES.APPS:
        return this.translateService.instant('pages.deactivate.apps');

      case MODULES.AUTHORIZATION_PERMISSIONS:
        return this.translateService.instant('pages.deactivate.permissions');

      case MODULES.AUTHORIZATION_PRIVILEGES:
        return this.translateService.instant('pages.deactivate.privileges');

      case MODULES.CONNECTIONS:
        return this.translateService.instant('pages.deactivate.connections');

      case MODULES.AUTHORIZATION_ROLES:
        return this.translateService.instant('pages.deactivate.roles');

      case MODULES.IDENTITIES:
        return this.translateService.instant('pages.deactivate.identities');

      case MODULES.INTEGRATIONS:
        return this.translateService.instant('pages.deactivate.integrations');

      case MODULES.MESSAGES:
        return this.translateService.instant('pages.deactivate.messages');

      case MODULES.ACHIEVEMENTS:
        return this.translateService.instant('pages.deactivate.achievements');

      case MODULES.LEADS:
        return this.translateService.instant('pages.deactivate.leads');

      case MODULES.ACTIVITIES:
        return this.translateService.instant('pages.deactivate.activities');

      case MODULES.COMPETITIONS:
        return this.translateService.instant('pages.deactivate.competitions');

      case MODULES.AGENTS:
        return this.translateService.instant('pages.deactivate.agents');

      case MODULES.TARGETS:
        return this.translateService.instant('pages.deactivate.targets');

      case MODULES.RECRUITMENTS:
        return this.translateService.instant('pages.deactivate.recruitments');

      case MODULES.CUSTOMERS:
        return this.translateService.instant('pages.deactivate.customers');

      case MODULES.POLICIES:
        return this.translateService.instant('pages.deactivate.policies');

      case MODULES.COVER_NOTES:
        return this.translateService.instant('pages.deactivate.cover-notes');

      case MODULES.CLAIMS:
        return this.translateService.instant('pages.deactivate.claims');

      case MODULES.ENDORSEMENTS:
        return this.translateService.instant('pages.deactivate.endorsements');

      case MODULES.STORAGE_DRIVE:
        return this.translateService.instant('pages.deactivate.drives');

      case MODULES.STORAGE_RECYCLE_BIN:
        return this.translateService.instant('pages.deactivate.trash');

      case MODULES.NOTICES:
        return this.translateService.instant('pages.deactivate.notices');

      case MODULES.NOTIFICATIONS:
        return this.translateService.instant('pages.deactivate.notifications');

      case MODULES.REFERENCES:
        return this.translateService.instant('pages.deactivate.references');

      case MODULES.KNOWLEDGEBASE:
        return this.translateService.instant('pages.deactivate.knowledgebase');

      case MODULES.LOGS:
        return this.translateService.instant('pages.deactivate.logs');

      case MODULES.SETUP:
        return this.translateService.instant('pages.deactivate.setup');

      default:
        return '';
    }
  }

  private restrict(module: MODULES) {
    console.log(
      `[DEBUG ModuleGuard]: Navigated to the deactivated page because of the ${module} module`
    );

    const routeData = {
      title: this.getTranslation(module),
      goBackEnabled: true,
      searchBarEnabled: false,
    };
    const destination = this.router
      .getCurrentNavigation()
      ?.extractedUrl?.toString();
    this.router
      .navigateByUrl('/app/deactivated', { skipLocationChange: true })
      .then(() => {
        if (destination) {
          this.location.replaceState(destination);
          this.flowControlService.updateRouteInfo(
            destination.split('?')[0],
            routeData
          );
        }
      });
  }

  private validate(route: Route | ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      let stopPipe = false;
      this.loggedUserService.dataStore
        .pipe(takeWhile(() => !stopPipe))
        .subscribe(async (loggedUserInfo) => {
          if (loggedUserInfo) {
            stopPipe = true;
            if (route.data.module === MODULES.SETUP) {
              if (loggedUserInfo?.user_type === USER_TYPES.EL_ADMIN) {
                return resolve(true);
              } else {
                this.restrict(route.data.module);
                return resolve(false);
              }
            }

            const foundModule = await this.modulesService.getModuleByKey(
              route.data.module
            );
            if (foundModule?.is_enabled) {
              return resolve(true);
            } else {
              this.restrict(foundModule.key);
              return resolve(false);
            }
          }
        });
    });
  }

  canLoad(route: Route): Promise<boolean> {
    return this.validate(route);
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.validate(route);
  }
}
