export enum LEAD_TYPES {
  CUSTOMER = 'CUSTOMER',
  RECRUITMENT = 'RECRUITMENT',
}

// IMPORTANT: Keep in sync with LEAD_TYPES
export enum LEADS_DEFAULT_ADDITIONAL_FIELDS_LEAD_TYPE_DROPDOWN_OPTIONS {
  CUSTOMER = 'Customer',
  RECRUITMENT = 'Recruitment',
}

export enum LEAD_STATUSES {
  SUSPECT = 'SUSPECT',
  PROSPECT = 'PROSPECT',
  QUOTATION_ISSUED = 'QUOTATION_ISSUED',
  REJECTED = 'REJECTED',
  REASSIGNED = 'REASSIGNED',
  REFER_NEXT_INTERVIEW = 'REFER_NEXT_INTERVIEW',
  REFER_TO_EXAM = 'REFER_TO_EXAM',
  RETURN = 'RETURN',
  RETURN_DOCUMENT = 'RETURN_DOCUMENT',
  PENDING_CODE_CREATION = 'PENDING_CODE_CREATION',
  RECRUITED = 'RECRUITED',
  INCOMPLETE = 'INCOMPLETE', // for public api
  PROPOSAL_SUBMITTED = 'PROPOSAL_SUBMITTED',
  POLICY_ISSUED = 'POLICY_ISSUED',
}

export enum RECRUITMENT_STATUSES {
  EXITED = 'EXITED',
  PENDING_CODE_CREATION = 'PENDING_CODE_CREATION',
  PENDING_CODE_CREATION_REJECTED = 'PENDING_CODE_CREATION_REJECTED',
  PENDING_INTERVIEW = 'PENDING_INTERVIEW',
  RECRUITED = 'RECRUITED',
  REFER_NEXT_INTERVIEW = 'REFER_NEXT_INTERVIEW',
  REFER_TO_EXAM = 'REFER_TO_EXAM',
  REJECTED = 'REJECTED',
  RETURN = 'RETURN',
  RETURN_DOCUMENT = 'RETURN_DOCUMENT',
}

export enum AGENT_ASSIGNMENT_MODES {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
  PUBLIC = 'PUBLIC',
}

export enum LEADS_DEFAULT_ADDITIONAL_FIELDS {
  CHANNEL_TYPE = 'CHANNEL TYPE',
  LEAD_TYPE = 'LEAD TYPE',
}

export enum LEADS_DEFAULT_ADDITIONAL_FIELDS_CHANNEL_TYPE_DROPDOWN_OPTIONS {
  AGENTS = 'Agent',
  PUBLIC_API = 'Public API',
}

export enum LEAD_FIELD_VALIDATION_ERROR_TYPES {
  NOT_UNIQUE = 'NOT_UNIQUE',
  IS_EMPTY = 'IS_EMPTY',
  FIELDS_MISSING = 'FIELDS_MISSING',
  NOT_VALID = 'NOT_VALID',
}
