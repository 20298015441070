// if there is a need of add an element into this, do a global search with "case CONFIGURABLE_FIELD_DATA_TYPES"
// and do necessary changes for all affected places
export enum CONFIGURABLE_FIELD_DATA_TYPES {
  // Non defined data types
  NONE = 'none',
  SEPARATOR = 'separator',
  // Basic types
  TEXT = 'text',
  NUMBER = 'number',
  CURRENCY = 'currency',
  DATE = 'date',
  TIME = 'time',
  DATE_TIME = 'date_time',
  BOOLEAN = 'boolean',
  // Extended types
  FORMULA = 'formula',
  VIEW = 'view',
  JSON = 'json',
  FILE = 'file',
  RICH_TEXT = 'rich_text',
  // Another reference
  REFERENCES = 'references',
  // Multi Field types
  ADDRESS = 'address',
  NAME = 'name',
  CONTACT_NUMBER = 'contact number',
  EMAIL = 'email',
  // Advanced
  CHECKBOX = 'checkbox',
  RADIO_BUTTON = 'radio_button',
  DROPDOWN = 'dropdown',
}

export enum FIELD_LAYOUT_TYPES {
  FULL_WIDTH = 'FULL_WIDTH',
  HALF_WIDTH = 'HALF_WIDTH',
}

export enum FIELD_MODE_TYPES {
  ADVANCED = 'ADVANCED',
  BASIC = 'BASIC',
}

export enum CONFIGURABLE_FIELD_CHECKBOX_TYPES {
  DEFAULT = 'DEFAULT',
  REQUIRED = 'REQUIRED',
  SEARCHABLE = 'SEARCHABLE',
  SORT = 'SORT',
  CONSTANT = 'CONSTANT',
  UNIQUE = 'UNIQUE',
  PASS_TO_ACTIVITIES = 'PASS_TO_ACTIVITIES',
  OTHER = 'OTHER',
}

export enum ADDITIONAL_FIELDS_TYPE {
  DROPDOWN = 'DROPDOWN',
  TEXT = 'TEXT',
}

export enum CRITERIA_COMPARE_TYPES {
  DIRECT = 'DIRECT',
  SOMETHING_ELSE = 'SOMETHING_ELSE',
}

export enum CRITERIA_TYPES {
  FILTER = 'FILTER',
  DISABLE = 'DISABLE',
}

// all the mechanisms related to each and every element in this enum should be
// defined in libs/utils/src/lib/configurable-fields/additional-fields.ts
export enum CONFIGURABLE_FIELD_DROPDOWN_OPTION_GENERATORS {
  // DUMMY CONTENT
  LEADS_BRANCH = 'LEADS_BRANCH',
  LEADS_CHANNEL_TYPE = 'CHANNEL_TYPE',
  LEADS_LEAD_TYPE = 'LEAD_TYPE',
}
