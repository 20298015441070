<table style="width: 100%">
  <div class="container-fluid">
    <div style="float: right; margin-top: 0px; font-size: small">
      {{ this.created_on }}
    </div>
    <div class="column left" style="margin-top: -15px; margin-right: 30px">
      <img
        [src]="client_information?.logo_path_light"
        width="110px"
        height="150px"
        [alt]="client_information?.website"
      />
    </div>

    <div
      style="
        position: relative;
        align-items: left;
        justify-content: left;
        text-align: left;
      "
    >
      <div style="padding-left: 50px">
        <h2 style="padding-top: 5px">
          {{ client_information?.name?.toUpperCase() }}
        </h2>
        <p style="margin: 0; padding: 0px">
          {{ client_information?.address }}
        </p>
        <p style="margin: 0; padding: 0px">
          Tel :
          {{
            client_information?.phone_number?.dialCode +
              client_information?.phone_number?.number
          }}
        </p>
        <p style="margin: 0; padding: 0px">
          Email:
          {{ client_information?.email }}
        </p>
      </div>
      <div
        style="
          margin-top: 33px;
          justify-content: center;
          text-align: center;
          padding-right: 8px;
        "
      >
        <p style="font-size: 20px; margin-top: 2px">
          <strong>
            {{
              proposal_information?.proposer_details?.customer_type ===
              'INDIVIDUAL'
                ? ('policies.proposal.pdf.proposal-form-travel-ind' | translate)
                : ('policies.proposal.pdf.proposal-form-travel' | translate)
            }}
          </strong>
          <br />
          <strong>
            <u>
              {{ 'policies.proposal.pdf.quotation-number' | translate }}-{{
                ref_number
              }}
            </u>
          </strong>
        </p>
      </div>

      <div class="content" style="padding-top: -20px; margin-top: -20px">
        <br />
        <div>
          <div>
            <!------ Proposer Stepper -------->
            <ng-container>
              <p class="table_caption">
                <strong>
                  01.
                  {{ 'policies.proposal.add-edit.steppers.1' | translate }}
                </strong>
              </p>

              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th colspan="2" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.type-pdf'
                          | translate
                      }}
                    </th>
                    <td colspan="2" class="table_result">
                      {{
                        proposal_information?.proposer_details?.customer_type ||
                          '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.name'
                          | translate
                      }}
                    </th>
                    <td colspan="2" class="table_result">
                      {{
                        getName(proposal_information?.proposer_details?.name)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.engagement-pdf'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                        titleCasePipe.transform(
                          proposal_information?.proposer_details?.engagement
                        )
                      }}
                    </td>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.contact'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                      proposal_information?.proposer_details?.contact?.[0]
                      ? getPhone(
                      proposal_information?.proposer_details
                      ?.contact
                      )
                      : '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.email'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                      proposal_information?.proposer_details?.email?.[0]
                      ? getEmail(
                      proposal_information?.proposer_details?.email
                      )
                      : '-'
                      }}
                    </td>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.fax'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details?.fax
                          ? getPhone(
                              proposal_information?.proposer_details?.fax
                            )
                          : '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.identification_number'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details
                          ?.identification_number || '-'
                      }}
                    </td>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.occupation'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details?.occupation ||
                          '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.vat_number'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details?.vat_number ||
                          '-'
                      }}
                    </td>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.svat_number'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details?.svat_number ||
                          '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table_header">Date of Birth</th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details?.date_of_birth
                          ? dateTimeFormatService.formatDateWithoutTime(
                              proposal_information?.proposer_details
                                ?.date_of_birth
                            )
                          : ''
                      }}
                    </td>
                    <th class="table_header">Age (Year(s))</th>
                    <td class="table_result">
                      {{ proposal_information?.proposer_details?.age || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table_header">Passport Number</th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details?.passport || '-'
                      }}
                    </td>
                    <th class="table_header">Purpose of Visit</th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details?.visit_purpose ||
                          '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.communication-mode-pdf'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{ communication_modes }}
                    </td>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.communication-language-pdf'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{ communication_languages }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table_header">Period Start Date</th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details?.trip_period_from
                          ? dateTimeFormatService.formatDateWithoutTime(
                              proposal_information?.proposer_details
                                ?.trip_period_from
                            )
                          : ''
                      }}
                    </td>
                    <th class="table_header">Period End Date</th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details?.trip_period_to
                          ? dateTimeFormatService.formatDateWithoutTime(
                              proposal_information?.proposer_details
                                ?.trip_period_to
                            )
                          : '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>

            <!-- Travel Individual Sections -->
            <!-- Scheme And Sum Assured Info -->

            <ng-container *ngIf="!isTravelGroup">
              <p class="table_caption">
                <strong> 02. PRODUCT INFORMATION </strong>
              </p>

              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th width="50%" class="table_header">Product Code</th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information
                          ?.travel_individual_product_information
                          ?.product_code || '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">Travel Area</th>
                    <td width="50%" class="table_result">
                      {{
                        getTravelAreaValueString(
                          proposal_information
                            ?.travel_individual_product_information?.travel_area
                        )
                      }}
                    </td>
                  </tr>

                  <ng-container
                    *ngFor="
                      let section of proposal_information
                        ?.travel_individual_product_information?.sections;
                      let j = index
                    "
                  >
                    <br />

                    <tr>
                      <th width="50%" class="table_header">Section</th>
                      <td width="50%" class="table_result">
                        {{ getSectionName(section?.section) || '-' }}
                      </td>
                    </tr>

                    <ng-container
                      *ngFor="let cover of section?.covers_list; let k = index"
                    >
                      <tr>
                        <th width="50%" class="table_header">Cover</th>
                        <td width="50%" class="table_result">
                          {{ getCoverName(cover?.cover) || '-' }}
                        </td>
                      </tr>
                      <tr>
                        <th width="50%" class="table_header">Value Type</th>
                        <td width="50%" class="table_result">
                          {{ cover?.valueType || '-' }}
                        </td>
                      </tr>
                      <tr>
                        <th width="50%" class="table_header">
                          Value ({{ schedule_currency }})
                        </th>
                        <td width="50%" class="table_result">
                          {{ cover?.sum_assured || cover?.hour_based || '-' }}
                        </td>
                      </tr>
                      <tr>
                        <th width="50%" class="table_header">
                          Maximum Rate ({{ schedule_currency }})
                        </th>
                        <td width="50%" class="table_result">
                          {{ cover?.maximum_rate || '-' }}
                        </td>
                      </tr>
                      <tr>
                        <th width="50%" class="table_header">
                          Excess ({{ schedule_currency }})
                        </th>
                        <td width="50%" class="table_result">
                          {{ cover?.excess || '-' }}
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </tbody>
              </table>
              <br />
            </ng-container>

            <!-- Travel Group Sections -->
            <!-- Scheme And Sum Assured Info -->

            <ng-container *ngIf="isTravelGroup">
              <p class="table_caption">
                <strong> 02. SCHEME & PRODUCT INFORMATION </strong>
              </p>

              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th width="50%" class="table_header">Product Code</th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.travel_group_product_information
                          ?.product_code || '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">Travel Area</th>
                    <td width="50%" class="table_result">
                      {{
                        getTravelAreaValueString(
                          proposal_information?.travel_group_product_information
                            ?.travel_area
                        )
                      }}
                    </td>
                  </tr>

                  <br />

                  <ng-container
                    *ngFor="
                      let scheme of proposal_information
                        ?.travel_group_product_information?.schemes;
                      let i = index
                    "
                  >
                    <tr>
                      <th width="50%" class="table_header">Scheme</th>
                      <td width="50%" class="table_result">
                        {{ scheme?.scheme || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">Scheme Count</th>
                      <td width="50%" class="table_result">
                        {{ scheme?.count || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">Product Plan</th>
                      <td width="50%" class="table_result">
                        {{ getPlanName(scheme?.product_plan) || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">Age Ranges</th>
                      <td width="50%" class="table_result">
                        {{ getAgeRanges(scheme?.age_ranges) || '-' }}
                      </td>
                    </tr>

                    <br />

                    <ng-container
                      *ngFor="let section of scheme?.sections; let j = index"
                    >
                      <tr>
                        <th width="50%" class="table_header">Section</th>
                        <td width="50%" class="table_result">
                          {{ getSectionName(section?.section) || '-' }}
                        </td>
                      </tr>

                      <ng-container
                        *ngFor="
                          let cover of section?.covers_list;
                          let k = index
                        "
                      >
                        <tr>
                          <th width="50%" class="table_header">Cover</th>
                          <td width="50%" class="table_result">
                            {{ getCoverName(cover?.cover) || '-' }}
                          </td>
                        </tr>
                        <tr>
                          <th width="50%" class="table_header">Value Type</th>
                          <td width="50%" class="table_result">
                            {{ cover?.valueType || '-' }}
                          </td>
                        </tr>
                        <tr>
                          <th width="50%" class="table_header">
                            Value ({{ schedule_currency }})
                          </th>
                          <td width="50%" class="table_result">
                            {{ cover?.sum_assured || cover?.hour_based || '-' }}
                          </td>
                        </tr>
                        <tr>
                          <th width="50%" class="table_header">
                            Maximum Rate ({{ schedule_currency }})
                          </th>
                          <td width="50%" class="table_result">
                            {{ cover?.maximum_rate || '-' }}
                          </td>
                        </tr>
                        <tr>
                          <th width="50%" class="table_header">
                            Excess ({{ schedule_currency }})
                          </th>
                          <td width="50%" class="table_result">
                            {{ cover?.excess || '-' }}
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </tbody>
              </table>
            </ng-container>

            <!-- Prevailing Ailments -->

            <ng-container>
              <p class="table_caption">
                <strong>
                  03.
                  {{
                    'policies.proposal.add-edit.steppers.2-medical' | translate
                  }}
                </strong>
              </p>
              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th colspan="2" class="table_header">
                      Details of the Medical treatment obtained during the last
                      12 months
                    </th>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">
                      Details of Sickness/ Illness
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.travel_prevailing_ailments
                          ?.sickness_details
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">
                      Name & Address of the Doctor
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.travel_prevailing_ailments
                          ?.sickness_doctor_name_address
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">
                      Name & Address of the Family Doctor
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.travel_prevailing_ailments
                          ?.family_doctor_name_address
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="75%" class="table_header">
                      Have you proposed for cover under this proposal ever made
                      a claim under any Accident, Sickness, Medical Expenses or
                      any other section of the Certificate ?
                    </th>
                    <td width="25%" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.travel_prevailing_ailments
                            ?.question_2
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="75%" class="table_header">
                      Have any Insurer declined or imposed special terms for you
                      proposed under this proposal, in respect of Life,
                      Accident, Sickness, Hospital Expenses or Travel Insurance
                      ?
                    </th>
                    <td width="25%" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.travel_prevailing_ailments
                            ?.question_3
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="75%" class="table_header">
                      In respect of any of the covers to which this proposal
                      relates and any business in which you have been engaged,
                      Has any insurer ever declined a proposal, refused a
                      renewal, terminated an insurance or imposed special terms
                      ?
                    </th>
                    <td width="25%" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.travel_prevailing_ailments
                            ?.question_4
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="75%" class="table_header">
                      Have you in the last 5 years suffered any accidents,
                      losses or had claims arisen in respect of any of the
                      insurance proposed whether previously insured or not ?
                    </th>
                    <td width="25%" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.travel_prevailing_ailments
                            ?.question_5
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>

            <!------ Credit Request Stepper -------->
            <ng-container>
              <p class="table_caption">
                <strong>
                  04.
                  {{ 'policies.proposal.add-edit.steppers.2' | translate }}
                </strong>
              </p>

              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th width="25%" class="table_header">Payment Method</th>
                    <td width="25%" class="table_result">
                      {{
                        titleCasePipe.transform(
                          proposal_information?.credit_request_details
                            ?.payment_method
                        ) || '-'
                      }}
                    </td>
                  </tr>
                  <tr
                    *ngIf="
                      proposal_information?.credit_request_details
                        ?.payment_method === 'CREDIT'
                    "
                  >
                    <th width="25%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.credit-request.credit-amount'
                          | translate
                      }}
                    </th>
                    <td width="25%" class="table_result">
                      {{
                        proposal_information?.credit_request_details
                          ?.credit_amount || '-'
                      }}
                      Days
                    </td>
                  </tr>
                  <tr
                    *ngIf="
                      proposal_information?.credit_request_details
                        ?.payment_method === 'CREDIT'
                    "
                  >
                    <th width="25%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.credit-request.credit-period'
                          | translate
                      }}
                    </th>
                    <td width="25%" class="table_result">
                      {{
                        proposal_information?.credit_request_details
                          ?.credit_period || '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                *ngIf="
                  proposal_information?.credit_request_details?.schedules
                    ?.length > 0
                "
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <ng-container
                    *ngFor="
                      let sch of proposal_information?.credit_request_details
                        ?.schedules
                    "
                  >
                    <tr>
                      <td width="33.3%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.credit-request.date'
                            | translate
                        }}
                      </td>
                      <td width="33.3%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.credit-request.days'
                            | translate
                        }}
                      </td>
                      <td width="33.3%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.credit-request.amount'
                            | translate
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td width="33.3%" class="table_result">
                        {{
                          sch.date
                            ? dateTimeFormatService.formatDateWithoutTime(
                                sch.date
                              )
                            : '-'
                        }}
                      </td>
                      <td width="33.3%" class="table_result">
                        {{ sch.number_of_days }}
                      </td>
                      <td width="33.3%" class="table_result">
                        {{ sch.amount }}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
                *ngIf="
                  proposal_information?.credit_request_details
                    ?.payment_method === 'CREDIT'
                "
              >
                <tbody>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.credit-request.remark'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.credit_request_details?.remark ||
                          '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </div>
        </div>
      </div>

      <p class="table_caption">
        <strong> {{ 'policies.proposal.pdf.declaration' | translate }}</strong>
      </p>
      <div style="margin: auto; text-align: justify">
        {{ 'policies.proposal.pdf.declaration-medical' | translate }}
      </div>

      <table
        width="100%"
        border="1"
        style="border-collapse: collapse; text-align: left; margin-top: 20px"
      >
        <tbody>
          <tr>
            <th width="50%" class="table_header">
              {{ 'policies.proposal.pdf.name-office' | translate }}
            </th>
            <td width="50%" class="table_result"></td>
          </tr>
          <tr>
            <th width="50%" class="table_header">
              {{ 'policies.proposal.pdf.date' | translate }}
            </th>
            <td width="50%" class="table_result"></td>
          </tr>
          <tr height="100px">
            <th width="50%" class="table_header">
              {{ 'policies.proposal.pdf.sign-with-stamp' | translate }}
            </th>
            <td width="50%" class="table_result"></td>
          </tr>
        </tbody>
      </table>

      <div
        class="footer"
        style="justify-content: center; text-align: center; margin-left: -10px"
      ></div>
    </div>
  </div>
</table>
