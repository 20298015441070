import { CONFIGURABLE_FIELD_CHECKBOX_TYPES } from '../../configurable-fields';

export const LEADS_MODULE_PASS_TO_ACTIVITY_CHECKBOX = {
  key: 'Pass to Activity',
  type: CONFIGURABLE_FIELD_CHECKBOX_TYPES.PASS_TO_ACTIVITIES,
  isChecked: true,
};

export enum LEADS_DEFAULT_FIELDS {
  PRODUCTS = 'Products',
  ACTIVITY_TYPE = 'Activity Type',
  CATEGORIES = 'Categories',
  RELATIONSHIPS = 'Relationships',
  NIC = 'National Identity Card (NIC) Number',
  CONTACT_NUMBER = 'Contact Number (Type)',
  ADDRESS = 'Address',
  EMAIL = 'Email Address (Type)',
  BIRTHDAY = 'Birthday',
}
