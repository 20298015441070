export enum FORM_BUILDER_ELEMENT_TYPES {
  SECTION_TITLE = 'SECTION_TITLE',
  SHORT_ANSWER = 'SHORT_ANSWER',
  PARAGRAPH = 'PARAGRAPH',
  CHECKBOX = 'CHECKBOX',
  RADIO_BUTTON = 'RADIO_BUTTON',
  UPLOAD_FIELD = 'UPLOAD_FIELD',
  DROPDOWN = 'DROPDOWN',
  DATE_TIME = 'DATE_TIME',
  NAME = 'NAME',
  ADDRESS = 'ADDRESS',
  NUMBER = 'NUMBER',
}
