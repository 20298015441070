import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DataElementActionProperties,
  DataListTableActionEvent,
  DataListTableElement,
  DataListTableElementCheckBoxClickedEvent,
} from '@el-data-list-table';

import { IActionButton } from '../action-button/action-button.component';

@Component({
  selector: 'app-display-data-in-dlt',
  templateUrl: './display-data-in-dlt.component.html',
})
export class DisplayDataInDLTComponent {
  @Input() downloadButtonEnabled = true;
  @Input() showMoreButtonEnabled = true;
  @Input() getAllButtonEnabled = false;
  @Input() showSelectAllCheckbox = false;
  @Input() disableNoResultsDescription = false;
  @Input() isListLoading: boolean;
  @Input() totalCount: number;
  @Input() pageSize: number;
  @Input() dataListArray: DataListTableElement[] = [];
  @Input() filteredActionButtons: IActionButton[] = [];

  @Output() onChangePageSize = new EventEmitter<number>();
  @Output() onClickShowMore = new EventEmitter<boolean>();
  @Output() onClickAction = new EventEmitter<DataListTableActionEvent>();
  @Output() onDataElementClickAction =
    new EventEmitter<DataElementActionProperties>();
  @Output() onElementExpand = new EventEmitter<number>();
  @Output() onClickActionButton = new EventEmitter<string>();
  @Output() onCheckBoxClickedAction =
    new EventEmitter<DataListTableElementCheckBoxClickedEvent>();
  @Output() onClickGetAll = new EventEmitter<DataListTableElement[]>();
}
