export enum COMPANY_TYPES {
  INSURANCE = 'Broker (Insurance)',
  REINSURANCE = 'Broker (Reinsurance)',
  BANK = 'Bank',
  LEASING_COMPANY = 'Leasing Company',
  FINANCIAL_INSTITUTES = 'Financial Institute',
}

export enum PARTNER_STATUS {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export enum AGENT_SELECTION {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export enum THIRD_PARTIES_REFERENCE_CATEGORIES {
  LEGAL_ENTITY = 'LEGAL-ENTITIES',
}
