import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ELAutocompleteElement } from '@el-autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import {
  COMPARE_WITH,
  CONFIGURABLE_FIELD_CHECKBOX_TYPES,
  CONFIGURABLE_FIELD_DATA_TYPES,
  REFERENCE_COMPARISON_TYPES,
} from '@shared/constants';
import {
  IConfigurableFieldConfigResponse,
  IFileConfigResponse,
  IFormulaFileDataElementFilter,
  IReferenceCategoryResponse,
  IReferenceResponse,
  IReferenceTypedConfigurableFieldConfigResponse,
} from '@shared/interfaces';

import { ReferenceService } from '../../../../../references/services';
import { ReferenceCategoryService } from '../../../../../references/services/reference-category.service';
import { IDisplayConfigurableFieldElement } from '../../../../types';

@Component({
  selector: 'app-define-file-filters-popup',
  templateUrl: 'define-file-filters-popup.component.html',
  styleUrls: ['define-file-filters-popup.component.scss'],
})
export class DialogContentDefineFileFiltersComponent
  implements OnInit, OnDestroy
{
  foreignReferenceFields: IConfigurableFieldConfigResponse[];
  fileConfig: IFileConfigResponse;
  categoryArray: IReferenceCategoryResponse[] = [];
  COMPARISON_TYPES = Object.values(REFERENCE_COMPARISON_TYPES);

  fields: {
    field: FormControl<string>;
    compareWith: FormControl<'local' | 'direct'>;
    comparisonType: FormControl<string>;
    compareValue: FormControl<string>;
    selectedCategory?: IReferenceCategoryResponse;
    fieldItem?: IDisplayConfigurableFieldElement;
  }[] = [];

  private onDestroy$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<DialogContentDefineFileFiltersComponent>,
    private referenceCategoryService: ReferenceCategoryService,
    private referenceService: ReferenceService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      fileConfig: IFileConfigResponse;
      filters: IFormulaFileDataElementFilter[];
    }
  ) {
    this.foreignReferenceFields = data?.fileConfig?.fields ?? [];
    this.fileConfig = data?.fileConfig;
    this.fields = [];
    data.filters.forEach((filter, index) => {
      const comparisonType =
        filter.comparisonType || REFERENCE_COMPARISON_TYPES.EQUAL;

      this.fields.push({
        compareWith: new FormControl(filter.compareWith, Validators.required),
        field: new FormControl(filter.field, Validators.required),
        compareValue: new FormControl(filter.compareValue, Validators.required),
        comparisonType: new FormControl(comparisonType, Validators.required),
      });
      this.setFieldItem(index, filter.compareWith);
    });
  }

  ngOnInit(): void {
    this.referenceCategoryService.activeCategories
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((activeCategories) => {
        this.categoryArray = activeCategories;
      });
    if (this.fields.length === 0) {
      this.addForm();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async setFieldItem(index: number, change: COMPARE_WITH) {
    const field = this.fields?.[index];
    if (!field) return;
    const resetAndExit = () => {
      this.fields[index].fieldItem = undefined;
      return;
    };
    if (change !== 'direct') return resetAndExit();
    const selectedReferenceField = this.foreignReferenceFields.find(
      (foreignField) => foreignField._id === field.field.value
    );

    const selectedReferenceFieldDataType = selectedReferenceField?.type;
    if (!selectedReferenceFieldDataType) return resetAndExit();
    let referenceTypeFieldConfig: IReferenceTypedConfigurableFieldConfigResponse;
    let references: ELAutocompleteElement<IReferenceResponse>[];
    if (
      selectedReferenceFieldDataType ===
      CONFIGURABLE_FIELD_DATA_TYPES.REFERENCES
    ) {
      referenceTypeFieldConfig =
        selectedReferenceField?.reference_type_field_config;
      if (!referenceTypeFieldConfig) return resetAndExit();
      references = await Promise.all(
        (
          await this.referenceService.getReferences(
            referenceTypeFieldConfig.category_id.toString()
          )
        ).map(async (reference) => {
          const refValue = await this.referenceService.getFieldValueFromBackend(
            {
              referenceId: reference._id,
              fieldId: referenceTypeFieldConfig.field_id,
            }
          );
          return {
            displayValue: refValue.value.toString(),
            value: reference._id.toString(),
            originalData: reference,
          };
        })
      );
    }
    this.fields[index].fieldItem = {
      configuration: {
        name: this.translate.instant(
          'configurable-fields.formula-builder.define-reference-filters.compare-value'
        ),
        type: selectedReferenceFieldDataType,
        reference_type_field_config: referenceTypeFieldConfig,
        checkboxes: [
          {
            type: CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED,
            isChecked: true,
            key: 'REQUIRED',
          },
        ],
      },
      references,
      field_value: field.compareValue,
    };
  }

  addForm() {
    this.fields.push({
      field: new FormControl('', Validators.required),
      compareWith: new FormControl('local', Validators.required),
      compareValue: new FormControl('', Validators.required),
      comparisonType: new FormControl(
        REFERENCE_COMPARISON_TYPES.EQUAL,
        Validators.required
      ),
    });
  }

  removeForm(index: number) {
    this.fields.splice(index, 1);
    if (this.fields.length === 0) {
      this.addForm();
    }
  }

  save() {
    const newFields = this.fields
      .map(({ compareValue, compareWith, field, comparisonType }) => {
        if (
          compareValue.valid &&
          compareWith.valid &&
          field.valid &&
          comparisonType.valid
        ) {
          return {
            field: field.value,
            compareWith: compareWith.value,
            compareValue: compareValue.value,
            comparisonType: comparisonType.value,
          };
        }
        return null;
      })
      .filter(Boolean);

    this.dialogRef.close(newFields);
  }

  isAllValid() {
    const valid = this.fields.every(
      ({ compareValue, compareWith, field, comparisonType }) => {
        return (
          compareValue.valid &&
          compareWith.valid &&
          field.valid &&
          comparisonType.valid
        );
      }
    );
    return !valid;
  }
}
