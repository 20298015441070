import { IPayoutConfigBatchScheduleResponse } from '@shared/interfaces';

export interface IBatchCoverage {
  [day: number]: boolean;
}

type Analysis =
  | { hasError: true }
  | { hasError: false; coverage: IBatchCoverage };

export const analyzeBatchCoverage = (
  batchSchedules: IPayoutConfigBatchScheduleResponse[]
): Analysis => {
  const coverage: IBatchCoverage = {};
  for (let i = 0; i < 31; i++) {
    coverage[i + 1] = false;
  }

  for (const batch of batchSchedules) {
    const { start_day, end_day, in_queue_day } = batch;

    if (
      !start_day ||
      !end_day ||
      !in_queue_day ||
      start_day < 1 ||
      end_day > 31
    ) {
      return { hasError: true };
    }

    for (let i = start_day; i <= end_day; i++) {
      // Don't shorthand this line as "if (coverage[i]) {"
      if (coverage[i] === true) {
        return { hasError: true };
      }

      coverage[i] = true;
    }
  }

  return { hasError: false, coverage };
};
