import { Injectable } from '@angular/core';

import { ModulesService } from '../modules/modules/services/modules.service';
import { ReferenceCategoryService } from '../modules/references/services';
import { CurrenciesService } from '../modules/setup/currencies/services/currencies.service';
import { PersonalizationService } from '../pages/personalization/services/personalization.service';

@Injectable({ providedIn: 'root' })
export class AfterLoginService {
  constructor(
    private personalizationService: PersonalizationService,
    private modulesService: ModulesService,
    private currenciesService: CurrenciesService,
    private referenceCategoryService: ReferenceCategoryService
  ) {}

  do() {
    this.personalizationService.getPersonalizationData().subscribe();
    this.referenceCategoryService.getAllReferenceCategories();
    this.modulesService.getAllModules().subscribe();
    this.currenciesService.getAllCurrencies().subscribe();
  }
}
