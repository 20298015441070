import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  CalendarDayViewBeforeRenderEvent,
  CalendarEvent,
  CalendarEventAction,
  CalendarMonthViewBeforeRenderEvent,
  CalendarView,
  CalendarWeekViewBeforeRenderEvent,
} from 'angular-calendar';
import { EventColor, ViewPeriod } from 'calendar-utils';
import { isSameDay, isSameMonth, setHours, setMinutes } from 'date-fns';
import * as moment from 'moment';
import { RRule, Weekday } from 'rrule';
import { forkJoin, Subject } from 'rxjs';

import { EVENT_TYPE, REPEAT_TYPE } from '@shared/constants';
import { IReminder, ITask } from '@shared/interfaces';

import {
  CONFIRMATION_TYPES,
  GetUserConfirmationService,
} from '../../modules/shared/services/get-user-confirmation/service';
import { DateTimeFormatService } from '../../services/date-time-format.service';
import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../services/snackbar.service';

import {
  DialogAddReminderTaskComponent,
  IAddDialogResponse,
} from './popups/add-reminder-task/add-reminder-task.component';
import { ViewReminderComponent } from './popups/view-reminder/view-reminder.component';
import { ViewTaskComponent } from './popups/view-task/view-task.component';
import { DiaryService } from './services/diary.service';

const colors: Record<string, EventColor> = {
  blue: {
    primary: '#3399ff',
    secondary: '#66b3ff',
  },
  green: {
    primary: '#5dd55d',
    secondary: '#85e085',
  },
};

interface RecurringEvent {
  _id?: string;
  title: string;
  color: any;
  rrule?: {
    freq: any;
    bymonth?: number;
    bymonthday?: number;
    byweekday?: any;
    dtstart?: Date;
    until?: Date;
  };
  meta?: any;
  actions?: CalendarEventAction[];
}

export enum ACTION {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
  REDIRECT = 'REDIRECT',
}
@Component({
  selector: 'app-diary',
  templateUrl: './diary.component.html',
  styleUrls: ['./diary.component.scss'],
})
export class DiaryComponent implements OnInit, DoCheck, OnDestroy {
  @ViewChild('modalContent', { read: true })
  modalContent: TemplateRef<CalendarView>;

  view: CalendarView;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  activeDayIsOpen = false;
  clickedDayEventsLength = 0;

  clickedDate = new Date();
  refresh: Subject<any> = new Subject();

  neverRepeatReminderAction: CalendarEventAction[] = [];
  reminderActions: CalendarEventAction[] = [];
  taskActions: CalendarEventAction[] = [];

  isLoading = false;
  isFullScreen = false;

  reminders: RecurringEvent[] = [];
  neverRepeatReminders: CalendarEvent[] = [];
  tasks: CalendarEvent[] = [];
  events: CalendarEvent[] = [];

  viewPeriod: ViewPeriod;

  weekElem: HTMLElement;
  dayElem: HTMLElement;
  isAtTop = true;

  constructor(
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private diaryService: DiaryService,
    private dateTimeFormatService: DateTimeFormatService,
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private getUserConfirmation: GetUserConfirmationService,
    @Inject(DOCUMENT) private document: any
  ) {}

  ngOnInit(): void {
    this.getRemindersAndTasks();
    this.setView(CalendarView.Month);
  }

  ngDoCheck(): void {
    const dayElements = document.getElementsByClassName('cal-week-view');
    this.dayElem = dayElements[0] as HTMLElement;
    if (this.dayElem) {
      this.dayElem.removeEventListener('blur', this.onScroll, false);
      this.dayElem.addEventListener('scroll', this.onScroll, false);
    }

    const weekElements = document.getElementsByClassName('cal-time-events');
    this.weekElem = weekElements[0] as HTMLElement;
    if (this.weekElem) {
      this.weekElem.removeEventListener('blur', this.onScroll, false);
      this.weekElem.addEventListener('scroll', this.onScroll, false);
    }
  }

  ngOnDestroy(): void {
    if (this.dayElem) {
      this.dayElem.removeEventListener('blur', this.onScroll, false);
    }

    if (this.weekElem) {
      this.weekElem.removeEventListener('blur', this.onScroll, false);
    }
  }

  onScroll(event: any) {
    const element = event.target as HTMLElement;
    if (element?.scrollTop === 0) {
      element.style.boxShadow = 'inset 0 -7px 9px -7px rgba(0,0,0,0.4)';
    } else if (
      element.scrollHeight - element.clientHeight - 3 >
      element.scrollTop
    ) {
      element.style.boxShadow =
        'inset 0 -7px 9px -7px rgba(0,0,0,0.4), inset 0 7px 9px -7px rgba(0,0,0,0.4)';
    } else {
      element.style.boxShadow = 'inset 0 7px 9px -7px rgba(0,0,0,0.4)';
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.clickedDate = date;
    this.clickedDayEventsLength = events.length;
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  handleEvent(action: ACTION, event: CalendarEvent, type: EVENT_TYPE): void {
    const reminderDialogData = {
      description: this.translate.instant('pages.diary.popups.delete-reminder'),
    };

    const taskDialogData = {
      description: this.translate.instant('pages.diary.popups.delete-task'),
    };

    if (action === ACTION.DELETE) {
      if (type === EVENT_TYPE.REMINDER) {
        this.activeDayIsOpen = false;
        this.getUserConfirmation
          .confirm(CONFIRMATION_TYPES.DELETE, reminderDialogData)
          .then((res) => {
            if (res) {
              this.diaryService.deleteReminder(event?.id as string).subscribe({
                next: () => {
                  this.events = this.events.filter(
                    (item) => item.id !== event.id
                  );
                  this.reminders = this.reminders.filter(
                    (reminder) => reminder._id !== event.id
                  );
                  this.neverRepeatReminders = this.neverRepeatReminders.filter(
                    (reminder) => reminder.id !== event.id
                  );
                  this.clickedDayEventsLength = this.clickedDayEventsLength - 1;
                  if (this.clickedDayEventsLength > 0)
                    this.activeDayIsOpen = true;
                  this.snackbar.success(
                    SUCCESS_TYPES.DELETED,
                    this.translate.instant('pages.diary.popups.reminder')
                  );
                },
                error: () => {
                  this.activeDayIsOpen = true;
                  this.snackbar.error(
                    this.translate.instant(
                      'pages.diary.error-messages.reminder-delete-failed'
                    )
                  );
                },
              });
            } else {
              this.activeDayIsOpen = true;
            }
          });
      } else {
        this.activeDayIsOpen = false;
        this.getUserConfirmation
          .confirm(CONFIRMATION_TYPES.DELETE, taskDialogData)
          .then((res) => {
            if (res) {
              this.diaryService
                .deleteTask(event?.meta?._id as string)
                .subscribe({
                  next: () => {
                    this.events = this.events.filter((item) => item !== event);
                    this.tasks = this.tasks.filter((task) => task !== event);
                    this.clickedDayEventsLength =
                      this.clickedDayEventsLength - 1;
                    if (this.clickedDayEventsLength > 0)
                      this.activeDayIsOpen = true;
                    this.snackbar.success(
                      SUCCESS_TYPES.DELETED,
                      this.translate.instant('pages.diary.popups.task')
                    );
                  },
                  error: () => {
                    this.activeDayIsOpen = true;
                    this.snackbar.error(
                      this.translate.instant(
                        'pages.diary.error-messages.task-delete-failed'
                      )
                    );
                  },
                });
            } else {
              this.activeDayIsOpen = true;
            }
          });
      }
    } else if (action === ACTION.EDIT) {
      let updateReminder = {};

      if (type === EVENT_TYPE.REMINDER) {
        updateReminder = {
          eventType: EVENT_TYPE.REMINDER,
          reminder: event?.meta,
        };
      } else {
        updateReminder = {
          eventType: EVENT_TYPE.TASK,
          task: event?.meta,
        };
      }

      const dialog = this.dialog.open(DialogAddReminderTaskComponent, {
        width: '60%',
        minWidth: '330px',
        maxWidth: '600px',
        data: updateReminder,
        disableClose: true,
      });

      dialog
        .afterClosed()
        .subscribe((result: IAddDialogResponse<IReminder | ITask>) => {
          if (result) {
            if (result.isReminder) {
              this.reminders.push(
                this.getReminderEvent(result.data as IReminder)
              );
              this.refreshDiary();
            } else {
              this.tasks.push(this.getTaskEvent(result.data as ITask));
              this.refreshDiary();
            }
          }
        });
    } else if (action === ACTION.VIEW) {
      this.activeDayIsOpen = false;
      if (type === EVENT_TYPE.REMINDER) {
        const reminder: IReminder = event.meta;
        this.dialog
          .open(ViewReminderComponent, {
            width: '90%',
            maxWidth: '490px',
            data: reminder,
          })
          .afterClosed()
          .subscribe(() => {
            this.activeDayIsOpen = true;
          });
      } else {
        const task: ITask = event.meta;
        this.dialog
          .open(ViewTaskComponent, {
            width: '90%',
            maxWidth: '490px',
            data: task,
          })
          .afterClosed()
          .subscribe(() => {
            this.activeDayIsOpen = true;
          });
      }
    } else {
      window.open(event?.meta?.redirectTo);
    }
  }

  refreshDiary() {
    this.getRemindersAndTasks();
    this.isLoading = true;
    this.activeDayIsOpen = false;
    this.view = CalendarView.Month;
    this.viewDate = new Date(new Date().getDate() - 1);
    setTimeout(() => {
      document.getElementById('today-button')?.click();
      this.isLoading = false;
    }, 500);
  }

  getRemindersAndTasks() {
    this.isLoading = true;
    this.events = [];

    forkJoin([
      this.diaryService.getAllReminders(),
      this.diaryService.getAllTasks(),
    ]).subscribe(([remRes, taskRes]) => {
      this.reminders = [];
      this.neverRepeatReminders = [];
      remRes?.data?.forEach((reminder) => {
        if (reminder.repeat === REPEAT_TYPE.NEVER) {
          if (reminder?.isReschedule || reminder?.isReassign) {
            // ignore this
          } else {
            this.neverRepeatReminders.push(
              this.getNeverRepeatReminderEvent(reminder)
            );
          }
        } else {
          this.reminders.push(this.getReminderEvent(reminder));
        }
      });

      this.tasks = [];

      taskRes.data.forEach((task) => {
        this.tasks.push(this.getTaskEvent(task));
      });

      this.isLoading = false;
    });
  }

  getNeverRepeatReminderEvent(reminder: IReminder) {
    const dateMoment = moment.utc(reminder.date).local();
    const timeMoment = moment.utc(reminder.time).local();

    const dateTimeMoment = moment({
      year: dateMoment.get('year'),
      month: dateMoment.get('month'),
      date: dateMoment.get('date'),
      hour: timeMoment.get('hour'),
      minute: timeMoment.get('minute'),
      second: timeMoment.get('second'),
    });

    this.neverRepeatReminderAction = [];

    this.neverRepeatReminderAction.push({
      label: `<span class="reminder-date">${this.dateTimeFormatService.formatDateTime(
        dateTimeMoment.clone().toDate()
      )}</span>`,
      a11yLabel: '',
      onClick: (): void => {
        // ignore
      },
    });

    if (reminder.redirectTo) {
      this.neverRepeatReminderAction.push({
        label: '<span class="material-symbols-outlined">link</span>',
        a11yLabel: 'Link',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.handleEvent(ACTION.REDIRECT, event, EVENT_TYPE.REMINDER);
        },
      });
    }

    if (reminder.link) {
      this.neverRepeatReminderAction.push({
        label: '<span class="material-symbols-outlined">visibility</span>',
        a11yLabel: 'View',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.handleEvent(ACTION.VIEW, event, EVENT_TYPE.REMINDER);
        },
      });
    }

    this.neverRepeatReminderAction.push({
      label: '<span class="material-symbols-outlined">delete</span>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent(ACTION.DELETE, event, EVENT_TYPE.REMINDER);
      },
    });

    this.neverRepeatReminderAction.push({
      label: '<span class="material-symbols-outlined">edit</span>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent(ACTION.EDIT, event, EVENT_TYPE.REMINDER);
      },
    });

    return {
      id: reminder._id.toString(),
      title: reminder.title,
      start: new Date(dateTimeMoment.clone().toDate()),
      actions: this.neverRepeatReminderAction,
      color: { ...colors.green },
      meta: reminder,
    };
  }

  getReminderEvent(reminder: IReminder) {
    const dateLocalMoment = moment.utc(reminder.date).local();
    const timeLocalMoment = moment.utc(reminder.time).local();

    const dateTimeLocalMoment = moment({
      year: dateLocalMoment.get('year'),
      month: dateLocalMoment.get('month'),
      date: dateLocalMoment.get('date'),
      hour: timeLocalMoment.get('hour'),
      minute: timeLocalMoment.get('minute'),
      second: timeLocalMoment.get('second'),
    });

    const dateMoment = moment.utc(reminder.date);
    const timeMoment = moment.utc(reminder.time);

    const dateTimeMoment = moment({
      year: dateMoment.get('year'),
      month: dateMoment.get('month'),
      date: dateMoment.get('date'),
      hour: timeMoment.get('hour'),
      minute: timeMoment.get('minute'),
      second: timeMoment.get('second'),
    });

    this.reminderActions = [];

    this.reminderActions.push({
      label: `<span class="reminder-date">${this.dateTimeFormatService.formatDateTime(
        dateTimeMoment.clone().toDate()
      )}</span>`,
      a11yLabel: '',
      onClick: (): void => {
        // ignore
      },
    });

    if (reminder.redirectTo) {
      this.reminderActions.push({
        label: '<span class="material-symbols-outlined">link</span>',
        a11yLabel: 'Link',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.handleEvent(ACTION.REDIRECT, event, EVENT_TYPE.REMINDER);
        },
      });
    }

    if (reminder.link) {
      this.reminderActions.push({
        label: '<span class="material-symbols-outlined">visibility</span>',
        a11yLabel: 'View',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.handleEvent(ACTION.VIEW, event, EVENT_TYPE.REMINDER);
        },
      });
    }

    this.reminderActions.push(
      {
        label: '<span class="material-symbols-outlined">edit</span>',
        a11yLabel: 'Edit',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.handleEvent(ACTION.EDIT, event, EVENT_TYPE.REMINDER);
        },
      },
      {
        label: '<span class="material-symbols-outlined">delete</span>',
        a11yLabel: 'Delete',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.handleEvent(ACTION.DELETE, event, EVENT_TYPE.REMINDER);
        },
      }
    );

    switch (reminder.repeat) {
      case REPEAT_TYPE.EVERY_DAY:
        return {
          id: reminder._id,
          title: reminder.title,
          rrule: {
            freq: RRule.DAILY,
            dtstart: dateTimeLocalMoment.clone().toDate(),
          },
          color: { ...colors.green },
          meta: reminder,
          actions: this.reminderActions,
        };
      case REPEAT_TYPE.EVERY_WEEK:
        return {
          id: reminder._id,
          title: reminder.title,
          rrule: {
            freq: RRule.WEEKLY,
            byweekday: [new Weekday(dateTimeMoment.clone().weekday())],
            dtstart: dateTimeMoment.clone().toDate(),
          },
          color: { ...colors.green },
          meta: reminder,
          actions: this.reminderActions,
        };
      case REPEAT_TYPE.EVERY_MONTH:
        return {
          id: reminder._id,
          title: reminder.title,
          rrule: {
            freq: RRule.MONTHLY,
            bymonthday: dateTimeMoment.clone().date() + 1,
            dtstart: dateTimeMoment.clone().toDate(),
          },
          color: { ...colors.green },
          meta: reminder,
          actions: this.reminderActions,
        };
      case REPEAT_TYPE.EVERY_YEAR:
        return {
          id: reminder._id,
          title: reminder.title,
          rrule: {
            freq: RRule.YEARLY,
            bymonth: dateTimeMoment.clone().month() + 1,
            bymonthday: dateTimeMoment.clone().date() + 1,
            dtstart: dateTimeMoment.clone().toDate(),
          },
          color: { ...colors.green },
          meta: reminder,
          actions: this.reminderActions,
        };

      default:
        return null;
    }
  }

  updateCalendarEvents(
    viewRender:
      | CalendarMonthViewBeforeRenderEvent
      | CalendarWeekViewBeforeRenderEvent
      | CalendarDayViewBeforeRenderEvent
  ): void {
    if (
      !this.viewPeriod ||
      !moment(this.viewPeriod.start).isSame(viewRender.period.start) ||
      !moment(this.viewPeriod.end).isSame(viewRender.period.end)
    ) {
      this.viewPeriod = viewRender.period;

      this.events = [];
      this.reminders.forEach((reminder: any) => {
        //set type to any (For some reason there are 2 types of IDs id & _id)
        const rule: RRule = new RRule({
          dtstart: moment(viewRender.period.start).startOf('day').toDate(),
          until: moment(viewRender.period.end).endOf('day').toDate(),
          ...reminder.rrule,
        });
        const { id: reminder_id, title, color, meta, actions } = reminder;

        rule.all().forEach((date) => {
          this.events.push({
            id: reminder_id.toString(),
            title,
            color,
            meta,
            actions,
            start: moment(date).toDate(),
          });
        });
      });

      this.tasks.forEach((task) => {
        this.events.push(task);
      });

      this.neverRepeatReminders.forEach((reminder) => {
        this.events.push(reminder);
      });

      this.cdr.detectChanges();
    }
  }

  getTaskEvent(task: ITask) {
    const startDateMoment = moment.utc(task.start_date).local();
    const startTimeMoment = moment.utc(task.start_time).local();
    const endDateMoment = moment.utc(task.end_date).local();
    const endTimeMoment = moment.utc(task.end_time).local();

    const startDateTimeMoment = moment({
      year: startDateMoment.get('year'),
      month: startDateMoment.get('month'),
      date: startDateMoment.get('date'),
      hour: startTimeMoment.get('hour'),
      minute: startTimeMoment.get('minute'),
      second: startTimeMoment.get('second'),
    });

    const endDateTimeMoment = moment({
      year: endDateMoment.get('year'),
      month: endDateMoment.get('month'),
      date: endDateMoment.get('date'),
      hour: endTimeMoment.get('hour'),
      minute: endTimeMoment.get('minute'),
      second: endTimeMoment.get('second'),
    });

    this.taskActions = [];

    if (task.all_day) {
      this.taskActions.push({
        label: `<span class="reminder-date">${this.dateTimeFormatService.formatDateWithoutTime(
          startDateTimeMoment.clone().toDate()
        )} - ${this.dateTimeFormatService.formatDateWithoutTime(
          endDateTimeMoment.clone().toDate()
        )}</span>`,
        a11yLabel: '',
        onClick: (): void => {
          // ignore
        },
      });
    } else {
      this.taskActions.push({
        label: `<span class="reminder-date">${this.dateTimeFormatService.formatDateTime(
          startDateTimeMoment.clone().toDate()
        )} - ${this.dateTimeFormatService.formatDateTime(
          endDateTimeMoment.clone().toDate()
        )}</span>`,
        a11yLabel: '',
        onClick: (): void => {
          // ignore
        },
      });
    }

    if (task.redirectTo) {
      this.taskActions.push({
        label: '<span class="material-symbols-outlined">link</span>',
        a11yLabel: 'Link',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.handleEvent(ACTION.REDIRECT, event, EVENT_TYPE.TASK);
        },
      });
    }

    this.taskActions.push(
      {
        label: '<span class="material-symbols-outlined">visibility</span>',
        a11yLabel: 'View',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.handleEvent(ACTION.VIEW, event, EVENT_TYPE.TASK);
        },
      },
      {
        label: '<span class="material-symbols-outlined">edit</span>',
        a11yLabel: 'Edit',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.handleEvent(ACTION.EDIT, event, EVENT_TYPE.TASK);
        },
      },
      {
        label: '<span class="material-symbols-outlined">delete</span>',
        a11yLabel: 'Delete',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.handleEvent(ACTION.DELETE, event, EVENT_TYPE.TASK);
        },
      }
    );

    if (task.all_day) {
      if (
        moment(task.start_date).format('YYYY-MM-DD') ===
        moment(task.end_date).format('YYYY-MM-DD')
      ) {
        return {
          title: task.title,
          start: new Date(task.start_date),
          allDay: true,
          actions: this.taskActions,
          meta: task,
        };
      } else {
        return {
          title: task.title,
          start: new Date(task.start_date),
          end: new Date(task.end_date),
          allDay: true,
          actions: this.taskActions,
          meta: task,
        };
      }
    } else {
      return {
        title: task.title,
        start: setHours(
          setMinutes(
            new Date(task.start_date),
            new Date(task.start_time).getMinutes()
          ),
          new Date(task.start_time).getHours()
        ),
        end: setHours(
          setMinutes(
            new Date(task.end_date),
            new Date(task.end_time).getMinutes()
          ),
          new Date(task.end_time).getHours()
        ),
        actions: this.taskActions,
        meta: task,
      };
    }
  }

  openFullscreen(card: string) {
    const elem = document.getElementById(card);
    elem.requestFullscreen();
  }

  onItemDoubleClick() {
    this.addReminderOrTask(this.clickedDate);
  }

  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    }
  }

  addReminderOrTask(clickedDate = null) {
    if (this.isFullScreen) {
      this.closeFullscreen();
      const dialog = this.dialog.open(DialogAddReminderTaskComponent, {
        width: '60%',
        minWidth: '330px',
        maxWidth: '600px',
        disableClose: true,
        data: {
          eventType: null,
          date: clickedDate,
          start_date: clickedDate,
          end_date: clickedDate,
        },
      });

      dialog
        .afterClosed()
        .subscribe((result: IAddDialogResponse<IReminder | ITask>) => {
          if (result) {
            if (result.isReminder) {
              this.reminders.push(
                this.getReminderEvent(result.data as IReminder)
              );
              this.refreshDiary();
            } else {
              this.tasks.push(this.getTaskEvent(result.data as ITask));
              this.refreshDiary();
            }
          }
          this.openFullscreen('card');
        });
    } else {
      const dialog = this.dialog.open(DialogAddReminderTaskComponent, {
        width: '60%',
        minWidth: '330px',
        maxWidth: '600px',
        disableClose: true,
        data: {
          eventType: null,
          date: clickedDate,
          start_date: clickedDate,
          end_date: clickedDate,
        },
      });

      dialog
        .afterClosed()
        .subscribe((result: IAddDialogResponse<IReminder | ITask>) => {
          if (result) {
            if (result.isReminder) {
              this.reminders.push(
                this.getReminderEvent(result.data as IReminder)
              );
              this.refreshDiary();
            } else {
              this.tasks.push(this.getTaskEvent(result.data as ITask));
              this.refreshDiary();
            }
          }
        });
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  toggleFullscreen() {
    this.isFullScreen = !this.isFullScreen;
  }
}
