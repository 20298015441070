import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Types } from 'mongoose';
import { BehaviorSubject, firstValueFrom, Observable, tap } from 'rxjs';

import { ENDPOINTS } from '@shared/constants';
import {
  CommonResponseDTO,
  ITargetConfigRequest,
  ITargetConfigResponse,
  ITargetResponse,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

interface TargetSheetConfigsResponse {
  data: ITargetConfigResponse[];
  message: string;
  success: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class TargetService {
  private _configurations = new BehaviorSubject<ITargetConfigResponse[]>([]);
  private _calculatedTargets = new BehaviorSubject<{
    [key: string]: ITargetResponse;
  }>({});

  constructor(private http: HttpClient) {}

  public async getTargetSheetConfigs(): Promise<ITargetConfigResponse[]> {
    const loadedConfigurations = this._configurations.value;
    if (loadedConfigurations?.length) return loadedConfigurations;

    const url = generateURL({ endpoint: ENDPOINTS.TARGETS_CONFIG_GET_ALL });
    const { data } = await firstValueFrom(
      this.http.get<TargetSheetConfigsResponse>(url)
    );

    this._configurations.next(data);
    return data;
  }

  public setTargetSheetConfigs(
    fields: ITargetConfigRequest[]
  ): Observable<TargetSheetConfigsResponse> {
    const url = generateURL({ endpoint: ENDPOINTS.TARGETS_CONFIG_UPDATE });

    return this.http.post<TargetSheetConfigsResponse>(url, { fields }).pipe(
      tap((response) => {
        this._configurations.next(response.data);
      })
    );
  }

  public getAllTargets(
    params: HttpParams
  ): Observable<CommonResponseDTO<ITargetResponse[]>> {
    const url = generateURL({ endpoint: ENDPOINTS.TARGETS_GET_ALL });
    const config = { params };

    return this.http
      .get<CommonResponseDTO<ITargetResponse[]>>(url, config)
      .pipe(tap(() => this._calculatedTargets.next({})));
  }

  async getTarget(
    year: number,
    month: string,
    agent_id?: string | Types.ObjectId
  ): Promise<ITargetResponse> {
    const key = `${year}-${month}-${agent_id}`;
    const calculatedTargets = this._calculatedTargets.getValue();

    if (calculatedTargets[key]) return calculatedTargets[key];

    const url = generateURL({
      endpoint: ENDPOINTS.TARGETS_GET_ONE,
      params: { year, month },
    });
    let params = new HttpParams();
    if (agent_id) {
      params = params.append('agent_id', agent_id.toString());
    }

    const response = await firstValueFrom(
      this.http.get<CommonResponseDTO<ITargetResponse>>(url, { params })
    );
    calculatedTargets[key] = response.data;
    this._calculatedTargets.next(calculatedTargets);

    return response.data;
  }

  public deleteTarget(id: string) {
    const url = generateURL({
      endpoint: ENDPOINTS.TARGETS_DELETE_ONE,
      params: { id },
    });

    return this.http.delete<CommonResponseDTO<ITargetResponse>>(url);
  }

  public archiveTarget(id: string) {
    const url = generateURL({
      endpoint: ENDPOINTS.TARGETS_ARCHIVE_ONE,
      params: { id },
    });

    return this.http.patch<CommonResponseDTO<ITargetResponse>>(url, null);
  }

  public createTarget(
    target: ITargetResponse
  ): Observable<CommonResponseDTO<ITargetResponse>> {
    const url = generateURL({ endpoint: ENDPOINTS.TARGETS_ADD_NEW });

    return this.http.post<CommonResponseDTO<ITargetResponse>>(url, target);
  }
}
