<mat-card-title mat-dialog-title class="d-flex align-items-center mb-0"
  ><mat-icon>filter_alt</mat-icon>
  {{ 'configurable-fields.formula-builder.define-target.title' | translate }}
</mat-card-title>
<mat-card-subtitle>{{
  'configurable-fields.formula-builder.define-target.sub-title' | translate
}}</mat-card-subtitle>
<mat-divider></mat-divider>
<mat-dialog-content class="main-container">
  <div class="row header-row">
    <h6>
      {{
        'configurable-fields.formula-builder.define-target.parameters'
          | translate
      }}
    </h6>
  </div>
  <form class="container">
    <div *ngFor="let field of fields; let i = index" class="row filter-row">
      <mat-form-field appearance="fill" class="filter-element">
        <mat-label>
          {{
            'configurable-fields.formula-builder.define-target.parameter-name'
              | translate
          }}
        </mat-label>
        <input
          class="disabled-input"
          matInput
          [formControl]="field.parameterName"
          readonly
        />
        <mat-error>{{
          'configurable-fields.formula-builder.define-target.name-required'
            | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="filter-element">
        <mat-label>
          {{
            'configurable-fields.formula-builder.define-target.assignment-type'
              | translate
          }}</mat-label
        >
        <mat-select
          placeholder="Compare With"
          required
          [formControl]="field.assignmentType"
          [disabled]="
            !field.parameterName.value || field.disable.value === true
          "
          (valueChange)="onChangeCompareWithField(i)"
        >
          <mat-option [value]="ASSIGNMENT_TYPES.LOCAL">
            {{
              'configurable-fields.formula-builder.define-target.local-field'
                | translate
            }}
          </mat-option>
          <mat-option [value]="ASSIGNMENT_TYPES.DIRECT">
            {{
              'configurable-fields.formula-builder.define-target.direct'
                | translate
            }}
          </mat-option>
          <mat-option [value]="ASSIGNMENT_TYPES.RUNTIME">
            {{
              'configurable-fields.formula-builder.define-target.runtime'
                | translate
            }}
          </mat-option>
          <mat-option [value]="ASSIGNMENT_TYPES.PREDEFINED_DYNAMIC" disabled>
            {{
              'configurable-fields.formula-builder.define-target.predefined'
                | translate
            }}
          </mat-option>
          <mat-option [value]="ASSIGNMENT_TYPES.PREDEFINED_STATIC" disabled>
            {{
              'configurable-fields.formula-builder.define-target.predefined'
                | translate
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        *ngIf="field.assignmentType.value === ASSIGNMENT_TYPES.LOCAL"
        appearance="fill"
        class="filter-element"
      >
        <mat-label>
          {{
            'configurable-fields.formula-builder.define-target.assignment-value'
              | translate
          }}</mat-label
        >
        <mat-select
          required
          [formControl]="field.assignmentValue"
          [disabled]="!field.parameterName.value"
        >
          <mat-option
            *ngFor="let field of localReference?.fields || []"
            [value]="field._id"
          >
            {{ field.name }}
          </mat-option>
        </mat-select>
        <mat-error>{{
          'configurable-fields.formula-builder.define-target.value-required'
            | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field
        *ngIf="field.assignmentType.value === ASSIGNMENT_TYPES.DIRECT"
        appearance="fill"
        class="filter-element"
      >
        <mat-label>
          {{
            'configurable-fields.formula-builder.define-target.assignment-value'
              | translate
          }}
        </mat-label>
        <input
          matInput
          [formControl]="field.assignmentValue"
          required
          [readonly]="field.disable.value === true"
          [ngClass]="{
            'disabled-input': field.disable.value === true
          }"
        />
        <mat-error>{{
          'configurable-fields.formula-builder.define-target.value-required'
            | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field
        *ngIf="field.assignmentType.value === ASSIGNMENT_TYPES.RUNTIME"
        appearance="fill"
        class="filter-element"
      >
        <mat-label>
          {{
            'configurable-fields.formula-builder.define-target.assignment-value'
              | translate
          }}</mat-label
        >
        <mat-select
          required
          [formControl]="field.assignmentValue"
          [disabled]="!field.parameterName.value"
        >
          <mat-option
            *ngFor="let field of runtimeCollectableFields || []"
            [value]="field.value"
          >
            {{ field.key }}
          </mat-option>
        </mat-select>
        <mat-error>{{
          'configurable-fields.formula-builder.define-target.value-required'
            | translate
        }}</mat-error>
      </mat-form-field>

      <div
        *ngIf="
          field.assignmentType.value === ASSIGNMENT_TYPES.PREDEFINED_DYNAMIC ||
          field.assignmentType.value === ASSIGNMENT_TYPES.PREDEFINED_STATIC
        "
        class="filter-element predefined-hint"
      >
        {{ field.predefinedValeHint.value }}
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    [mat-dialog-close]="false"
    [matTooltip]="
      'configurable-fields.formula-builder.define-target.close' | translate
    "
  >
    {{ 'configurable-fields.formula-builder.define-target.close' | translate }}
  </button>
  <button
    mat-raised-button
    cdkFocusInitial
    color="primary"
    [matTooltip]="
      'configurable-fields.formula-builder.define-target.save' | translate
    "
    [disabled]="isAllValid()"
    (click)="save()"
  >
    {{ 'configurable-fields.formula-builder.define-target.save' | translate }}
  </button>
</mat-dialog-actions>
