import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
export interface IFlowControl {
  lastVisitedRoute: {
    route: string;
    data?: {
      title?: string;
      goBackEnabled?: boolean;
      searchBarEnabled?: boolean;
    };
  };
}

@Injectable({ providedIn: 'root' })
export class FlowControlService {
  public readonly dataStore = new BehaviorSubject<IFlowControl>(undefined);
  public readonly scrollToTop = new Subject<void>();

  updateRouteInfo(routeName: string, data: any) {
    const newFlowData: IFlowControl = {
      ...this.dataStore.getValue(),
      lastVisitedRoute: {
        route: routeName,
        data,
      },
    };
    this.dataStore.next(newFlowData);
  }
}
