<div *ngIf="loading" class="loading-overlay">
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="contents">
  <div class="row">
    <div class="col-2 d-flex align-items-center">
      <div class="file-logo">
        <img
          [src]="client_information?.logo_path_light"
          width="auto"
          height="auto"
          [alt]="client_information?.website"
        />
      </div>
    </div>
    <div class="col-10">
      <div class="row d-flex justify-content-start">
        <p class="company-name">
          {{ client_information?.name?.toUpperCase() }}
        </p>
      </div>
      <div class="row">
        <mat-icon>mail</mat-icon>
        <p class="mx-2">
          {{ client_information?.address }}
        </p>
        <mat-icon class="ml-1">phone</mat-icon>
        <p class="mx-2">
          {{
            client_information?.phone_number?.dialCode +
              client_information?.phone_number?.number
          }}
        </p>
        <mat-icon>alternate_email</mat-icon>
        <p class="mx-2">
          {{ client_information?.email }}
        </p>
      </div>
    </div>
  </div>

  <div style="font-size: 13px; overflow-x: hidden">
    <div class="title-content">
      <h5 class="title">
        {{ 'payouts.payslip.title' | translate }}
        {{ payoutResponse.commonData.month.toUpperCase() }}
        {{ payoutResponse.commonData.year }}
      </h5>
    </div>

    <div class="mt-5" class="info">
      <div>
        <div class="d-flex">
          <span class="data-heading"
            >{{ 'payouts.payslip.agent-code' | translate }}:</span
          >
          <span class="data-value ml-1">{{
            agent_information?.agent_code ?? '-'
          }}</span>
        </div>
        <div class="d-flex mt-2">
          <span class="data-heading"
            >{{ 'payouts.payslip.agent-name' | translate }}:</span
          >
          <span class="data-value ml-1">{{
            agent_information?.agent_name ?? '-'
          }}</span>
        </div>
      </div>
      <div>
        <div class="d-flex">
          <span class="data-heading"
            >{{ 'payouts.payslip.agent-designation' | translate }}:</span
          >
          <span class="data-value ml-1">{{
            agent_information?.designation ?? '-'
          }}</span>
        </div>
        <div class="d-flex mt-2">
          <span class="data-heading"
            >{{ 'payouts.payslip.employment-type' | translate }}:</span
          >
          <span class="data-value ml-1">{{
            agent_information?.employment_type ?? '-'
          }}</span>
        </div>
      </div>
      <div>
        <div class="d-flex">
          <span class="data-heading"
            >{{ 'payouts.payslip.date' | translate }}:</span
          >
          <span class="data-value ml-1">{{ printed_date ?? '-' }}</span>
        </div>
      </div>
    </div>

    <div class="page mt-3" *ngFor="let payout of payoutResponse.transactions">
      <div
        class="row mb-4 pt-2 justify-content-between"
        style="margin-top: 50px"
      >
        <div class="batch-no p-3">
          <mat-icon [style.color]="getColor(payout.status)"
            >check_circle</mat-icon
          >
          <span class="ml-3">
            {{ getBatchAndDateRange(payout) }}
          </span>
        </div>
        <div class="batch-no p-3">
          <span class="mr-2" [style.color]="getColor(payout.status)">
            {{ getStatus(payout.status) }}
          </span>
        </div>
      </div>
      <ng-container *ngIf="checkStatus(payout.status)">
        <div class="row mt-3">
          <div class="col-6 pr-0">
            <div class="table-heading">
              <div class="heading py-1">
                <span class="ml-4">
                  {{ 'payouts.payslip.earnings' | translate }}
                </span>
              </div>
              <div class="heading-right py-1">
                <span class="mr-4">
                  {{ 'payouts.payslip.amounts' | translate }}({{
                    currencyCode
                  }})
                </span>
              </div>
            </div>
            <div
              *ngFor="let earning of payout?.earnings"
              class="row table-data my-1 py-1"
            >
              <div class="col-6 name ml-4">{{ earning?.key }}</div>
              <div class="col-6 value mr-4">
                {{ getValue(earning.value.value.toString()) }}
              </div>
            </div>
          </div>
          <div class="col-6 pl-0">
            <div class="table-heading">
              <div class="heading py-1">
                <span class="ml-4">{{
                  'payouts.payslip.deductions' | translate
                }}</span>
              </div>

              <div class="heading-right py-1">
                <span class="mr-4"
                  >{{ 'payouts.payslip.amounts' | translate }} ({{
                    currencyCode
                  }})</span
                >
              </div>
            </div>
            <div
              *ngIf="payout?.carried_forward"
              class="row table-data my-1 py-1"
            >
              <div class="col-6 name ml-4">Carried Forward</div>
              <div class="col-6 value mr-4">
                {{ getValue(payout.carried_forward) }}
              </div>
            </div>

            <div
              *ngFor="let deduction of payout?.deductions"
              class="row table-data my-1 py-1"
            >
              <div class="col-6 name ml-4">
                {{ deduction.deduction_config.description }}
              </div>
              <div class="col-6 value mr-4">
                {{ getValue(deduction.value) }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 pr-0">
            <div class="table-heading">
              <div class="heading py-1">
                <span class="ml-4"
                  >{{ 'payouts.payslip.gross-earnings' | translate }}
                </span>
              </div>
              <div class="heading-right py-1">
                <span class="mr-4">{{ getValue(payout?.total_earnings) }}</span>
              </div>
            </div>
          </div>

          <div class="col-6 pl-0">
            <div class="table-heading">
              <div class="heading py-1">
                <span class="ml-4">{{
                  'payouts.payslip.deductions-simple' | translate
                }}</span>
              </div>
              <div class="heading-right py-1">
                <span class="mr-4">
                  {{ getValue(payout?.total_deductions) }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="net-payable py-1" [style.background-color]="headingColor">
          <span>{{ 'payouts.payslip.net-payable-amount' | translate }}:</span>
          <span class="ml-1">
            {{ getValue(payout?.net_payable_amount, true) }}</span
          >
        </div>
      </ng-container>
    </div>
    <div
      class="net-payable py-1 mt-5"
      [style.background-color]="headingColor"
      *ngIf="!checkAllStatus(payoutResponse)"
    >
      <span>{{ 'payouts.payslip.monthly-gross-earnings' | translate }}:</span>
      <span class="ml-1">
        {{ getValue(payoutResponse?.monthly_gross_earnings) }}</span
      >
    </div>
    <div
      class="net-payable py-1 mt-3"
      [style.background-color]="headingColor"
      *ngIf="!checkAllStatus(payoutResponse)"
    >
      <span>{{
        'payouts.payslip.monthly-net-payable-amount' | translate
      }}</span>
      <span class="ml-1">
        {{ getValue(payoutResponse?.monthly_net_payable, true) }}</span
      >
    </div>

    <div class="equation py-1 mt-5">
      <span>
        {{ 'payouts.payslip.net-payable-amount-equation' | translate }}
      </span>
    </div>
  </div>
</div>

<div class="footer">
  <span>{{ 'payouts.payslip.computer-generated-message' | translate }}</span>
</div>
