import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';
import { ElAutocompleteModule } from '@el-autocomplete';
import { ElDataListTableModule } from '@el-data-list-table';
import { MtxNativeDatetimeModule } from '@ng-matero/extensions/core';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '../../material.module';
import { SharedModule } from '../shared/shared.module';

import { ConfigureConfigurableFieldComponent } from './components/configure-configurable-field/configure-configurable-field.component';
import { DisplayConfigurableFieldComponent } from './components/display-configurable-field/display-configurable-field.component';
import { DisplaySingleConfigurableFieldComponent } from './components/display-single-configurable-field/display-single-configurable-field.component';
import { ELMultiFieldComponent } from './components/el-multi-field/el-multi-field.component';
import { FormulaBuilderComponent } from './components/formula-builder/formula-builder.component';
import { DialogContentDefineCustomVariableComponent } from './components/formula-builder/popups/define-custom-variable/define-custom-variable.component';
import { DialogContentDefineFileFiltersComponent } from './components/formula-builder/popups/define-file-filters/define-file-filters-popup.component';
import { DialogContentDefineIntegrationComponent } from './components/formula-builder/popups/define-integration/define-integration-popup.component';
import { DialogContentDefineProductComponent } from './components/formula-builder/popups/define-product/define-product-popup.component';
import { DialogContentDefineReferenceFiltersComponent } from './components/formula-builder/popups/define-reference-filters/define-reference-filters-popup.component';
import { DialogContentDefineTargetComponent } from './components/formula-builder/popups/define-target/define-target-popup.component';
import { SetAddressesPopupComponent } from './popups/set-addresses/set-addresses.popup.component';
import { SetContactNumbersPopupComponent } from './popups/set-contact-numbers/set-contact-numbers.popup.component';
import { SetContactEmailsPopupComponent } from './popups/set-emails/set-emails.popup.component';
import { SetNamePopupComponent } from './popups/set-name/set-name.popup.component';

@NgModule({
  declarations: [
    ConfigureConfigurableFieldComponent,
    DisplayConfigurableFieldComponent,
    DisplaySingleConfigurableFieldComponent,
    FormulaBuilderComponent,
    ELMultiFieldComponent,
    SetAddressesPopupComponent,
    SetNamePopupComponent,
    SetContactNumbersPopupComponent,
    SetContactEmailsPopupComponent,
    DialogContentDefineCustomVariableComponent,
    DialogContentDefineReferenceFiltersComponent,
    DialogContentDefineFileFiltersComponent,
    DialogContentDefineIntegrationComponent,
    DialogContentDefineProductComponent,
    DialogContentDefineTargetComponent,
  ],
  exports: [
    FormulaBuilderComponent,
    ConfigureConfigurableFieldComponent,
    DisplayConfigurableFieldComponent,
    ELMultiFieldComponent,
    DisplaySingleConfigurableFieldComponent,
  ],
  imports: [
    CommonModule,
    CKEditorModule,
    ElDataListTableModule,
    MaterialModule,
    SharedModule,
    EcoFabSpeedDialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ElAutocompleteModule,
    MtxNativeDatetimeModule,
    MtxDatetimepickerModule,
  ],
})
export class ConfigurableFieldsModule {}
