import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  GridstackComponent,
  NgCompInputs,
  NgGridStackOptions,
  // nodesCB,
} from 'gridstack/dist/angular';
import { GridStackNode, GridStackWidget } from 'gridstack';

import { IWidget, IWidgetUserLayout } from '@shared/interfaces';

import { BarChartComponent } from '../widgets/bar-chart.component/bar-chart.component';
import { CountWidgetComponent } from '../widgets/count-widget/count-widget.component';
import { DonutWidgetComponent } from '../widgets/donut-widget/donut-widget.component';
import { GroupBarChartComponent } from '../widgets/group-bar-chart/group-bar-chart.component';
import { LineChartComponent } from '../widgets/line-chart/line-chart.component';
import { NumberCardWidgetComponent } from '../widgets/number-card-widget/number-card-widget.component';
import { ProgressBarWidgetComponent } from '../widgets/progress-bar-widget/progress-bar-widget.component';

// created a custom interface to get the inputs(widget) when doing changes in the dynamic container
interface nodesInput extends GridStackNode {
  input?: NgCompInputs;
}

@Component({
  selector: 'app-dynamic-container',
  templateUrl: './dynamic-container.component.html',
  styleUrls: ['./dynamic-container.component.scss'],
})
export class DynamicContainerComponent implements OnChanges {
  @Input() widgets: IWidget[];
  @Input() isDisabled: boolean;
  @Input() layout: IWidgetUserLayout;
  @Output() updatedLayout: EventEmitter<IWidgetUserLayout> = new EventEmitter();

  @ViewChild('gridContainer', { static: false }) gridContainerRef: ElementRef;
  @ViewChild(GridstackComponent) gridComp?: GridstackComponent;

  constructor() {
    // should add all the widgets here
    GridstackComponent.addComponentToSelectorType([
      CountWidgetComponent,
      LineChartComponent,
      BarChartComponent,
      DonutWidgetComponent,
      NumberCardWidgetComponent,
      GroupBarChartComponent,
      ProgressBarWidgetComponent,
    ]);
  }

  gridOptions: NgGridStackOptions = {
    auto: false,
    margin: 5,
    disableResize: true,
    cellHeight: 7,
    children: [],
    cellHeightUnit: 'rem',
    disableOneColumnMode: true,
  };

  ngOnChanges(changes: SimpleChanges): void {
    this.gridComp?.grid.batchUpdate(true);
    if (changes.layout?.currentValue) {
      this.updateGripOptions(changes.layout?.currentValue);
      if (changes.layout.firstChange) {
        setTimeout(() => {
          this.updateGripOptions(changes.layout?.currentValue);
        }, 100);
      }
    }
    this.gridComp?.grid.batchUpdate(false);
  }

  updateGripOptions(layout: IWidgetUserLayout) {
    this.gridComp?.updateAll();
    const newLayout: GridStackWidget[] = layout.data?.map((item) => {
      return {
        selector: item.widget.type,
        x: item.position.x > -1 ? item.position.x : undefined,
        y: item.position.y > -1 ? item.position.y : undefined,
        h: item.layout.h,
        w: item.layout.w,
        autoPosition: item.position.x === -1 && item.position.y === -1,
        noResize: true,
        input: {
          widget: item.widget,
          closable: true,
          clickable: false,
        },
        id: item._id?.toString() ?? Date.now().toString(),
      };
    });
    this.gridComp?.grid.load(newLayout);
  }

  onChange(): void {
    const nodes: nodesInput[] = this.gridComp.grid.engine.nodes;
    nodes.forEach((node) => {
      const foundItemIndex = this.layout.data.findIndex(
        (item) => item.widget._id === node.input.widget._id
      );
      if (foundItemIndex > -1) {
        this.layout.data[foundItemIndex].position.x = node.x;
        this.layout.data[foundItemIndex].position.y = node.y;
      }
    });

    this.updatedLayout.emit(this.layout);
  }
}
