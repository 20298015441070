import { DecimalPipe } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';

import { thousandSeparatorAdd, thousandSeparatorRemove } from '@shared/utils';

@Directive({
  selector: '[commaSeparatedWithTwoDecimals]',
  providers: [DecimalPipe],
})
export class CommaSeparatedWithTwoDecimalDirective
  implements OnInit, OnDestroy
{
  inputElement: HTMLInputElement;
  private onDestroy$ = new Subject<void>();
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];
  forecastValue: any;

  constructor(private el: ElementRef, private model: NgControl) {
    this.inputElement = el.nativeElement;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.updateValue();
    });

    this.model.control.valueChanges
      .pipe(debounceTime(100), takeUntil(this.onDestroy$))
      .subscribe((newValue) => {
        if (newValue !== null && newValue !== undefined) this.updateValue();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): any {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      ((e.key === 'a' || e.code === 'KeyA') && e.ctrlKey === true) || // Allow: Ctrl+A
      ((e.key === 'c' || e.code === 'KeyC') && e.ctrlKey === true) || // Allow: Ctrl+C
      ((e.key === 'v' || e.code === 'KeyV') && e.ctrlKey === true) || // Allow: Ctrl+V
      ((e.key === 'x' || e.code === 'KeyX') && e.ctrlKey === true) || // Allow: Ctrl+X
      ((e.key === 'a' || e.code === 'KeyA') && e.metaKey === true) || // Allow: Cmd+A (Mac)
      ((e.key === 'c' || e.code === 'KeyC') && e.metaKey === true) || // Allow: Cmd+C (Mac)
      ((e.key === 'v' || e.code === 'KeyV') && e.metaKey === true) || // Allow: Cmd+V (Mac)
      ((e.key === 'x' || e.code === 'KeyX') && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }
  }

  private setInputValue(value: string) {
    const numericValue = thousandSeparatorRemove(value);
    if (numericValue !== this.model.control.value)
      this.model.control.setValue(isNaN(numericValue) ? null : numericValue);

    this.el.nativeElement.dataset.previousValue = value;
    this.el.nativeElement.value = value;

    const event = new Event('change');
    this.el.nativeElement.dispatchEvent(event);
  }

  private updateValue() {
    const inputValue: string = (this.el.nativeElement.value ?? '')
      .toString()
      .replace(/,/g, '')
      .trim();

    if (!inputValue.length) {
      this.setInputValue('');
      return;
    }

    const decimalIndex = inputValue.indexOf('.');
    let integerPart = inputValue.slice(
      0,
      decimalIndex !== -1 ? decimalIndex : inputValue.length
    );
    const decimalPart = inputValue.slice(
      decimalIndex !== -1 ? decimalIndex + 1 : inputValue.length
    );

    if (isNaN(Number(integerPart)) || isNaN(Number(decimalPart))) {
      this.setInputValue(this.el.nativeElement.dataset.previousValue);
      return;
    }

    // Remove leading zeros if it's followed by other digits
    // apply thousand separation
    integerPart = thousandSeparatorAdd(Number(integerPart));

    // Restrict decimal part to two digits
    const restrictedDecimalPart = decimalPart.slice(0, 2);

    // Update the input value
    this.setInputValue(
      decimalIndex !== -1
        ? `${integerPart}.${restrictedDecimalPart}`
        : integerPart
    );
  }

  @HostListener('input', ['$event'])
  onInput(): void {
    this.updateValue();
  }
}
