import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

import { ENDPOINTS, MODULES } from '@shared/constants';
import {
  CommonResponseDTO,
  IModuleRequest,
  IModuleResponse,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class ModulesService {
  private readonly _dataStore = new BehaviorSubject<IModuleResponse[]>([]);
  public dataStore = this._dataStore.asObservable();

  constructor(private http: HttpClient) {}

  getAllModules(
    params?: HttpParams
  ): Observable<CommonResponseDTO<IModuleResponse[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.MODULES_GET_ALL,
    });
    return this.http
      .get<CommonResponseDTO<IModuleResponse[]>>(url, { params })
      .pipe(
        tap((res) => {
          const loadedModules = this._dataStore.value;
          (res.data ?? []).forEach((module) => {
            const moduleIndex = loadedModules.findIndex(
              (loadedModule) => loadedModule.key === module.key
            );

            if (moduleIndex > -1) {
              loadedModules[moduleIndex] = module;
            } else {
              loadedModules.push(module);
            }
          });

          this._dataStore.next(loadedModules);
        })
      );
  }

  async getModuleByKey(key: MODULES): Promise<IModuleResponse | undefined> {
    const loadedModules = this._dataStore.value;
    const foundModule = loadedModules.find((module) => module.key === key);

    if (foundModule) return foundModule;

    const params = new HttpParams().append('key', key);

    return this.fetchSingleModuleFromBackend(params);
  }

  async getModuleByName(name: string): Promise<IModuleResponse | undefined> {
    const loadedModules = this._dataStore.value;
    const foundModule = loadedModules.find((module) => module.name === name);

    if (foundModule) return foundModule;

    const params = new HttpParams().append('name', name);

    return this.fetchSingleModuleFromBackend(params);
  }

  private async fetchSingleModuleFromBackend(params: HttpParams) {
    const loadedModules = this._dataStore.value;
    const url = generateURL({ endpoint: ENDPOINTS.MODULES_GET_ALL });

    const response = await firstValueFrom(
      this.http.get<CommonResponseDTO<IModuleResponse[]>>(url, {
        params,
      })
    );
    const moduleConfig = response?.data?.[0];
    if (!moduleConfig) return undefined;

    this._dataStore.next([...loadedModules, moduleConfig]);

    return moduleConfig;
  }

  resetModuleConfig(
    id: string
  ): Observable<CommonResponseDTO<IModuleResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.MODULES_RESET,
      params: { id },
    });
    return this.http
      .patch<CommonResponseDTO<IModuleResponse>>(url, undefined)
      .pipe(
        tap((res) => {
          this.updateDataStore(res.data);
        })
      );
  }

  updateModules(
    id: string,
    bodyData: Partial<IModuleRequest>
  ): Observable<CommonResponseDTO<IModuleResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.MODULES_UPDATE,
      params: { id },
    });
    return this.http
      .patch<CommonResponseDTO<IModuleResponse>>(url, bodyData)
      .pipe(
        tap((res) => {
          this.updateDataStore(res.data);
        })
      );
  }

  private updateDataStore(updatedModule: IModuleResponse) {
    const updatedModules = this._dataStore.value.map((moduleData) => {
      if (moduleData._id === updatedModule._id) {
        return updatedModule;
      } else {
        return moduleData;
      }
    });
    this._dataStore.next(updatedModules);
  }

  getModuleById(id: string): Observable<CommonResponseDTO<IModuleResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.MODULES_GET_ONE_BY_ID,
      params: { id },
    });
    return this.http.get<CommonResponseDTO<IModuleResponse>>(url);
  }

  listenToAvailability(modules: MODULES[]): Observable<boolean> {
    return this._dataStore.asObservable().pipe(
      map((change) => {
        return modules.every((module) => {
          const foundModules = change.find((_module) => _module.key === module);

          // TODO: check whether the logged in user has READ: permissions to the passed modules
          return (
            foundModules?.is_enabled &&
            !foundModules.is_deleted &&
            !foundModules.is_hidden
          );
        });
      })
    );
  }

  reset() {
    this._dataStore.next([]);
  }
}
