import { KeyValue, TitleCasePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';

import { CUSTOMER_TYPES } from '@shared/constants';
import {
  IContactAddress,
  IContactEmail,
  IContactNumber,
  IName,
  MotorFleetSchemeVehicleWise,
  ProposalInformation,
  ProposalModelList,
} from '@shared/interfaces';
import {
  extractDefaultValue,
  getArrangedName,
  thousandSeparatorAdd,
} from '@shared/utils';

import { DateTimeFormatService } from '../../../../services/date-time-format.service';
import { FlowControlService } from '../../../../services/flow-control.service';
import { PrintService } from '../../../../services/print.service';
import { SnackbarService } from '../../../../services/snackbar.service';
import { ClientSetupResponseDTO } from '../../../setup/general/services/client-data.service';

@Component({
  selector: 'app-print-proposal-component',
  templateUrl: './print-proposal.component.html',
  styleUrls: ['./print-proposal.component.scss'],
})
export class ProposalTemplateComponent implements OnInit, AfterViewInit {
  loading = true;
  proposal_information: ProposalInformation;
  client_information: ClientSetupResponseDTO;
  created_on: string;
  created_by: string;
  ref_number: string;
  communication_modes: string;
  communication_languages: string;
  vehicle_use: string;
  workmen_compensation: string;
  goods_nature: string;
  required_covers: string;
  make: string;
  model: string;
  type: string;
  fuel: string;

  // Only for Motor Fleet PDF template

  type_list: KeyValue<string, string>[] = [];
  make_list: KeyValue<string, string>[] = [];
  fuel_list: KeyValue<string, string>[] = [];
  model_list: ProposalModelList[] = [];

  isMotorFleet: boolean;
  isMedicalGroup: boolean;
  isTravelGroup: boolean;

  motor_fleet_vehicle_information: MotorFleetSchemeVehicleWise[] = [];

  // Only for Medical Group PDF template
  coverages: KeyValue<string, string>[] = [];
  covers: KeyValue<string, string>[] = [];

  // Travel
  countries_list: KeyValue<string, string>[] = [];
  sections: KeyValue<string, string>[] = [];
  plans: KeyValue<string, string>[] = [];
  schedule_currency: string;

  thousandSeparatorAdd = thousandSeparatorAdd;

  constructor(
    flowControlService: FlowControlService,
    router: Router,
    route: ActivatedRoute,
    public dateTimeFormatService: DateTimeFormatService,
    public sanitizer: DomSanitizer,
    public snackbar: SnackbarService,
    public titleCasePipe: TitleCasePipe,
    private printService: PrintService,
    private localStorage: LocalStorage
  ) {
    flowControlService.updateRouteInfo(
      router.url.split('?')[0],
      route.snapshot.data
    );
  }

  ngOnInit(): void {
    this.created_on = this.dateTimeFormatService.formatDateTime(new Date());
    this.localStorage.getItem('print-proposal').subscribe(
      (data: {
        proposal: ProposalInformation;
        client: ClientSetupResponseDTO;
        created_by: string;
        ref_number: string;
        make: string;
        model: string;
        type: string;
        fuel: string;
        motor_fleet_ref_info?: {
          type_list: KeyValue<string, string>[];
          make_list: KeyValue<string, string>[];
          model_list: ProposalModelList[];
          fuel_list: KeyValue<string, string>[];
        };
        medical_group_ref_info?: {
          coverages: KeyValue<string, string>[];
          covers: KeyValue<string, string>[];
        };
        travel_ref_info?: {
          sections: KeyValue<string, string>[];
          plans: KeyValue<string, string>[];
          covers: KeyValue<string, string>[];
          countries_list: KeyValue<string, string>[];
          schedule_currency: string;
        };
      }) => {
        this.proposal_information = data.proposal;
        this.isMotorFleet =
          this.proposal_information?.proposer_details?.customer_type ===
          CUSTOMER_TYPES.FLEET;
        this.isMedicalGroup =
          this.proposal_information?.proposer_details?.customer_type ===
          CUSTOMER_TYPES.ORGANIZATION;
        this.isTravelGroup =
          this.proposal_information?.proposer_details?.customer_type ===
          CUSTOMER_TYPES.GROUP;

        this.motor_fleet_vehicle_information =
          this.proposal_information?.fleet_products_details?.scheme_vehicle_information;

        this.client_information = data.client;
        this.created_by = data.created_by;
        this.ref_number = data.ref_number;
        this.make = data.make;
        this.model = data.model;
        this.type = data.type;
        this.fuel = data.fuel;

        this.type_list = data?.motor_fleet_ref_info?.type_list;
        this.make_list = data?.motor_fleet_ref_info?.make_list;
        this.model_list = data?.motor_fleet_ref_info?.model_list;
        this.fuel_list = data?.motor_fleet_ref_info?.fuel_list;

        this.coverages = data?.medical_group_ref_info?.coverages;
        this.covers = data?.medical_group_ref_info?.covers;

        this.schedule_currency = data?.travel_ref_info?.schedule_currency;
        this.countries_list = data?.travel_ref_info?.countries_list;
        this.sections = data?.travel_ref_info?.sections;
        this.plans = data?.travel_ref_info?.plans;
        this.covers = data?.travel_ref_info?.covers;

        this.communication_modes =
          this.getCommunicationModes()?.length > 0
            ? this.getCommunicationModes()?.join(', ')
            : '-';

        this.communication_languages =
          this.getCommunicationLanguages()?.length > 0
            ? this.getCommunicationLanguages()?.join(', ')
            : '-';
        this.vehicle_use =
          this.getVehicleUse()?.length > 0
            ? this.getVehicleUse()?.join(', ')
            : '-';
      }
    );

    this.localStorage.removeItem('print-proposal');
  }

  getVehicleTypeOfFleet(id: string): string {
    return this.type_list.find((e) => e.key === id)?.value;
  }
  getVehicleMakeOfFleet(id: string): string {
    return this.make_list.find((e) => e.key === id)?.value;
  }
  getVehicleModelOfFleet(id: string): string {
    return this.model_list.find((e) => e.key === id)?.value;
  }
  getVehicleFuelOfFleet(id: string): string {
    return this.fuel_list.find((e) => e.key === id)?.value;
  }

  getVehicleUseListOfFleet(scheme: number, vehicle: number): string {
    const selectedVehicle =
      this.motor_fleet_vehicle_information[scheme]?.vehicleDetails[vehicle];

    const output = this.getVehicleUse(selectedVehicle);

    return output?.length > 0 ? output?.join(', ') : '-';
  }

  getCommunicationModes(): string[] {
    const modes =
      this.proposal_information?.proposer_details?.communication_modes;
    const fax = modes?.fax;
    const email = modes?.email;
    const letters = modes?.letters;

    const output = [];
    if (fax) output.push('Fax');
    if (email) output.push('Email');
    if (letters) output.push('Letters');

    return output;
  }

  getCommunicationLanguages(): string[] {
    const languages =
      this.proposal_information?.proposer_details?.communication_languages;
    const sinhala = languages?.sinhala;
    const english = languages?.english;
    const tamil = languages?.tamil;

    const output = [];
    if (sinhala) output.push('Sinhala');
    if (english) output.push('English');
    if (tamil) output.push('Tamil');

    return output;
  }

  getVehicleUse(vehicle_uses: any = undefined): string[] {
    const vehicleUsePurpose =
      vehicle_uses ||
      this.proposal_information?.vehicle_details?.vehicle_use_purpose;

    const agriculture = vehicleUsePurpose?.agriculture;
    const driving_tuition = vehicleUsePurpose?.driving_tuition;
    const goods_carriage = vehicleUsePurpose?.goods_carriage;
    const passengers_carriage = vehicleUsePurpose?.passengers_carriage;
    const rented_to_rent_car = vehicleUsePurpose?.rented_to_rent_car;
    const rented_to_self_driving = vehicleUsePurpose?.rented_to_self_driving;
    const sltb_route = vehicleUsePurpose?.sltb_route;
    const social_domestic_pleasure =
      vehicleUsePurpose?.social_domestic_pleasure;
    const is_any_other = vehicleUsePurpose?.is_any_other;

    const output = [];
    if (agriculture) output.push('Agriculture Purpose');
    if (driving_tuition) output.push('Driving Tuition');
    if (goods_carriage) output.push('Carriage of Goods');
    if (passengers_carriage) output.push('Carriage of Passengers');
    if (rented_to_rent_car) output.push('Rented to a Rent a Car Company');
    if (rented_to_self_driving) output.push('Rented out on self-drive basis');
    if (sltb_route) output.push('SLTB Route');
    if (social_domestic_pleasure)
      output.push('Social, Domestic & Pleasure Purpose Only');
    if (is_any_other) output.push(vehicleUsePurpose?.any_other || '-');

    return output;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loading = false;
      setTimeout(() => {
        if (this.proposal_information) {
          this.printService.onDataReady(
            this.proposal_information.proposer_details.identification_number
          );
        } else {
          window.close();
        }
      }, 50);
    }, 2000);
  }

  getName(name: IName) {
    return getArrangedName(name);
  }

  getPhone(contact: IContactNumber[]) {
    return extractDefaultValue({
      type: 'PHONE',
      value: contact,
    });
  }

  getEmail(email: IContactEmail[]) {
    return extractDefaultValue({
      type: 'EMAIL',
      value: email,
    });
  }

  getAddress(address: IContactAddress[]) {
    return extractDefaultValue({
      type: 'ADDRESS',
      value: address,
    });
  }
}
