import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';

export const setDateAsString = (
  httpParams: HttpParams,
  queryParams: { [key: string]: string },
  dateParamName: string
) => {
  if (httpParams.has(dateParamName)) {
    const dateString = moment(httpParams.get(dateParamName)).format(
      'YYYY-MM-DD'
    );
    httpParams = httpParams.delete(dateParamName);

    httpParams = httpParams.append(dateParamName, dateString);
    queryParams = {
      ...queryParams,
      [dateParamName]: dateString,
    };
  }

  return {
    httpParams,
    queryParams,
  };
};
