import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { IPersonalization, IRoleResponse } from '@shared/interfaces';

import { IamConfigService } from '../../modules/setup/iam-config/services/iam-config.service';
import { LanguagesService } from '../../modules/setup/languages/services/languages.service';
import { NotificationConfigService } from '../../modules/setup/notifications/services/notification-config.service';
import { WidgetApiService } from '../../modules/widgets/services/widgets.api.service';
import { FlowControlService } from '../../services/flow-control.service';
import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../services/snackbar.service';

import { PersonalizationService } from './services/personalization.service';

interface Language {
  value: string;
  viewValue: string;
}

export enum LAYOUT_ROLES {
  MINE = 'MINE',
}

@Component({
  selector: 'app-personalization',
  templateUrl: './personalization.component.html',
  styleUrls: ['./personalization.component.scss'],
})
export class PersonalizationComponent implements OnInit, OnDestroy {
  languages: Language[] = [];
  roles: IRoleResponse[];
  selectedRole = 'MINE';
  userId: string;

  subscriptions = new Subscription();
  subscriptionsIAM = new Subscription();
  subscriptionsNotifications = new Subscription();
  isLoading = false;

  twoFactorFormGroup = new FormGroup({
    emailEnable: new FormControl(false),
    smsEnable: new FormControl(false),
    googleEnable: new FormControl(false),
  });

  widgetLayout = new FormControl(LAYOUT_ROLES.MINE);

  languagePreferences = new FormControl();
  widgetLayoutPreference = new FormControl();
  darkModeEnable = new FormControl(false);
  miniSideMenuEnable = new FormControl(false);
  emailNotificationsEnable = new FormControl(false);
  pushNotificationsEnable = new FormControl(false);

  systemNotificationEnabled = false;
  iamConfigTwoFactorEnabled = false;
  isNotificationEnableViaEmail = false;
  isPushNotificationEnable = false;

  widgetLayoutData: IPersonalization;

  constructor(
    private personalizationService: PersonalizationService,
    public widgetService: WidgetApiService,
    private languageService: LanguagesService,
    private snackbarService: SnackbarService,
    private iamConfigService: IamConfigService,
    private notificationsService: NotificationConfigService,
    private translate: TranslateService,
    flowControlService: FlowControlService,
    router: Router,
    route: ActivatedRoute
  ) {
    flowControlService.updateRouteInfo(
      router.url.split('?')[0],
      route.snapshot.data
    );
    this.initForm();
  }

  ngOnInit(): void {
    this.getAllLanguages();
    this.getAllRoles();
    this.initializeWidgetLayout();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsIAM?.unsubscribe();
    this.subscriptionsNotifications?.unsubscribe();
  }

  initializeWidgetLayout() {
    this.widgetService.getCurrentUserWidgetLayout().subscribe((res) => {
      this.widgetLayout.patchValue(res.data.role.toString() as LAYOUT_ROLES);
    });
  }

  initForm() {
    const notificationsSub = this.notificationsService.dataStore.subscribe(
      (data) => {
        if (data) {
          this.isNotificationEnableViaEmail = data.isNotificationEnableViaEmail;
          this.isPushNotificationEnable = data.isPushNotificationEnable;
        }
      }
    );

    const dataStoreSub = this.personalizationService.dataStore.subscribe(
      (data: IPersonalization) => {
        if (data) {
          this.twoFactorFormGroup.controls.emailEnable.setValue(
            data?.two_factor?.email
          );
          this.twoFactorFormGroup.controls.smsEnable.setValue(
            data?.two_factor?.sms
          );
          this.twoFactorFormGroup.controls.googleEnable.setValue(
            data?.two_factor?.google_authenticator
          );
          this.languagePreferences.setValue(data?.language);
          this.darkModeEnable.setValue(data?.dark_mode);
          this.miniSideMenuEnable.setValue(data?.mini_side_menu);
          this.emailNotificationsEnable.setValue(
            this.isNotificationEnableViaEmail && data?.email_notifications
          );
          this.pushNotificationsEnable.setValue(
            this.isPushNotificationEnable && data?.push_notifications
          );
        }
      }
    );

    const iamConfigSub = this.iamConfigService.dataStore.subscribe((data) => {
      if (data) {
        this.iamConfigTwoFactorEnabled =
          data.password_configurations.two_factor_authentication_enabled;
      }
    });

    this.subscriptions.add(dataStoreSub);
    this.subscriptionsIAM.add(iamConfigSub);
    this.subscriptionsNotifications.add(notificationsSub);
  }

  onUpdateTwoFactor() {
    this.isLoading = true;
    const data: IPersonalization = {
      two_factor: {
        email: this.twoFactorFormGroup.controls.emailEnable.value,
        sms: this.twoFactorFormGroup.controls.smsEnable.value,
        google_authenticator:
          this.twoFactorFormGroup.controls.googleEnable.value,
      },
    };
    if (this.twoFactorFormGroup.controls.emailEnable.value) {
      data.two_factor.email =
        this.twoFactorFormGroup.controls.emailEnable.value;
    }
    if (this.twoFactorFormGroup.controls.smsEnable.value) {
      data.two_factor.sms = this.twoFactorFormGroup.controls.smsEnable.value;
    }
    if (this.twoFactorFormGroup.controls.googleEnable.value) {
      data.two_factor.google_authenticator =
        this.twoFactorFormGroup.controls.googleEnable.value;
    }
    this.personalizationService
      .updatePersonalizationData(data, false)
      .subscribe({
        next: () => {
          this.snackbarService.success(SUCCESS_TYPES.SAVED);
          this.twoFactorFormGroup.markAsPristine();
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  onUpdateLanguage() {
    this.isLoading = true;
    const data: IPersonalization = {
      language: this.languagePreferences.value,
    };
    if (!this.languagePreferences.value) {
      this.languagePreferences.setValue(false);
    }
    this.personalizationService
      .updatePersonalizationData(data, false)
      .subscribe({
        next: () => {
          this.snackbarService.success(
            SUCCESS_TYPES.SAVED,
            this.translate.instant('personalization.language-preferences.label')
          );
          this.languagePreferences.markAsPristine();
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  onChangeDarkModeToggle() {
    this.isLoading = true;
    const data: IPersonalization = {
      dark_mode: this.darkModeEnable.value,
    };
    if (!this.darkModeEnable.value) {
      this.darkModeEnable.setValue(false);
    }
    this.personalizationService
      .updatePersonalizationData(data, true)
      .subscribe({
        next: () => {
          if (this.darkModeEnable.value) {
            this.snackbarService.success(
              SUCCESS_TYPES.ENABLED,
              this.translate.instant('personalization.dark-mode.status-message')
            );
          } else {
            this.snackbarService.success(
              SUCCESS_TYPES.DISABLED,
              this.translate.instant('personalization.dark-mode.status-message')
            );
          }
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  onChangeSideMenuToggle() {
    this.isLoading = true;
    const data: IPersonalization = {
      mini_side_menu: this.miniSideMenuEnable.value,
    };
    if (!this.miniSideMenuEnable.value) {
      this.miniSideMenuEnable.setValue(false);
    }
    this.personalizationService
      .updatePersonalizationData(data, true)
      .subscribe({
        next: () => {
          if (this.miniSideMenuEnable.value) {
            this.snackbarService.success(
              SUCCESS_TYPES.ENABLED,
              this.translate.instant(
                'personalization.mini-side-menu.status-message'
              )
            );
          } else {
            this.snackbarService.success(
              SUCCESS_TYPES.DISABLED,
              this.translate.instant(
                'personalization.mini-side-menu.status-message'
              )
            );
          }
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  onChangeEmailNotificationsToggle() {
    this.isLoading = true;
    const data: IPersonalization = {
      email_notifications: this.emailNotificationsEnable.value,
    };
    if (!this.emailNotificationsEnable.value) {
      this.emailNotificationsEnable.setValue(false);
    }
    this.personalizationService
      .updatePersonalizationData(data, false)
      .subscribe({
        next: () => {
          if (this.emailNotificationsEnable.value) {
            this.snackbarService.success(
              SUCCESS_TYPES.ENABLED,
              this.translate.instant(
                'personalization.email-notifications.status-message'
              )
            );
          } else {
            this.snackbarService.success(
              SUCCESS_TYPES.DISABLED,
              this.translate.instant(
                'personalization.email-notifications.status-message'
              )
            );
          }
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  onChangePushNotificationsToggle() {
    this.isLoading = true;
    const data: IPersonalization = {
      push_notifications: this.pushNotificationsEnable.value,
    };

    if (!this.pushNotificationsEnable.value) {
      this.pushNotificationsEnable.setValue(false);
    }
    this.personalizationService
      .updatePersonalizationData(data, false)
      .subscribe({
        next: () => {
          if (this.pushNotificationsEnable.value) {
            this.snackbarService.success(
              SUCCESS_TYPES.ENABLED,
              this.translate.instant(
                'personalization.push-notifications.status-message'
              )
            );
          } else {
            this.snackbarService.success(
              SUCCESS_TYPES.DISABLED,
              this.translate.instant(
                'personalization.push-notifications.status-message'
              )
            );
          }
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  getAllLanguages() {
    this.isLoading = true;
    this.languageService
      .getAllLanguages()
      .then((res) => {
        res = res.filter(this.filterEnabled);
        this.languages = res.map((language) => ({
          value: language.code,
          viewValue: language.title,
        }));
      })
      .catch(() => {
        this.languages = [];
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  filterEnabled(language) {
    return language.is_enabled;
  }

  onChangeWidgetLayout(): void {
    const selectedRole = this.widgetLayout.value;

    if (selectedRole === LAYOUT_ROLES.MINE)
      this.widgetService.getUserIdentityWidgetLayout().subscribe((res) => {
        this.widgetLayoutData = {
          widget_layout: res.data._id ? res.data._id : undefined,
        };
      });
    else
      this.widgetService.getRoleWidgetLayout(selectedRole).subscribe((res) => {
        this.widgetLayoutData = {
          widget_layout: res.data._id ? res.data._id : undefined,
        };
      });
  }

  onSaveWidgetLayout() {
    this.subscriptions.add(
      this.personalizationService
        .updatePersonalizationData(this.widgetLayoutData, true)
        .subscribe({
          next: () => {
            this.snackbarService.success(
              SUCCESS_TYPES.UPDATED,
              this.translate.instant('widgets.personalization.layout-changed')
            );
            this.isLoading = false;
            this.widgetLayout.markAsPristine();
          },
          error: () => {
            // TODO: Sameera
            // Had to comment this out since this is an invalid operation.
            // Take necessary actions

            // this.personalizationService.dataStore.value.widget_layout = undefined;
            this.isLoading = false;
          },
        })
    );
  }

  getAllRoles(): void {
    this.widgetService.getAllRolesForPersonalization().subscribe({
      next: (res) => {
        if (res.data) {
          this.roles = res.data;
        }
      },
      error: () => {
        this.snackbarService.error(
          this.translate.instant('widgets.error.failed-to-load-roles')
        );
      },
    });

    this.widgetService.getCurrentUserWidgetLayout().subscribe({
      next: (res) => {
        if (res.data?.identity) this.selectedRole = LAYOUT_ROLES.MINE;
        else if (res.data?.role) this.selectedRole = res.data.role.toString();
      },
    });
  }
}
