import {
  ADDITIONAL_FIELDS_TYPE,
  CONFIGURABLE_FIELD_DROPDOWN_OPTION_GENERATORS,
  LEADS_DEFAULT_ADDITIONAL_FIELDS,
  MODULES,
} from '@shared/constants';
import { IConfigureAdditionalFields } from '@shared/interfaces';

export const getModuleWiseAdditionalFields = (moduleName: MODULES) => {
  const additionalFormFields: IConfigureAdditionalFields[] = [];

  if (moduleName === MODULES.LEADS) {
    const leadType: IConfigureAdditionalFields = {
      key: LEADS_DEFAULT_ADDITIONAL_FIELDS.LEAD_TYPE,
      type: ADDITIONAL_FIELDS_TYPE.DROPDOWN,
      dropdown_options_generator:
        CONFIGURABLE_FIELD_DROPDOWN_OPTION_GENERATORS.LEADS_LEAD_TYPE,
      label: LEADS_DEFAULT_ADDITIONAL_FIELDS.LEAD_TYPE,
      errorMessage: 'ERR LEAD TYPE',
      placeholder: 'PLACEHOLDER LEAD TYPE',
      isRequired: true,
      isDisabled: false,
    };

    const channelType: IConfigureAdditionalFields = {
      key: LEADS_DEFAULT_ADDITIONAL_FIELDS.CHANNEL_TYPE,
      type: ADDITIONAL_FIELDS_TYPE.DROPDOWN,
      dropdown_options_generator:
        CONFIGURABLE_FIELD_DROPDOWN_OPTION_GENERATORS.LEADS_CHANNEL_TYPE,
      label: LEADS_DEFAULT_ADDITIONAL_FIELDS.CHANNEL_TYPE,
      errorMessage: 'ERR CHANNEL TYPE',
      placeholder: 'PLACEHOLDER CHANNEL TYPE',
      isRequired: true,
      isDisabled: false,
    };

    additionalFormFields.push(channelType, leadType);
  }

  return additionalFormFields;
};
