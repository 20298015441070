<div
  class="default-fields"
  *ngFor="let field of defaultFields; let i = index"
  [style.width]="
    field.advancedConfig?.width === FIELD_LAYOUT_TYPES.HALF_WIDTH
      ? '49%'
      : '100%'
  "
>
  <app-display-single-configurable-field
    [fieldItem]="field"
    [referenceCategory]="referenceCategory"
    (onFieldValueChange)="fieldValueChange(i)"
    [disabled]="disableAll"
    [readonly]="readonlyAll"
    [disableUniqueFields]="false"
  ></app-display-single-configurable-field>
</div>

<div *ngIf="nonDefaultFields?.length" class="w-100">
  <div class="mb-2">
    <button
      mat-stroked-button
      (click)="onAddField()"
      [disabled]="
        !(usedNonDefaultFields.length < nonDefaultFields.length) || disableAll
      "
    >
      <div class="d-flex justify-content-center align-items-center">
        +&nbsp;{{ 'configurable-fields.add-parameter' | translate }}
      </div>
    </button>
  </div>

  <div
    *ngFor="let field of usedNonDefaultFields; let index = index"
    class="non-default-field-container"
  >
    <div class="non-default-field">
      <div class="row">
        <div class="col-sm-4 col-12">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>{{
              'configurable-fields.non-default-fields.field-label' | translate
            }}</mat-label>
            <mat-select
              [placeholder]="
                'configurable-fields.non-default-fields.field-placeholder'
                  | translate
              "
              [formControl]="field.field_name"
              [disabled]="disableAll"
              (selectionChange)="selectedFieldChange($event, index)"
            >
              <mat-option
                *ngFor="let nonDefaultField of nonDefaultFields"
                [value]="nonDefaultField.configuration.name"
                [disabled]="
                  haveTheFieldUsedAlready(nonDefaultField.configuration.name)
                "
              >
                {{ nonDefaultField.configuration.name | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-8 col-12">
          <app-display-single-configurable-field
            *ngIf="field.field"
            [isDefault]="false"
            [fieldItem]="field.field"
            [referenceCategory]="referenceCategory"
            [disabled]="disableAll"
            [readonly]="readonlyAll"
          ></app-display-single-configurable-field>

          <mat-form-field
            *ngIf="!field.field"
            appearance="fill"
            style="width: 100%"
          >
            <mat-label>{{
              'configurable-fields.non-default-fields.value-label' | translate
            }}</mat-label>
            <input
              matInput
              [placeholder]="
                'configurable-fields.non-default-fields.value-placeholder'
                  | translate
              "
              [disabled]="true"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
    <button
      [matTooltip]="
        'references.add-new-reference-category.delete-button' | translate
      "
      matTooltipPosition="right"
      class="d-md-block pt-0 mt-1"
      style="float: left"
      mat-icon-button
      type="button"
      [disabled]="disableAll"
      (click)="onClickRemove(index)"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
