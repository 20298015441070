<!-- Begin:: Number & Text -->
<mat-form-field
  *ngIf="
    fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.TEXT ||
    fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.NUMBER ||
    fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.CURRENCY
  "
  class="w-100"
  appearance="fill"
>
  <mat-label>{{ fieldItem.configuration.name }}</mat-label>
  <input
    matInput
    [required]="isRequired()"
    [readonly]="isUnique()"
    [type]="
      fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.TEXT
        ? 'text'
        : 'number'
    "
    [formControl]="fieldItem.field_value"
    [placeholder]="fieldItem.configuration.name || 'Field Name'"
    [readonly]="isUnique() || isConstant() || readonly"
    (input)="fieldValueChanged()"
    (input)="checkAndClearIfUnique()"
  />
  <span
    *ngIf="
      fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.CURRENCY
    "
    matSuffix
  >
    {{ currencyCode }}&nbsp;
  </span>
  <mat-error
    *ngIf="
      fieldItem.field_value.hasError('required') ||
      fieldItem.field_value.hasError('whitespace')
    "
    >{{ fieldItem.configuration.name }}&nbsp;is required</mat-error
  >

  <mat-error *ngIf="fieldItem.field_value.hasError('unique')"
    >{{ fieldItem.configuration.name }}&nbsp;{{
      'references.templates.unique-error' | translate
    }}</mat-error
  >
</mat-form-field>
<!-- End:: Number & Text -->

<!-- Begin:: Date -->
<mat-form-field
  *ngIf="fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.DATE"
  class="w-100"
  appearance="fill"
>
  <mat-label>{{ fieldItem.configuration.name }}</mat-label>
  <input
    matInput
    [formControl]="fieldItem.field_value"
    [placeholder]="fieldItem.configuration.name || 'Field Name'"
    [required]="isRequired()"
    [disabled]="isConstant() || disabled || readonly || isUnique()"
    [matDatepicker]="picker"
    [readonly]="true"
    (input)="checkAndClearIfUnique()"
    (click)="picker.open()"
    (dateChange)="fieldValueChanged()"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-error *ngIf="fieldItem.field_value.hasError('required')"
    >{{ fieldItem.configuration.name }}&nbsp;is required</mat-error
  >
  <mat-error *ngIf="fieldItem.field_value.hasError('unique')"
    >{{ fieldItem.configuration.name }}&nbsp;{{
      'references.templates.unique-error' | translate
    }}</mat-error
  >
</mat-form-field>
<!-- End:: Date -->

<!-- Begin:: Time -->
<mat-form-field
  *ngIf="fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.TIME"
  class="w-100"
  appearance="fill"
>
  <mat-label>{{ fieldItem.configuration.name }}</mat-label>

  <input
    matInput
    readonly
    [placeholder]="fieldItem.configuration.name || 'Field Name'"
    [formControl]="fieldItem.field_value"
    [mtxDatetimepicker]="datetimePickerA"
    [required]="isRequired()"
    [disabled]="isConstant() || disabled || readonly"
    (click)="datetimePickerA.open()"
    (dateChange)="fieldValueChanged()"
    (focus)="resetErrorState()"
    (input)="checkAndClearIfUnique()"
  />
  <mtx-datetimepicker-toggle
    [for]="datetimePickerA"
    matSuffix
  ></mtx-datetimepicker-toggle>
  <mtx-datetimepicker
    #datetimePickerA
    type="time"
    startView="clock"
    color="primary"
    mode="portrait"
    [twelvehour]="true"
    [touchUi]="false"
  >
  </mtx-datetimepicker>
  <mat-error *ngIf="fieldItem.field_value.hasError('required')"
    >{{ fieldItem.configuration.name }}&nbsp;is required</mat-error
  >
  <mat-error *ngIf="fieldItem.field_value.hasError('unique')"
    >{{ fieldItem.configuration.name }}&nbsp;{{
      'references.templates.unique-error' | translate
    }}</mat-error
  >
</mat-form-field>
<!-- End:: Time -->

<!-- Begin:: Date Time -->
<mat-form-field
  *ngIf="
    fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.DATE_TIME
  "
  class="w-100"
  appearance="fill"
>
  <mat-label>{{ fieldItem.configuration.name }}</mat-label>
  <input
    matInput
    [formControl]="fieldItem.field_value"
    [placeholder]="fieldItem.configuration.name || 'Field Name'"
    [required]="isRequired()"
    [disabled]="isConstant() || disabled || readonly"
    [readonly]="true"
    (input)="checkAndClearIfUnique()"
    (click)="datetimePickerB.open()"
    mtxDatetimepickerToggleTrigger
    [mtxDatetimepicker]="datetimePickerB"
  />

  <mtx-datetimepicker-toggle
    [for]="datetimePickerB"
    matSuffix
  ></mtx-datetimepicker-toggle>
  <mtx-datetimepicker
    #datetimePickerB
    type="datetime"
    startView="month"
    color="primary"
    mode="auto"
    [twelvehour]="true"
    [touchUi]="false"
  >
  </mtx-datetimepicker>
  <mat-error *ngIf="fieldItem.field_value.hasError('required')"
    >{{ fieldItem.configuration.name }}&nbsp;is required</mat-error
  >
  <mat-error *ngIf="fieldItem.field_value.hasError('unique')"
    >{{ fieldItem.configuration.name }}&nbsp;{{
      'references.templates.unique-error' | translate
    }}</mat-error
  >
</mat-form-field>
<!-- End:: Date Time -->

<!-- Begin:: View -->
<div
  *ngIf="fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.VIEW"
>
  <h3>
    {{ fieldItem.configuration.name + (isRequired() ? ' *' : '') }}
  </h3>
  <span class="template-name">
    {{
      fieldItem.templateName || ('references.templates.no-template' | translate)
    }}
  </span>
  <button
    class="config-template"
    mat-button
    matSuffix
    (click)="setUpView()"
    [disabled]="disabled || readonly"
  >
    <mat-icon>settings</mat-icon>
    {{ 'configurable-fields.setup-view' | translate }}
  </button>
</div>
<!-- End:: View -->

<!-- Begin:: Boolean -->
<mat-form-field
  *ngIf="fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.BOOLEAN"
  class="w-100"
  appearance="fill"
>
  <mat-label>{{ fieldItem.configuration.name }}</mat-label>
  <mat-select
    (valueChange)="fieldValueChanged()"
    [formControl]="fieldItem.field_value"
    [placeholder]="fieldItem.configuration.name || 'Field Name'"
    [required]="isRequired()"
    [disabled]="
      isConstant() ||
      fieldItem.advancedConfig?.disableOnCriteria ||
      disabled ||
      readonly ||
      isUnique()
    "
    (input)="checkAndClearIfUnique()"
  >
    <!-- Keep these field values as strings. Don't change into booleans -->
    <mat-option value="true">
      {{
        fieldItem.configuration.booleanLabels?.trueLabel ??
          ('configurable-fields.boolean-fields.true' | translate)
      }}
    </mat-option>
    <!-- Keep these field values as strings. Don't change into booleans -->
    <mat-option value="false">
      {{
        fieldItem.configuration.booleanLabels?.falseLabel ??
          ('configurable-fields.boolean-fields.false' | translate)
      }}
    </mat-option>
  </mat-select>
</mat-form-field>
<!-- End:: Boolean -->

<!-- Begin:: Dropdown/ Autocomplete -->
<el-autocomplete
  *ngIf="
    fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.DROPDOWN
  "
  [label]="fieldItem.configuration.name || 'Field Name'"
  [options]="fieldItem.dropdownOptions"
  [required]="isRequired()"
  appearance="fill"
  [disabled]="
    isConstant() ||
    fieldItem.advancedConfig?.disableOnCriteria ||
    disabled ||
    readonly ||
    isUnique()
  "
  [hideTheAllOption]="true"
  [selected]="fieldItem.field_value.value"
  (itemsSelected)="onDropdownItemSelect($event)"
>
</el-autocomplete>
<!-- End:: Dropdown/ Autocomplete -->

<!-- Begin:: References -->
<el-autocomplete
  *ngIf="
    fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.REFERENCES
  "
  [label]="fieldItem.configuration.name || 'Field Name'"
  [options]="fieldItem.referencesFilteredFromCriteria ?? fieldItem.references"
  [required]="isRequired()"
  appearance="fill"
  [disabled]="
    isConstant() ||
    fieldItem.advancedConfig?.disableOnCriteria ||
    disabled ||
    readonly ||
    isUnique()
  "
  [hideTheAllOption]="true"
  [selected]="fieldItem.field_value.value"
  (itemsSelected)="onDropdownItemSelect($event)"
>
</el-autocomplete>
<!-- End:: References -->

<!-- Begin:: Formula -->
<!-- Readonly and disabled not added -->
<app-formula-builder
  *ngIf="fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.FORMULA"
  [fieldName]="fieldItem.configuration.name + (isRequired() ? ' *' : '')"
  [referenceCategory]="referenceCategory"
  [formula]="fieldItem.field_value.value"
  (onUpdateFormula)="onUpdateFormula($event)"
  (onErrorFormula)="fieldItem.errorFormula.next($event)"
  [isDisabled]="isConstant() || isUnique()"
>
</app-formula-builder>
<!-- End:: Formula -->

<!-- Begin:: JSON -->
<!-- Readonly and disabled not added -->
<div
  *ngIf="fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.JSON"
>
  <mat-label>
    {{ fieldItem.configuration.name + (isRequired() ? ' *' : '') }}
  </mat-label>
  <app-monaco-editor
    [language]="MONACO_SUPPORTED_LANGUAGES.JSON"
    [code]="fieldItem.field_value.value"
    [errors]="jsonEditorErrors"
    (codeChange)="onJSONChange($event)"
    (errorOutput)="jsonEditorErrors = $event.errors ?? []"
  >
  </app-monaco-editor>
</div>
<!-- End:: JSON -->

<!-- Begin:: File -->
<!-- Readonly and disabled not added -->
<div
  *ngIf="fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.FILE"
  style="margin-bottom: 10px; margin-top: 10px"
>
  <app-drag-and-drop
    style="width: 100%"
    [allowUpload]="true"
    [uploadDirectly]="false"
    [imgURLList]="getThumbnail(fieldItem.field_value.value)"
    [allowedTypesRegex]="allowedTypesRegex"
    [allowDelete]="true"
    (triggerFileSelected)="triggerFileUploaded($event)"
    (triggerImageDelete)="fieldItem.field_value.setValue(null)"
    [remainingFilesCount]="1"
    [title]="fieldItem.configuration.name + (isRequired() ? ' *' : '')"
    [allowedTypesNames]="allowedTypesNames"
    [maxSize]="maxSize"
    required
  >
  </app-drag-and-drop>
</div>
<!-- End:: File -->

<!-- Begin:: Rich Text -->
<!-- Readonly and disabled not added -->
<div
  *ngIf="
    fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.RICH_TEXT
  "
  class="mt-2"
>
  <mat-label>
    {{ fieldItem.configuration.name + (isRequired() ? ' *' : '') }}
  </mat-label>
  <ckeditor
    #ckeEditor
    [editor]="editor"
    [config]="editorConfig"
    [formControl]="fieldItem.field_value"
    style="width: 100%"
  >
  </ckeditor>
  <div style="height: 20px">
    <mat-error
      class="error ml-2"
      *ngIf="
        fieldItem.field_value.touched &&
        fieldItem.field_value.hasError('required')
      "
      >{{ fieldItem.configuration.name }}&nbsp;{{
        'configurable-fields.required-error' | translate
      }}</mat-error
    >
  </div>
</div>
<!-- End:: Rich Text -->

<!-- Begin:: Address -->
<div
  *ngIf="fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.ADDRESS"
>
  <el-multi-field
    [title]="fieldItem.configuration.name"
    placeholder="93, North 9th street, Brooklyn"
    [required]="isRequired()"
    [isConstant]="isConstant()"
    [data]="{
      type: 'ADDRESS',
      value: fieldItem.field_value.value,
      fieldConfig: fieldItem.configuration
    }"
    (onSetValue)="onOtherValueEmitted($event)"
    [disabled]="
      fieldItem.advancedConfig?.disableOnCriteria || disabled || isUnique()
    "
    [readonly]="readonly || isUnique()"
    (input)="checkAndClearIfUnique()"
  ></el-multi-field>
</div>
<!-- End:: Address -->

<!-- Begin:: Phone -->
<div
  *ngIf="
    fieldItem.configuration.type ===
    CONFIGURABLE_FIELD_DATA_TYPES.CONTACT_NUMBER
  "
>
  <el-multi-field
    [title]="fieldItem.configuration.name"
    [placeholder]="fieldItem.configuration.name || 'Field Name'"
    [required]="isRequired()"
    [isConstant]="isConstant()"
    [data]="{
      type: 'PHONE',
      value: fieldItem.field_value.value,
      fieldConfig: fieldItem.configuration
    }"
    (onSetValue)="onOtherValueEmitted($event)"
    [disabled]="
      fieldItem.advancedConfig?.disableOnCriteria || disabled || isUnique()
    "
    [readonly]="readonly || isUnique()"
    (input)="checkAndClearIfUnique()"
  ></el-multi-field>
</div>
<!-- End:: Phone -->

<!-- Begin:: Name -->
<div
  *ngIf="fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.NAME"
>
  <el-multi-field
    [title]="fieldItem.configuration.name"
    placeholder="Mr. John Doe"
    [required]="isRequired()"
    [isConstant]="isConstant()"
    [data]="{
      type: 'NAME',
      value: fieldItem.field_value.value,
      fieldConfig: fieldItem.configuration
    }"
    (onSetValue)="onOtherValueEmitted($event)"
    [disabled]="
      fieldItem.advancedConfig?.disableOnCriteria || disabled || isUnique()
    "
    [readonly]="readonly || isUnique()"
    (input)="checkAndClearIfUnique()"
  ></el-multi-field>
</div>
<!-- End:: Name -->

<!-- Begin:: Email -->
<div
  *ngIf="fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.EMAIL"
>
  <el-multi-field
    [title]="fieldItem.configuration.name"
    placeholder="johndoe@example.com (Work)"
    [required]="isRequired()"
    [isConstant]="isConstant()"
    [data]="{
      type: 'EMAIL',
      value: fieldItem.field_value.value,
      fieldConfig: fieldItem.configuration
    }"
    (onSetValue)="onOtherValueEmitted($event)"
    [disabled]="
      fieldItem.advancedConfig?.disableOnCriteria || disabled || isUnique()
    "
    [readonly]="readonly || isUnique()"
    (input)="checkAndClearIfUnique()"
  ></el-multi-field>
</div>
<!-- End:: Email -->

<!-- Begin:: Advanced configured fields -->

<!-- Begin:: Checkboxes -->
<div
  *ngIf="
    fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.CHECKBOX
  "
  class="d-flex flex-wrap my-3"
>
  <div
    *ngIf="isDefault"
    class="w-100"
    [ngClass]="{
      'error-color': customValidationInvalid
    }"
  >
    {{ fieldItem.configuration.name }}{{ isRequired() ? ' *' : '' }}
  </div>
  <mat-selection-list
    [formControl]="fieldItem.field_value"
    [disabled]="
      fieldItem.advancedConfig?.disableOnCriteria || disabled || readonly
    "
    class="d-flex flex-wrap"
  >
    <mat-list-option
      id="checkbox-items"
      color="primary"
      checkboxPosition="before"
      (selectedChange)="checkboxValueChange($event, i)"
      [value]="option.value"
      *ngFor="
        let option of fieldItem.configuration.checkboxOptions;
        let i = index
      "
    >
      {{ option.displayValue }}
    </mat-list-option>
  </mat-selection-list>
</div>

<!-- End:: Checkboxes -->

<!-- Begin:: Radio buttons -->
<div
  *ngIf="
    fieldItem.configuration.type === CONFIGURABLE_FIELD_DATA_TYPES.RADIO_BUTTON
  "
  class="my-3"
>
  <div
    *ngIf="isDefault"
    class="w-100 mb-3"
    [ngClass]="{
      'error-color': customValidationInvalid
    }"
  >
    {{ fieldItem.configuration.name }}{{ isRequired() ? ' *' : '' }}
  </div>
  <mat-radio-group
    [disabled]="
      fieldItem.advancedConfig?.disableOnCriteria || disabled || readonly
    "
    [formControl]="fieldItem.field_value"
    (change)="radioButtonChange($event)"
  >
    <mat-radio-button
      *ngFor="let option of fieldItem.configuration.radioButtonOptions"
      class="pr-5"
      color="primary"
      [value]="option.value"
      [disabled]="fieldItem.advancedConfig?.disableOnCriteria || disabled"
      >{{ option.displayValue }}</mat-radio-button
    >
  </mat-radio-group>
</div>
<!-- End:: Radio buttons -->

<!-- End:: Advanced configured fields -->

<div *ngIf="!fieldItem.configuration.type">Empty field</div>
