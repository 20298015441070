import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class EnumValueToStringService {
  constructor(private titleCasePipe: TitleCasePipe) {}

  private possibleCommonCapitalizer(text: string) {
    const possibleValues = ['url', 'pwa', 'iam'];
    const possibleValuesRegex = new RegExp(possibleValues.join('|'), 'ig');

    return text.replace(possibleValuesRegex, (matched) =>
      matched.toUpperCase()
    );
  }

  transform(
    text: string,
    config?: {
      skipTitleCaseTransform?: boolean;
      skipUnderscoreReplacement?: boolean;
      skipPossibleCommonCapitalization?: boolean;
    }
  ) {
    const {
      skipTitleCaseTransform,
      skipUnderscoreReplacement,
      skipPossibleCommonCapitalization,
    } = config ?? {};

    let retValue = text;

    if (!skipUnderscoreReplacement) retValue = retValue.replaceAll('_', ' ');
    if (!skipTitleCaseTransform)
      retValue = this.titleCasePipe.transform(retValue);
    if (!skipPossibleCommonCapitalization)
      retValue = this.possibleCommonCapitalizer(retValue);

    return retValue;
  }
}
