export enum ENDPOINT_CLIENT_TYPES {
  DEFAULT = '', // Don't change the value of this DEFAULT enum. It will break the generateURL function
  INTERNAL = '/internal',
  EXTERNAL = '/external',
}

export enum ENDPOINTS {
  // ------------------------------------------------------ GENERAL ENDPOINTS ------------------------------------------------------ //
  // ASSETS
  ASSETS_IMAGES_UPLOAD,
  ASSETS_IMAGES_DELETE,

  // AUTH
  AUTH_REFRESH_TOKEN,
  AUTH_LOGIN,
  AUTH_PROFILE_IMAGE_UPLOAD,
  AUTH_CODE,
  AUTH_LOGIN_IMPERSONATE,
  AUTH_CHECK_USER,
  AUTH_USERNAME,
  AUTH_LOGOUT,
  AUTH_FORGOT_PASSWORD,
  AUTH_VALIDATE_TOKEN,
  AUTH_RESET_PASSWORD,
  AUTH_REGISTER,
  AUTH_VERIFY,
  AUTH_PROFILE,
  AUTH_SEND_OTP,

  //CHART OF ACCOUNTS
  CHART_OF_ACCOUNT_GET_ALL,
  CHART_OF_ACCOUNT_ADD_NEW,
  CHART_OF_ACCOUNT_GET_ALL_PARTIAL_DATA,
  CHART_OF_ACCOUNT_GET_ONE,
  CHART_OF_ACCOUNT_UPDATE_ONE,
  CHART_OF_ACCOUNT_DELETE_ONE,
  CHART_OF_ACCOUNT_RESTORE_ONE,
  CHART_OF_ACCOUNT_DISABLE,
  CHART_OF_ACCOUNT_ENABLE,

  // CONNECTED-APPS
  CONNECTED_APPS_CONNECT_NEW_APPS,
  CONNECTED_APPS_GET_ALL_APPS,
  CONNECTED_APPS_GET_ONE_APP,
  CONNECTED_APPS_UPDATE_ONE_APP,
  CONNECTED_APPS_DELETE_ONE_APP,
  CONNECTED_APPS_CONNECT_OR_DISCONNECT_APP,
  CONNECTED_APPS_GET_ALL_FIELDS_OF_A_CONNECTED_APP,
  CONNECTED_APPS_GET_ALL_PERMISSIONS_OF_A_CONNECTED_APP,
  CONNECTED_APPS_GET_ALL_ROLES_OF_A_CONNECTED_APP,
  CONNECTED_APPS_GET_ALL_DOCUMENTATIONS_OF_A_CONNECTED_APP,
  CONNECTED_APPS_GET_ALL_REFERENCES_OF_A_CONNECTED_APP,
  CONNECTED_APPS_GENERATE_PUBLIC_API_KEY,
  CONNECTED_APPS_GENERATE_PUBLIC_CLIENT_CREDENTIALS,
  CONNECTED_APPS_GENERATE_SECRETS,
  CONNECTED_APPS_SAVE_GENERATED_PUBLIC_KEY,
  CONNECTED_APPS_SAVE_DELETED_PUBLIC_KEY,
  CONNECTED_APPS_SAVE_DELETED_CLIENT_SECRET,
  CONNECTED_APPS_UPDATE_GENERATED_PUBLIC_KEY,
  CONNECTED_APPS_GET_SPECIFIC_GENERATED_KEY,
  CONNECTED_APPS_GET_ALL_GENERATED_KEYS,
  CONNECTED_APPS_DELETE_KEY,
  CONNECTED_APPS_RESTORE_KEY,
  CONNECTED_APPS_HANDSHAKE,

  // CONNECTIONS
  CONNECTIONS_GET_ALL,
  CONNECTIONS_UPDATE,
  CONNECTION_CREATE_ONE,

  // CUSTOMERS
  CUSTOMERS_GET_ALL,
  CUSTOMERS_GET_ONE,

  // CLAIMS
  CLAIMS_GET_ALL,

  // ENDORSEMENTS
  ENDORSEMENTS_GET_ALL,
  ENDORSEMENTS_GET_ONE,
  ENDORSEMENTS_ADD,

  //ENTRIES
  ENTRIES_GET_ALL,
  ENTRIES_GET_ONE_BY_DEDUCTION_CONFIG,
  ENTRIES_ADD_NEW,
  ENTRIES_UPDATE_ONE,

  // IDENTITIES
  IDENTITIES_CREATE_ONE,
  IDENTITIES_GET_ALL,
  IDENTITIES_GET_ONE,
  IDENTITIES_GET_ONE_PUBLIC_INFORMATION_ONLY,
  IDENTITIES_GET_INTERNAL_FIELD,
  IDENTITIES_SUBORDINATES,
  IDENTITIES_SUBORDINATE_IDS,
  IDENTITIES_HIERARCHICAL,
  IDENTITIES_SUPERVISORS,
  IDENTITIES_INTERNAL_FIELDS,
  IDENTITIES_EXTERNAL_FIELDS,

  //INTEGRATION - ENDPOINTS
  INTEGRATION_ENDPOINTS_GET_ALL,
  INTEGRATION_ENDPOINTS_ADD_ONE,
  INTEGRATION_ENDPOINTS_UPDATE_ONE,
  INTEGRATION_ENDPOINTS_DELETE_ONE,
  INTEGRATION_ENDPOINTS_RESTORE_ONE,
  INTEGRATION_ENDPOINTS_VERIFY_ONE,

  //INTEGRATION - MAPPING
  INTEGRATION_MAPPINGS_ADD_ONE,
  INTEGRATION_MAPPINGS_GET_ALL,
  INTEGRATION_MAPPINGS_UPDATE_ONE,
  INTEGRATION_MAPPINGS_DELETE_ONE,
  INTEGRATION_MAPPINGS_RESTORE_ONE,
  INTEGRATION_MAPPINGS_VERIFY_ONE,

  //INTEGRATION - FILE-CONFIGS
  INTEGRATION_FILE_CONFIGS_GET_ALL,
  INTEGRATION_FILE_CONFIGS_UPDATE,

  //INTEGRATION - FILE-UPLOADS
  INTEGRATION_FILE_UPLOADS_GET_ALL,
  INTEGRATION_FILE_UPLOADS_ADD_ONE,
  INTEGRATION_FILE_UPLOADS_UPDATE_STATUS,
  INTEGRATION_FILE_UPLOADS_GET_CONNECTIONS_WITH_FILE_APPROVAL_PERMISSION,

  // FILES
  FILES_UPLOAD,
  FILES_DOWNLOAD,
  FILES_DELETE,

  // INVOICES
  INVOICES_GET_ALL,
  INVOICES_SHARE_INVOICE,
  INVOICES_SHARE_PAYABLE_LINK,
  INVOICE_GET_BY_PARAMS,
  INVOICES_HASH_PAYMENT_DETAILS,

  //  receipts
  RECEIPTS_GET_ALL,
  RECEIPTS_ADD_NEW,
  RECEIPTS_AGGREGATOR_NEW,
  RECEIPTS_GET_ONE,
  RECEIPTS_UPDATE_ONE,
  RECEIPTS_DELETE_ONE,
  RECEIPTS_ADD_CANCEL_REQUEST,
  RECEIPTS_ADD_CANCEL_APPROVAL,
  RECEIPTS_GET_REQUEST_CANCELLATION_DATA,
  RECEIPTS_GET_ALL_EXCESS_DATA,
  CONTROLS_GET_ALL_PARTIAL_DATA,
  BRANCHES_GET_ALL_PARTIAL_DATA,
  CONTROLS_GET_ALL,
  PARTNERS_GET_AGENT_INFORMATION,
  GET_TRANSACTION,
  RECEIPTS_SEND_BODIES_TO_TRANSACTION,
  INITIALIZE_TRANSACTION_CREATE,

  // JOB_QUEUE
  JOB_QUEUE_CHECK_STATUS,

  // MODULES
  MODULES_GET_ALL,
  MODULES_GET_ONE_BY_ID,
  MODULES_UPDATE,
  MODULES_RESET,

  // PERSONALIZATION
  PERSONALIZATION_GET,
  PERSONALIZATION_UPDATE,

  // PRIVILEGES
  PRIVILEGES_CREATE_ONE,
  PRIVILEGES_GET_ALL,
  PRIVILEGES_GET_ONE_BY_ID,
  PRIVILEGES_GET_RELEVANT,
  PRIVILEGES_UPDATE,
  PRIVILEGES_DELETE,
  PRIVILEGES_RESTORE,

  // NOTIFICATION
  NOTIFICATION_CERATE_WITH_USER,
  NOTIFICATION_CERATE_WITHOUT_USER,
  NOTIFICATION_CREATE_MULTIPLE_NOTIFICATIONS_WITH_USERS,
  NOTIFICATION_GET,
  NOTIFICATION_GET_USER_NOTIFICATIONS,
  NOTIFICATION_FILTER,
  NOTIFICATIONS_MARK_AS_READ,
  NOTIFICATIONS_MARK_ALL_AS_READ,

  // CONFIGURABLE_FIELDS - VALIDATIONS
  CONFIGURABLE_FIELDS_VALIDATIONS_NAME,
  CONFIGURABLE_FIELDS_VALIDATIONS_PHONE_NUMBER,
  CONFIGURABLE_FIELDS_VALIDATIONS_ADDRESS,
  CONFIGURABLE_FIELDS_VALIDATIONS_EMAIL,

  // REFERENCES - PREDEFINED_REFERENCES
  PREDEFINED_REFERENCES_GET_VALUE,

  // REFERENCES - REFERENCE_CATEGORIES
  REFERENCE_CATEGORIES_GET_ALL,
  REFERENCE_CATEGORIES_GET_SINGLE,
  REFERENCE_CATEGORIES_GET_EXTERNAL,
  REFERENCE_CATEGORIES_GET_EXTERNAL_DELETE,
  REFERENCE_CATEGORIES_ADD_NEW,
  REFERENCE_CATEGORIES_UPDATE,
  REFERENCE_CATEGORIES_DELETE,
  REFERENCE_CATEGORIES_RESTORE,
  REFERENCE_CATEGORIES_REFERENCES_GET_ALL,
  REFERENCE_CATEGORIES_REFERENCES_GET_ALL_V2,
  REFERENCE_CATEGORIES_REFERENCES_ADD_NEW,
  REFERENCE_CATEGORIES_REFERENCES_BULK_ADD,

  // REFERENCES - REFERENCES
  REFERENCE_EDIT,
  REFERENCE_DELETE,
  REFERENCE_RESTORE,
  REFERENCES_FIELDS_GET_VALUE,

  // REFERENCES - TEMPLATES
  TEMPLATES_ADD_NEW,
  TEMPLATES_UPDATE_ONE,
  TEMPLATES_GET_ONE,

  // REFERENCES - FORMULA
  FORMULA_ANALYZE,
  FORMULA_EVALUATE,

  // STORAGE - INTERNAL
  STORAGE_INTERNAL_CONTENT,
  STORAGE_INTERNAL_DELETE,
  STORAGE_INTERNAL_PREVIEW,
  STORAGE_INTERNAL_UPLOAD,
  STORAGE_INTERNAL_DOWNLOAD,
  STORAGE_INTERNAL_DELETE_ONE,
  STORAGE_INTERNAL_SHARE_INTERNALLY,

  // SETUP - IAM
  SETUP_IAM_AUTHORIZERS_AZURE,
  SETUP_IAM_HANDSHAKE,

  // SETUP - BACKUP_SCHEDULES
  SETUP_BACKUPS_SCHEDULES_ADD_NEW,
  SETUP_BACKUPS_SCHEDULES_GET_ALL,
  SETUP_BACKUPS_SCHEDULES_DELETE_ONE,
  SETUP_BACKUPS_SCHEDULES_ENABLE_DISABLE_ONE,

  // SETUP - BACKUPS
  SETUP_BACKUPS_BACKUP_NOW,
  SETUP_BACKUPS_GET_ALL,
  SETUP_BACKUPS_DOWNLOAD_ONE,
  SETUP_BACKUPS_DELETE_ONE,

  // SETUP - LANGUAGES
  SETUP_LANGUAGES_ADD_ONE,
  SETUP_LANGUAGES_GET_ALL,
  SETUP_LANGUAGES_GET_ONE,
  SETUP_LANGUAGES_UPDATE_ONE,
  SETUP_LANGUAGES_ENABLE_DISABLE_ONE,
  SETUP_LANGUAGES_DELETE_ONE,
  SETUP_LANGUAGES_REFRESH_ONE,
  SETUP_LANGUAGES_GET_CONTENT_OF_ONE,

  // SETUP - REFERENCE MAPPING
  SETUP_GET_ALL_REFERENCE_MAPPINGS,
  SETUP_GET_REFERENCE_MAPPING_BY_KEY,
  SETUP_UPDATE_REFERENCE_MAPPING,

  // SETUP - CURRENCIES
  SETUP_CURRENCIES_ADD_ONE,
  SETUP_CURRENCIES_GET_ALL,
  SETUP_CURRENCIES_GET_ONE,
  SETUP_CURRENCIES_UPDATE_ONE,
  SETUP_CURRENCIES_DELETE_ONE,
  SETUP_CURRENCIES_RESTORE_ONE,
  SETUP_CURRENCIES_TOGGLE_BASE_CURRENCY,

  // SETUP - EXTERNAL SYSTEMS
  SETUP_EXTERNAL_SYSTEMS_GET_ALL,
  SETUP_EXTERNAL_SYSTEM_GET_EXTERNAL_REFERENCE_CATEGORIES,
  SETUP_EXTERNAL_SYSTEMS_UPDATE_ONE,

  // SETUP - EXTERNAL SYSTEMS - KEYS
  SETUP_EXTERNAL_SYSTEMS_KEYS_GET_ALL,
  SETUP_EXTERNAL_SYSTEMS_KEYS_GET_ONE,
  SETUP_EXTERNAL_SYSTEMS_KEYS_DELETE_ONE,
  SETUP_EXTERNAL_SYSTEMS_KEYS_RESTORE_ONE,
  SETUP_EXTERNAL_SYSTEMS_KEYS_SAVE_KEY,
  SETUP_EXTERNAL_SYSTEMS_KEYS_GENERATE_NEW_KEY,

  // ------------------------------------------------------ GENERAL ENDPOINTS ------------------------------------------------------ //
  // ------------------------------------------------------ CLIENT ENDPOINTS ------------------------------------------------------ //
  // REFERENCES - REFERENCE_CATEGORIES
  CLIENT_REFERENCE_CATEGORIES_REFERENCES_GET_ALL,
  // REFERENCES - REFERENCES
  CLIENT_REFERENCES_FIELDS_GET_VALUE,
  // ------------------------------------------------------ CLIENT ENDPOINTS ------------------------------------------------------ //
  // ------------------------------------------------------ EXTERNAL ENDPOINTS ------------------------------------------------------ //
  // REFERENCES - REFERENCE_CATEGORIES
  EXTERNAL_REFERENCE_CATEGORIES_REFERENCES_GET_ALL,
  // REFERENCES - REFERENCES
  EXTERNAL_REFERENCES_FIELDS_GET_VALUE,
  // REFERENCES - PRODUCTS
  EXTERNAL_REFERENCES_PRODUCTS_GET_ALL,
  // ------------------------------------------------------ EXTERNAL ENDPOINTS ------------------------------------------------------ //
  // ------------------------------------------------------ IAM ENDPOINTS ------------------------------------------------------ //
  // REFERENCES - REFERENCE_CATEGORIES
  IAM_REFERENCE_CATEGORIES_REFERENCES_GET_ALL,
  // REFERENCES - REFERENCES
  IAM_REFERENCES_FIELDS_GET_VALUE,
  // ------------------------------------------------------ IAM ENDPOINTS ------------------------------------------------------ //
  // ------------------------------------------------------ FOMS ------------------------------------------------------ //
  // ACTIVITIES
  ACTIVITIES_GET_ALL,
  ACTIVITIES_ADD_NEW,
  ACTIVITIES_UPDATE_ONE,
  ACTIVITIES_COMPLETE_ONE,
  ACTIVITIES_DELETE_ONE,
  ACTIVITIES_SCHEDULE_ONE,
  ACTIVITIES_RESCHEDULE_ONE,
  ACTIVITIES_RESCHEDULE_INTERVIEW,
  ACTIVITIES_CANCEL_INTERVIEW,

  // ACHIEVEMENTS
  ACHIEVEMENTS_CONFIG_GET_ALL,
  ACHIEVEMENTS_CONFIG_UPDATE,
  ACHIEVEMENTS_CONFIG_UPDATE_TARGET,
  ACHIEVEMENTS_LOCK_DATE_CONFIG_GET,
  ACHIEVEMENTS_LOCK_DATE_CONFIG_UPDATE,
  ACHIEVEMENTS_GET_ALL,
  ACHIEVEMENTS_GET_ONE,
  ACHIEVEMENTS_EXPORT,

  // COMPETITIONS
  COMPETITIONS_ADD_NEW,
  COMPETITIONS_GET_ALL,
  COMPETITIONS_GET_ONE,
  COMPETITIONS_UPDATE_ONE,
  COMPETITIONS_UPDATE_STATUS,
  COMPETITIONS_EXTEND,
  COMPETITIONS_STOP,
  COMPETITIONS_LEADERBOARD_AGENTS,

  // COVER NOTES
  COVER_NOTES_GET_ALL,
  COVER_NOTES_ADD_NEW,
  COVER_NOTES_GET_ONE,
  COVER_NOTES_UPDATE_ONE,

  // LEADS
  LEADS_ADD_NEW,
  LEADS_UPDATE_ONE,
  LEADS_UPDATE_DOCUMENTS,
  LEADS_GET_ALL,
  LEADS_GET_ONE,
  LEADS_RE_ASSIGN_AGENT,
  LEADS_BULK_UPLOAD,
  LEADS_COMMENT,
  LEADS_GET_ALL_DOCUMENTATIONS,
  LEADS_GENERATE_PUBLIC_API_KEY,
  LEADS_SAVE_GENERATED_API_KEY,
  LEADS_GET_SPECIFIC_GENERATED_KEY,
  LEADS_GET_ALL_GENERATED_KEYS,
  LEADS_DELETE_KEY,
  LEADS_RESTORE_KEY,

  // RECRUITMENTS
  RECRUITMENTS_ADD_NEW,
  RECRUITMENTS_GET_ALL,
  RECRUITMENTS_GET_ONE,
  RECRUITMENT_RESCHEDULE_INTERVIEW,
  RECRUITMENT_SCHEDULE_INTERVIEW,
  RECRUITMENT_EXIT_INTERVIEW,
  RECRUITMENTS_UPDATE_ONE,
  RECRUITMENT_GENERATE_CODE,

  //RECRUITER_INTERVIEW
  RECRUITMENT_INTERVIEW_ADD,
  RECRUITMENT_INTERVIEW_GET_ONE,
  RECRUITMENT_INTERVIEW_GET_ONE_BY_RECRUITMENT_ID,
  RECRUITMENT_INTERVIEW_UPDATE,
  RECRUITMENT_INTERVIEW_SUBMIT,

  // TARGETS
  TARGETS_CONFIG_GET_ALL,
  TARGETS_CONFIG_UPDATE,
  TARGETS_ADD_NEW,
  TARGETS_GET_ALL,
  TARGETS_GET_ONE,
  TARGETS_DELETE_ONE,
  TARGETS_ARCHIVE_ONE,

  // POLICIES
  POLICIES_GET_ALL_BOMS_PRODUCTS,
  POLICIES_GET_ALL_BOMS_PARTNERS,
  POLICIES_GET_BOMS_REFERENCES,
  POLICIES_GET_ONE_BOMS_WORKFLOW,
  POLICIES_GET_ALL,
  POLICIES_DATA,
  POLICIES_ADD_NEW,
  POLICIES_ADD_NEW_VEHICLE_FLEET,
  POLICIES_ADD_NEW_MEDICAL_GROUP,
  POLICIES_ADD_NEW_TRAVEL_GROUP,
  POLICIES_PREMIUM_CALCULATION,
  POLICIES_GET_ONE,
  POLICIES_UPDATE_ONE,
  POLICIES_ADD_PROPOSAL,
  POLICIES_CANCEL_PROPOSAL,
  POLICIES_VERIFY_PROPOSAL_DOCUMENTS,
  POLICIES_VIEW_PROPOSAL,
  POLICIES_VERIFY_PROPOSAL_DOCUMENTS_FINAL,
  POLICIES_PROPOSAL_REQUEST_COVER_NOTE,
  POLICIES_PROPOSAL_RESUBMISSION,
  POLICIES_PROPOSAL_RAISED_DOCUMENTS_SUBMISSION,
  POLICIES_SHARE_TO_CUSTOMER_LINK,
  POLICIES_RESUBMIT,
  POLICIES_DELETE_ONE,
  POLICIES_RESTORE_ONE,
  POLICIES_REQUEST_AMENDMENT,
  POLICIES_UPDATE_DOC_DELETE,
  POLICIES_REQUEST_AMENDMENT_STATUS,
  POLICIES_CANCEL_AMENDMENT,
  POLICIES_POLICY_CANCELATION,
  POLICIES_REQUEST_POLICY_RENEWALS,

  //PAYOUTS
  PAYOUTS_GET_ALL,
  PAYOUTS_UPDATE_STATUS_AND_DATA,
  PAYOUTS_CONFIG_BATCH_SCHEDULE_ADD_NEW,
  PAYOUTS_CONFIG_BATCH_SCHEDULE_GET_ALL,
  PAYOUTS_CONFIG_BATCH_SCHEDULE_UPDATE,
  PAYOUTS_CONFIG_BATCH_SCHEDULE_DELETE,
  PAYOUTS_CONFIG_EARNINGS_SET_EARNINGS,
  PAYOUTS_CONFIG_EARNINGS_GET_ALL,
  PAYOUTS_CONFIG_EARNINGS_GET_RUNNING,
  PAYOUTS_CONFIG_DEDUCTIONS_ADD_NEW,
  PAYOUTS_CONFIG_DEDUCTIONS_ADD_BULK,
  PAYOUTS_CONFIG_DEDUCTIONS_GET_ALL_BY_BATCH_ID,
  PAYOUTS_CONFIG_DEDUCTIONS_GET_ALL_GENERAL,
  PAYOUTS_CONFIG_DEDUCTIONS_GET_ALL_AGENT_WISE,
  PAYOUTS_CONFIG_DEDUCTIONS_UPDATE_ONE,
  PAYOUTS_CONFIG_DEDUCTIONS_DELETE_ONE,
  PAYOUTS_CONFIG_DEDUCTIONS_ADD_NEW_CRITERIA,
  PAYOUTS_CONFIG_ENTRIES_ADD_NEW,
  PAYOUTS_CONFIG_ENTRIES_GET_ALL,
  PAYOUTS_CONFIG_DATA_MAPPINGS_GET_ALL,
  PAYOUTS_CONFIG_DATA_MAPPINGS_SET_DATA_MAPPINGS,
  PAYOUTS_CONFIG_GET_BANK_ORDER_FIELDS,
  PAYOUTS_CONFIG_SET_BANK_ORDER_FIELDS,
  PAYOUTS_UPDATE_STATUS,
  PAYOUTS_UPDATE_PAYOUTS,
  PAYOUTS_GENERATE_VOUCHER_NUMBER_AND_BATCH_ID,
  PAYOUTS_PROCESS_ALL,
  PAYOUTS_TRANSACTIONS,
  PAYOUTS_COUNT,

  // EXCHANGE RATES
  EXCHANGE_RATES_GET_ALL,
}
