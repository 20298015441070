import {
  ACHIEVEMENT_PERMISSION_ACTIONS,
  AGENTS_PERMISSION_ACTIONS,
  ANALYZING_MODES,
  INTEGRATION_PERMISSION_ACTIONS,
  MODULES,
  PAYOUTS_PERMISSION_ACTIONS,
  PERMISSION_ACTIONS,
  REFERENCE_PERMISSION_ACTIONS,
  TARGET_PERMISSION_ACTIONS,
  USER_TYPES,
} from '@shared/constants';
import { IIdentity } from '@shared/interfaces';

export const generateVirtualPermissionKey = (
  categoryName: string,
  actionType: PERMISSION_ACTIONS
) => {
  const withUnderscore = categoryName
    .replace(/[^a-zA-Z\d\s:]/g, '')
    .replace(/\s/g, '_');

  return `${actionType}:${withUnderscore}`.toUpperCase();
};

export const generatePermissionKey = (
  module: MODULES,
  action:
    | PERMISSION_ACTIONS
    | REFERENCE_PERMISSION_ACTIONS
    | ACHIEVEMENT_PERMISSION_ACTIONS
    | INTEGRATION_PERMISSION_ACTIONS
    | PAYOUTS_PERMISSION_ACTIONS
    | INTEGRATION_PERMISSION_ACTIONS
    | TARGET_PERMISSION_ACTIONS
    | AGENTS_PERMISSION_ACTIONS,
  subModule?: string
) => {
  const newLocal = `:${subModule}`;
  const permissionString = `${action}:${module}${subModule ? newLocal : ''}`;

  return permissionString
    .replace(/[^a-zA-Z\d\s:]/g, ' ')
    .replace(/\s/g, '_')
    .toUpperCase();
};

export function getModulePermission(
  module: MODULES,
  actions: (
    | PERMISSION_ACTIONS
    | REFERENCE_PERMISSION_ACTIONS
    | INTEGRATION_PERMISSION_ACTIONS
    | ACHIEVEMENT_PERMISSION_ACTIONS
    | PAYOUTS_PERMISSION_ACTIONS
    | TARGET_PERMISSION_ACTIONS
    | AGENTS_PERMISSION_ACTIONS
  )[],
  subModule?: string
): string[] {
  return actions.map((action) =>
    generatePermissionKey(module, action, subModule)
  );
}

export const validatePermissionsForLoggedUser = (
  identity: IIdentity,
  existingPermissions: string[] = [],
  checkingPermissions: string[] = [],
  analyzingMode: ANALYZING_MODES = ANALYZING_MODES.EVERY
) => {
  if (identity.user_type === USER_TYPES.EL_ADMIN) return true;

  switch (analyzingMode) {
    case ANALYZING_MODES.EVERY:
      return checkingPermissions.every((permission) =>
        existingPermissions.includes(permission)
      );

    case ANALYZING_MODES.SOME:
      return checkingPermissions.some((permission) =>
        existingPermissions.includes(permission)
      );

    default:
      return false;
  }
};
