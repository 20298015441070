<mat-card class="quotation position-relative hiding-div mb-3" *ngIf="premium">
  <div class="row" *ngFor="let cover of covers">
    <div class="col-12 q-section">{{ cover.name }}&nbsp;{{ baseCurrency }}</div>
    <div class="col-12">
      <b>{{ thousandSeparatorAdd(cover.value) }}</b>
    </div>
  </div>
  <div class="col quotation-divider">
    <mat-divider></mat-divider>
  </div>
  <div class="row" *ngFor="let fee of fees_and_charges">
    <div class="col-12 q-section">{{ fee.name }}&nbsp;{{ baseCurrency }}</div>
    <div class="col-12">
      <b>{{ thousandSeparatorAdd(fee.value) }}</b>
    </div>
  </div>

  <div class="row">
    <div class="col-12 q-section">
      <mat-card class="primary-bg red-bar">
        <h5 class="d-flex flex-column">
          {{ 'shared.premium-displayer.total-premium' | translate }}
          &nbsp;{{ baseCurrency }}
          <span>
            <b>{{ thousandSeparatorAdd(premium) }}</b>
          </span>
        </h5>
      </mat-card>
    </div>
  </div>
</mat-card>
