<div style="display: flex; align-items: center">
  <div style="display: flex; align-items: center">
    <mat-icon>stop</mat-icon>
  </div>
  <div style="padding-left: 4px">
    <h2 style="display: inline" mat-dialog-title>
      {{ 'shared.confirmation-popup.title' | translate }}
    </h2>
  </div>
</div>

<br />

<div style="font-size: 14px; padding-left: 4px">
  <mat-label>{{ title }}</mat-label>
</div>

<mat-dialog-content
  style="padding-left: 4px; padding-right: 8px; margin: 0; overflow: hidden"
>
  <form [formGroup]="form">
    <mat-form-field
      color="primary"
      class="mt-3"
      style="width: 100%"
      appearance="fill"
    >
      <mat-label>{{ 'shared.cancel-interview.remarks' | translate }}</mat-label>
      <textarea
        id="stop_remark"
        [type]="'text'"
        matInput
        [placeholder]="'shared.cancel-interview.remarks' | translate"
        formControlName="stop_remark"
        required
      ></textarea>
      <mat-error>{{
        'shared.cancel-interview.remark-required' | translate
      }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions style="justify-content: flex-end; padding-top: -10px">
  <button
    mat-button
    [mat-dialog-close]="false"
    matTooltip="{{ 'shared.confirmation-popup.no' | translate }}"
  >
    {{ 'shared.common.no' | translate }}
  </button>
  <button
    mat-raised-button
    (click)="onClickYes()"
    color="warn"
    matTooltip="{{ 'shared.confirmation-popup.yes' | translate }}"
    [disabled]="form.invalid"
  >
    {{ 'shared.common.yes' | translate }}
  </button>
</mat-dialog-actions>
