import {
  IConfigurableFieldConfig,
  IConfigurableFieldDataTypes,
  IConfigurableFieldValue,
  IModule,
} from '@shared/interfaces';

interface IExtractValueResponse {
  success: boolean;
  error?: 'config' | 'data';
  data?: IConfigurableFieldDataTypes;
  config?: IConfigurableFieldConfig;
}

export const extractValueUsingDataAndConfig = (
  moduleConfig: IModule,
  fieldKey: string,
  data: IConfigurableFieldValue[] = []
): IExtractValueResponse => {
  const fieldConfig = (moduleConfig.fields || []).find(
    (field) => field.name === fieldKey
  );
  if (!fieldConfig)
    return {
      success: false,
      error: 'config',
    };

  const fieldValue = data.find(
    (field) => field.field_id?.toString() === fieldConfig._id?.toString()
  )?.value;

  if (!fieldValue)
    return {
      success: false,
      error: 'data',
    };

  return {
    success: true,
    data: fieldValue,
    config: fieldConfig,
  };
};
