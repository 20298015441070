export enum MODULES {
  IDENTITIES = 'IDENTITIES',
  APPS = 'APPS',
  CONNECTIONS = 'CONNECTIONS',
  MODULES = 'MODULES',
  NOTICES = 'NOTICES',
  KNOWLEDGEBASE = 'KNOWLEDGEBASE',
  NOTIFICATIONS = 'NOTIFICATIONS',
  MESSAGES = 'MESSAGES',
  LOGS = 'LOGS',
  AUTHORIZATION_PERMISSIONS = 'AUTHORIZATION_PERMISSIONS',
  AUTHORIZATION_ROLES = 'AUTHORIZATION_ROLES',
  AUTHORIZATION_PRIVILEGES = 'AUTHORIZATION_PRIVILEGES',
  STORAGE_DRIVE = 'STORAGE_DRIVE',
  STORAGE_RECYCLE_BIN = 'STORAGE_TRASH',
  REFERENCES = 'REFERENCES',
  INTEGRATIONS = 'INTEGRATIONS',
  SETUP = 'SETUP',

  // Business
  POLICIES = 'POLICIES',
  COVER_NOTES = 'COVER_NOTES',
  CUSTOMERS = 'CUSTOMERS',
  ENDORSEMENTS = 'ENDORSEMENTS',
  CLAIMS = 'CLAIMS',
  INVOICES = 'INVOICES',
  RECEIPTS = 'RECEIPTS',

  // FOMS
  LEADS = 'LEADS',
  ACTIVITIES = 'ACTIVITIES',
  AGENTS = 'AGENTS',
  TARGETS = 'TARGETS',
  ACHIEVEMENTS = 'ACHIEVEMENTS',
  RECRUITMENTS = 'RECRUITMENTS',
  COMPETITIONS = 'COMPETITIONS',
  PAYOUTS = 'PAYOUTS',

  //ACCOUNTING RELATED
  CHART_OF_ACCOUNTS = 'CHART_OF_ACCOUNTS',
  ENTRIES = 'ENTRIES',
}

export enum MODULE_GROUPS {
  AUTHORIZATION = 'AUTHORIZATION',
  INTEGRATIONS = 'INTEGRATIONS',
  STORAGE = 'STORAGE',
  REFERENCES = 'REFERENCES',
}

export enum MODULE_STATUSES {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  HIDDEN = 'hidden',
  DISABLE = 'disable',
}
