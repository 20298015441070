export enum ACTIVITY_PROGRESS_STATUSES {
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  EXITED = 'EXITED',
}

export enum ACTIVITY_TYPES {
  LEAD = 'LEAD',
  CALL = 'CALL',
  PRESENTATION = 'PRESENTATION',
  INTERVIEW = 'INTERVIEW',
  MEETING = 'MEETING',
  QUOTATION_GENERATED = 'QUOTATION_GENERATED',
  PROPOSAL_SUBMITTED = 'PROPOSAL_SUBMITTED',
  QUICK_QUOTE_GENERATED = 'QUICK_QUOTE_GENERATED',
  AMEND_CHANGE = 'QUOTATION GENERATED - REQUEST CHANGES AMENDMENT',
  AMEND_NEW = 'QUOTATION GENERATED - AMEND AS NEW AMENDMENT',
}
