import {
  DATA_REFERENCE_MODULES,
  FORMULA_ELEMENT_TYPES,
  IDENTITIES_DEFAULT_FIELDS,
} from '@shared/constants';
import { IFormulaDataReferenceElement } from '@shared/interfaces';

export const dataReferences: IFormulaDataReferenceElement[] = [
  {
    type: FORMULA_ELEMENT_TYPES.DATA_REFERENCE,
    dataReference: {
      module: DATA_REFERENCE_MODULES.ADVISOR,
      field: IDENTITIES_DEFAULT_FIELDS.BASIC_SALARY,
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.DATA_REFERENCE,
    dataReference: {
      module: DATA_REFERENCE_MODULES.ADVISOR,
      field: IDENTITIES_DEFAULT_FIELDS.BUSINESS_DEVELOPMENT_GRANT,
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.DATA_REFERENCE,
    dataReference: {
      module: DATA_REFERENCE_MODULES.ADVISOR,
      field: IDENTITIES_DEFAULT_FIELDS.APPOINTMENT_DATE,
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.DATA_REFERENCE,
    dataReference: {
      module: DATA_REFERENCE_MODULES.ADVISOR,
      field: IDENTITIES_DEFAULT_FIELDS.TRAVEL_REIMBURSEMENT,
    },
  },
];
