import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Color } from '@angular-material-components/color-picker';
import { LocalStorage } from '@ngx-pwa/local-storage';

import { PAYOUTS_STATUSES } from '@shared/constants';
import {
  IAgentInformation,
  IPayoutResponse,
  IPayoutTransaction,
} from '@shared/interfaces';
import { hexToRgb, thousandSeparatorAdd } from '@shared/utils';

import { DateTimeFormatService } from '../../../../services/date-time-format.service';
import { EnumValueToStringService } from '../../../../services/enum-value-to-string.service';
import { PrintService } from '../../../../services/print.service';
import { CurrenciesService } from '../../../setup/currencies/services/currencies.service';
import { ClientSetupResponseDTO } from '../../../setup/general/services/client-data.service';
import { SystemDataService } from '../../../setup/general/services/system-data.service';

const isNonCompleteStatus = (status: PAYOUTS_STATUSES) =>
  [
    PAYOUTS_STATUSES.DRAFT,
    PAYOUTS_STATUSES.IN_QUEUE,
    PAYOUTS_STATUSES.PENDING_APPROVAL,
  ].includes(status);

@Component({
  selector: 'app-payslip-template',
  templateUrl: './payslip-template.component.html',
  styleUrls: ['./payslip-template.component.scss'],
})
export class PayslipTemplateComponent implements OnInit {
  client_information: ClientSetupResponseDTO;

  agent_information: IAgentInformation;
  payoutResponse: IPayoutResponse;
  printed_date: string;
  headingColor: string;
  currencyCode: string;

  loading = true;

  constructor(
    private printService: PrintService,
    private localStorage: LocalStorage,
    private systemDataService: SystemDataService,
    private datePipe: DatePipe,
    private currenciesService: CurrenciesService,
    private enumValueToStringService: EnumValueToStringService,
    private dateTimeService: DateTimeFormatService
  ) {}

  ngOnInit(): void {
    this.currencyCode = this.currenciesService.baseCurrency.code;
    this.initializeData();
  }

  initializeData() {
    this.localStorage.getItem('print-payslip').subscribe((data: any) => {
      this.client_information = data.company;
      this.agent_information = data.agent_Information;
      this.payoutResponse = data.payoutResponse;
      this.printed_date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
      this.localStorage.removeItem('print-payslip');
    });
    this.systemDataService.dataStore.subscribe((data) => {
      if (data && typeof data.color_palette !== 'string') {
        const bgColor = hexToRgb(data.color_palette.primaryColor);
        this.headingColor = new Color(
          bgColor.r,
          bgColor.g,
          bgColor.b,
          0.2
        ).toRgba();
      }
    });

    this.print();
  }

  private print() {
    setTimeout(() => {
      this.loading = false;
      setTimeout(() => {
        if (
          this.client_information &&
          this.agent_information &&
          this.payoutResponse.transactions
        ) {
          this.printService.onDataReady(
            `Payslip-${this.agent_information.agent_name}-${this.payoutResponse.commonData.month}-${this.payoutResponse.commonData.year}`
          );
        } else {
          window.close();
        }
      }, 50);
    }, 2000);
  }

  checkStatus(status: PAYOUTS_STATUSES): boolean {
    switch (status) {
      case PAYOUTS_STATUSES.DRAFT:
      case PAYOUTS_STATUSES.IN_QUEUE:
      case PAYOUTS_STATUSES.PENDING_APPROVAL:
        return false;
      default:
        return true;
    }
  }

  checkAllStatus(payoutResponse: IPayoutResponse): boolean {
    const statuses = payoutResponse.transactions.map(
      (payout) => payout?.status
    );

    return statuses.some(isNonCompleteStatus);
  }

  getStatus(status: PAYOUTS_STATUSES) {
    if (isNonCompleteStatus(status)) {
      return 'PENDING';
    } else {
      return this.enumValueToStringService.transform(status, {
        skipTitleCaseTransform: true,
      });
    }
  }

  getColor(status: PAYOUTS_STATUSES) {
    if (isNonCompleteStatus(status)) {
      return '#9d9d9d';
    } else {
      return '#419c62';
    }
  }

  getValue(value: number | string, replaceNegativeValuesWithZero?: boolean) {
    return thousandSeparatorAdd(value.toString(), {
      currencyCode: this.currencyCode,
      onlyTwoDecimals: true,
      replaceNegativeValuesWithZero,
    });
  }

  getBatchAndDateRange(payout: IPayoutTransaction) {
    return `${payout.batch_no} (${this.dateTimeService.formatDateWithoutTime(
      payout.coverage_start
    )}-${this.dateTimeService.formatDateWithoutTime(payout.coverage_end)})`;
  }
}
