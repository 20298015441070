import { FILE_TYPES, MIME_TYPES } from '@shared/constants';

import { getMimeTypesForFileType } from './file-types';

export function getURLRegex(webDomain: string): RegExp {
  return new RegExp(
    `(http(s)?:\\/\\/)?(www\\.)?(${webDomain})\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)`
  );
}

export function getNavigationRegex(webDomain: string): RegExp {
  return new RegExp(`(http(s)?:\\/\\/)?(www\\.)?(${webDomain})`);
}

export function generateFileTypeRegex(types: FILE_TYPES[]): RegExp {
  const allowedMimeTypes = types.flatMap((type) =>
    getMimeTypesForFileType(type)
  );

  return new RegExp(allowedMimeTypes.join('|'));
}

export function generateMIMETypeRegex(types: MIME_TYPES[]): RegExp {
  return new RegExp(types.join('|'));
}

export function vehicleNumberRegex(): RegExp {
  return new RegExp(
    '^((WP|EP|NC|SG|UP|CP|NP|NW|SP) )?([A-Z]{2}|[A-Z]{3}) [0-9]{4}$|^[0-9]{1,3} (-|SRI) [0-9]{4}$'
  );
}
